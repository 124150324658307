/* CSS Index
-----------------------------------
1. Theme default css
2. header
3. slider
4. about
5. features
6. services
7. video
8. choose
9. brand
10. work-process
11. team
12. counter
13. cta
14. testimonial
15. blog
16. pagination
17. f-cta
18. pricing
19. contact
20. footer

*/

/* 1. Theme default css */

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Teko:wght@300;400;500;600;700&display=swap');

body {
	font-family: 'Barlow', sans-serif;
	font-size  : 15px !important;
	font-weight: normal;
	color      : #ffffffc7 !important;
	font-style : normal;
	line-height: 26px;
	background : #1a0e22 !important;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
	overflow-wrap: break-word;
}

.mean-container a.meanmenu-reveal span {
	background: no-repeat;
	display   : block;
	height    : 3px;
	margin-top: 3px;
	border-top: 3px solid #fff;
}

.srb-line {
	width     : 100%;
	text-align: center;
	position  : absolute;
	bottom    : -90px;
}

.line5 {
	display: block;
}

.mr-15 {
	margin-right: 15px;
}

.img {
	max-width         : 100%;
	-webkit-transition: all 0.3s ease-out;
	transition        : all 0.3s ease-out;
}

.f-left {
	float: left
}

.f-right {
	float: right
}

.fix {
	overflow: hidden
}

a,
.button {
	-webkit-transition: all 0.3s ease-out;
	transition        : all 0.3s ease-out;
}

a:focus,
.button:focus {
	text-decoration: none;
	outline        : none;
}

a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	text-decoration: none;
}

.btn.focus,
.btn:focus {
	outline   : 0;
	box-shadow: none;
}

a,
button {
	color  : #010f2e;
	outline: medium none;
}

a:hover {
	color: #ffc010;
}

.box20 .title a {
	color: #fff;
}

.box20 .title a:hover {
	color: #444;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
	outline   : none;
	box-shadow: none;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family   : 'Teko', sans-serif;
	color         : #fff;
	margin-top    : 0px;
	font-style    : normal;
	font-weight   : 600;
	text-transform: normal;
	text-transform: uppercase;
	letter-spacing: 1.5px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}

h1 {
	font-size  : 40px;
	font-weight: 600;
}

h2 {
	font-size: 35px;
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

ul {
	margin : 0px;
	padding: 0px;
}

li {
	list-style: none
}

p {
	margin-bottom: 15px;
}

hr {
	border-bottom: 1px solid #eceff8;
	border-top   : 0 none;
	margin       : 30px 0;
	padding      : 0;
}

label {
	color      : #7e7e7e;
	cursor     : pointer;
	font-size  : 14px;
	font-weight: 400;
}

*::-moz-selection {
	background : #d6b161;
	color      : #fff;
	text-shadow: none;
}

::-moz-selection {
	background : #444;
	color      : #fff;
	text-shadow: none;
}

::selection {
	background : #444;
	color      : #fff;
	text-shadow: none;
}

*::-moz-placeholder {
	color     : #555555;
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*::-webkit-input-placeholder {
	color     : #555555;
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*:-ms-input-placeholder {
	color     : #555555;
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*::placeholder {
	color     : #555555;
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.theme-overlay {
	position: relative
}

.theme-overlay:before {
	background: #1696e7 none repeat scroll 0 0;
	content   : "";
	height    : 100%;
	left      : 0;
	opacity   : 0.6;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
	position  : absolute;
	top       : 0;
	width     : 100%;
}

.separator {
	border-top: 1px solid #f2f2f2
}

.logo {
	min-width: 20rem;
}

.logo img {
	width: 450px;
	height: 6.5rem;
}

/*** Preloader style ** */
.preloader {
	position           : fixed;
	left               : 0;
	top                : 0;
	width              : 100%;
	height             : 100%;
	z-index            : 9999999;
	background-color   : #1a0e22;
	background-position: center center;
	background-repeat  : no-repeat;
	background-image   : url(../img/preloader.gif);
}

/* button style */

.btn {
	border            : none;
	color             : #fff;
	display           : inline-block;
	font-size         : 16px;
	font-weight       : 600;
	letter-spacing    : 0;
	line-height       : 1;
	margin-bottom     : 0;
	padding           : 18px 45px;
	text-align        : center;
	text-transform    : unset;
	-ms-touch-action  : manipulation;
	touch-action      : manipulation;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	vertical-align    : middle;
	white-space       : nowrap;
	position          : relative;
	z-index           : 999;
	border-radius     : 0;
	min-width         : 210px;
}

.btn:hover {
	color: #fff;
}

.btn:hover:before {
	opacity   : 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.breadcrumb>.active {
	color: #888;
}

.breadcrumb-wrap.text-left {
	text-align: center !important;
	position  : relative;
}

.breadcrumb-wrap nav {
	position: relative;
	z-index : 1;
	display : inline-block;
}

.btn-after {
	width             : 100%;
	height            : 45px;
	border            : 1px solid #707070;
	border-radius     : 8px;
	margin-top        : -28px;
	margin-left       : 15px;
	position          : absolute;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	display           : none;
}

.slider-btn:hover .btn-after {
	margin-top : -50px;
	margin-left: 0px;
}

/* scrollUp */

#scrollUp {
	background        : #ffc010;
	height            : 45px;
	width             : 45px;
	right             : 50px;
	bottom            : 77px;
	color             : #fff;
	font-size         : 20px;
	text-align        : center;
	border-radius     : 50%;
	font-size         : 22px;
	line-height       : 45px;
	-webkit-transition: .3s;
	transition        : .3s;
}

#scrollUp:hover {
	background: #ffc010;
}

/* 2. header */

.header-top {
	padding-top   : 40px;
	border-bottom : 1px solid #e6e6e6;
	padding-bottom: 30px;
}

.innder-ht {
	padding: 10px 0;
}

.wellcome-text p {
	margin-bottom: 0;
	color        : #8a8a8a;
}

.header-cta ul li {
	display      : inline-block;
	margin-left  : 30px;
	border-right : 1px solid #ccc;
	padding-right: 30px;
}

.header-cta ul li:first-child {
	margin-left: 0;
}

.header-cta ul li:last-child {
	padding-right: 0;
	border-right : none;
}

.header-cta ul li i {
	display         : inline-block;
	margin-right    : 5px;
	position        : relative;
	color           : #fff;
	background-image: -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	font-size       : 12px;
	width           : 23px;
	height          : 23px;
	border-radius   : 50%;
	line-height     : 22px;
	text-align      : center;
}

.header-top-cta ul li {
	display     : inline-block;
	width       : 200px;
	margin-right: 80px;
}

.header-top-cta ul li:last-child {
	margin-right: 0;
}

.h-cta-icon {
	float       : left;
	display     : block;
	margin-right: 20px;
}

.h-cta-content {
	overflow: hidden;
}

.h-cta-content h5 {
	font-size     : 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom : 0;
}

.h-cta-content p {
	margin-bottom: 0;
	line-height  : 1.5;
}

.main-menu ul li {
	display    : inline-block;
	margin-left: 25px;
	position   : relative;
}

.main-menu ul li.active a {
	color: #ffc010;
}

.main-menu ul li a {
	display       : block;
	color         : #4f4f4f;
	font-weight   : 500;
	padding       : 20px 0;
	position      : relative;
	font-size     : 18px;
	font-family   : 'Teko', sans-serif;
	text-transform: uppercase;
	letter-spacing: 1.5px;
}

.header-three .main-menu ul li a:hover {
	color: #ffc010;
}

.main-menu ul li:first-child {
	margin-left: 0;
}

.menu-area .menu-tigger {
	cursor : pointer;
	display: block;
	float  : left;
}

.menu-area .menu-tigger span {
	height            : 2px;
	width             : 30px;
	background        : #2935bb;
	display           : block;
	margin            : 7px 0;
	-webkit-transition: .3s;
	transition        : .3s;
}

.main-menu ul li:hover>a {
	color: #ffc010;
}

.main-menu ul li:hover>a:before {
	width: 100%;
}

.main-menu ul li:first-child:before {
	display: none;
}

.main-menu .has-sub ul li:before {
	display: none;
}

.display-ib {
	display: inline-block;
}

.header-btn {
	margin-left: -40px;
}

.header-social a {
	font-size  : 14px;
	display    : inline-block;
	margin-left: 15px;
	text-align : center;
	color      : #191D3B;
}

.search-top {
	display      : inline-block;
	position     : absolute;
	top          : 16px;
	border       : 2px solid #010f2e;
	border-radius: 30px;
	left         : 20%;
}

.search-top ul {
	background   : #4CC3C1 0% 0% no-repeat padding-box;
	border-radius: 23px;
	margin       : 5px;
	padding      : 10px 5px 5px;
}

.search-top li {
	display     : inline-block;
	padding     : 0px 15px;
	border-right: 1px solid #ffffff38;
}

.search-top li a {
	color    : #fff;
	font-size: 18px;
	float    : left;
}

.search-top li:last-child {
	border: none;
}

.header-social a:hover {
	color: #fff;
}

.sticky-menu {
	left              : 0;
	margin            : auto;
	position          : relative;
	top               : 0;
	width             : 100%;
	box-shadow        : 0 0 60px 0 rgba(0, 0, 0, .07);
	z-index           : 9999;
	background        : #fff;
	-webkit-animation : 300ms ease-in-out 0s normal none 1 running fadeInDown;
	animation         : 300ms ease-in-out 0s normal none 1 running fadeInDown;
	-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.responsive {
	display: none;
}

#mobile-menu {
	display: block;
	margin-top: 4%;
	margin-left: 4%;
}

.right-menu li {
	float  : right;
	padding: 10px 0 10px 0;
}

.right-menu .icon {
	float       : left;
	margin-right: 20px;
}

.right-menu .text {
	float      : left;
	padding-top: 5px;
}

.right-menu .text span {
	display: block;
	color  : #517496;
}

.right-menu .text strong {
	color    : #002e5b;
	font-size: 20px;
}

.menu-search {
	padding: 10px 0;
}

.menu-search a {
	font-size    : 16px;
	display      : inline-block;
	margin-top   : 5px;
	background   : #ffffff4a;
	text-align   : center;
	line-height  : 33px;
	border-radius: 50%;
	color        : #b8bfc3;
}

.top-btn {
	background-image: -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	padding         : 16px 30px;
	width           : 100%;
	float           : left;
	text-align      : center;
	color           : #fff !important;
}

.top-btn:hover {
	/* background: transparent linear-gradient(90deg, #ff8a010%, #ff8a01100%) 0% 0% no-repeat padding-box; */
	color: #fff;
}

.sticky-menu .logo {
	margin-top : 0;
	line-height: 61px;
	height     : auto;
}

.sticky-menu:before {
	height: 71px;
	top   : 0;
	left  : -20px;
}

.slider-content {
	position: relative;
	z-index : 2;
}

.slider-content.s-slider-content.text2 {
	margin-top: 0;
}

.slider-content.s-slider-content.text3 {
	margin-top: 168px;
}

.slider-price {
	position: absolute;
	right   : 0;
	bottom  : 0;
}

.slider-price h3 {
	color: #fff;
}

.slider-price h2 {
	color      : #ffc010;
	font-size  : 60px;
	font-weight: 500;
}

.slider-btn {
	display : inline-block;
	position: relative;
}

.slider-active .slick-dots {
	position: absolute;
	top     : 40%;
	left    : 22%;
}

.slider-active .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	height            : 1px;
	margin-left       : 10px;
	background        : #ffc010;
	border-radius     : 50px;
	z-index           : 1;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
}

.slider-active .slick-dots li.slick-active button {
	width     : 50px;
	background: #ffc010;
}

.slider-active .slick-dots li.slick-active button:before {
	/* background: url("../../assets/img/bg/active-dote.png"); */
	/* background: url(../img/bg/active-dote.png); */
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slider-active .slick-dots li button:before {
	content            : "";
	width              : 19px;
	height             : 19px;
	/* background      : url("../img/bg/dote.png"); */
	float              : left;
	position           : relative;
	margin-top         : -9px;
	left               : -19px;
	border-radius      : 50%;
	opacity            : .7;
	-ms-filter         : "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	background-repeat  : no-repeat;
	background-position: center;
}

.second-header {
	border        : none;
	padding-bottom: 0;
	background    : #fff;
	padding-top   : 0;
	color         : #000;
}

.second-header a,
.second-header span {
	color: #777;
}

.second-header a:hover {
	color: #111;
}

.second-menu {
	position: relative;
	z-index : 9;
}

.sticky-menu .second-menu {
	margin    : 0;
	box-shadow: none;
	padding   : 0;
}

.sticky-menu .second-menu:before {
	content: none;
}

.second-menu .main-menu ul li {
	margin-left: 55px;
}

.second-menu .main-menu ul li:first-child {
	margin-left: 0;
}

.second-menu .main-menu ul li a:before {
	content: none;
}

.second-header-btn .btn {
	background    : transparent;
	border        : 2px solid #ffc010;
	font-size     : 16px;
	text-transform: uppercase;
	color         : #010f2e;
}

.second-header-btn .btn:hover {
	color     : #fff;
	background: #ffc010;
}

.second-header-btn .btn:before {
	content: none;
}

.offcanvas-menu {
	position          : fixed;
	right             : 0;
	height            : 100%;
	width             : 300px;
	z-index           : 999;
	background        : #00081b;
	top               : 0;
	padding           : 30px;
	-webkit-transition: .5s;
	transition        : .5s;
	-webkit-transform : translateX(100%);
	transform         : translateX(100%);
}

.offcanvas-menu.active {
	-webkit-transform: translateX(0);
	transform        : translateX(0)
}

.menu-close i {
	font-size         : 18px;
	color             : #fff;
	-webkit-transition: .3s;
	transition        : .3s;
	cursor            : pointer;
}

.menu-close:hover i {
	color: #ffc010;
}

.offcanvas-menu ul {
	margin-top: 30px;
}

.offcanvas-menu ul li {
	border-bottom: 1px solid #101c38;
}

.offcanvas-menu ul li a {
	color         : #fff;
	font-size     : 18px;
	text-transform: capitalize;
	padding       : 6px 0;
	display       : block;
}

.offcanvas-menu ul li:hover a {
	color: #ffc010;
}

.side-social a {
	color       : #fff;
	margin-right: 10px;
}

.side-social {
	margin-top: 30px;
}

.side-social a:hover {
	color: #ffc010;
}

.offcanvas-menu form {
	position  : relative;
	margin-top: 30px;
}

.offcanvas-menu form input {
	width     : 100%;
	background: none;
	border    : 1px solid #2d3547;
	padding   : 7px 10px;
	color     : #fff;
}

.offcanvas-menu form button {
	position  : absolute;
	border    : none;
	right     : 0;
	background: #ffc010;
	padding   : 8px 14px;
	top       : 0;
	cursor    : pointer;
}

.offcanvas-menu form button i {
	color: #fff;
}

.offcanvas-overly {
	position          : fixed;
	background        : #000;
	top               : 0;
	left              : 0;
	height            : 100%;
	width             : 100%;
	z-index           : 9;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility        : hidden;
	-webkit-transition: .3s;
	transition        : .3s;
}

.offcanvas-overly.active {
	opacity   : .5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	visibility: visible;
}

.off-logo {
	display: none;
}

.header-two .container {
	max-width: 1400px;
}

.header-two .menu-area {
	padding-top: 0;
}

.header-two .top-callus {
	background   : #ffc010;
	color        : #fff;
	display      : inline-block;
	padding      : 15px 30px;
	border-radius: 0 0px 20px 20px;
	width        : 100%;
}

.header-two #mobile-menu {
	float: left;
}

.header-two .logo img {
	width    : auto;
	max-width: 60%;
}

.header-two .main-menu ul li a {
	color: #181e23;
}

.header-three .menu-area {
	margin-bottom: -130px;
	border-top   : 0;
	position     : relative;
	z-index      : 9;
	padding-top  : 25px;
}

.header-three .main-menu ul li a {
	color: #fff;
}

.header-three .btn.ss-btn {
	float: right;
}

.slider-three .slider-img {
	margin-left : -25px;
	margin-top  : 50px;
	margin-right: -227px;
}

.slider-four .slider-img {
	margin-left: 0;
	margin-top : 50px;
	float      : right;
}

.slider-four .slider-img img {
	position: relative;
	z-index : 1;
}

.slider-four .slider-bg .text2 h5 {
	color      : #ffc010;
	font-size  : 18px;
	font-family: 'Teko', sans-serif;
	font-weight: 500;
}

.slider-four .slider-bg .video-i i {
	border: none;
	color : #ffc010;
}

.slider-four .slider-bg .text2 p {
	color: #777;
}

.slider-four .subricbe {
	border-radius   : 10px;
	background-color: rgb(255, 255, 255);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(250, 162, 146, 0.4);
	position        : relative;
	padding         : 10px 15px;
	margin-top      : 40px;
}

.header-input {
	padding      : 0 16px;
	padding      : 0 1rem;
	width        : 100%;
	height       : 86.4px;
	height       : 5.4rem;
	border-radius: 5px;
	outline      : none !important;
	border       : solid 0;
	margin-bottom: 0;
	border-radius: 30px 0px 30px 30px;
	border       : 1px solid rgb(183, 183, 183);
	box-shadow   : 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
}

.header-btn {
	position        : absolute;
	right           : 10px;
	top             : 10px;
	bottom          : 10px;
	border-radius   : 30px 0px 30px 30px;
	background-image: -webkit-linear-gradient(0deg, rgb(5, 180, 105) 0%, rgb(72, 199, 144) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(5, 180, 105) 0%, rgb(72, 199, 144) 100%);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(6, 180, 106, 0.4);
	color           : #fff;
}

.search-top2 {
	float: right;
}

.search-top2 li {
	margin-left     : 20px;
	float           : left;
	border-radius   : 10px;
	background-color: rgb(255, 255, 255);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(250, 162, 146, 0.4);
	width           : 45px;
	height          : 45px;
	text-align      : center;
	line-height     : 45px;
}

.search-top2 li a {
	float: none !important;
}

.search-top2 li .fas.fa-search {
	color: #36383a;
}

.cart-top {
	display: inline-block;
}

.cart-top li {
	display     : inline-block;
	padding     : 0px 17px;
	border-right: 1px solid #5C5C5C;
}

.cart-top li a {
	color    : #fff;
	font-size: 18px;
	float    : none !important;
}

.cart-top li:last-child {
	border: none;
}

/* breadcrumb */

.breadcrumb-area {
	background-size  : cover;
	position         : relative;
	min-height       : 552px;
	background-repeat: no-repeat;
}

.breadcrumb {
	display         : inline-block;
	-ms-flex-wrap   : wrap;
	flex-wrap       : unset;
	padding         : 0;
	margin-bottom   : 0;
	list-style      : none;
	background-color: unset;
	border-radius   : 0;
}

.breadcrumb li {
	display       : inline-block;
	font-family   : 'Teko', sans-serif;
	text-transform: uppercase;
	font-size     : 22px;
}

.breadcrumb li a {
	color      : #fff;
	font-weight: 600;
}

.breadcrumb-item+.breadcrumb-item:before {
	display      : inline-block;
	padding-right: 15px;
	padding-left : 10px;
	color        : #ddd;
	content      : "";
	font-family  : "Font Awesome 5 Pro";
	font-size    : 16px;
}

.breadcrumb-title h2 {
	font-size    : 82px;
	margin-bottom: 25px;
	line-height  : 1;
	color        : #fff;
	margin-top   : 140px;
	position     : relative;
}

.breadcrumb-title p {
	margin-bottom: 0;
	color        : #777;
	font-size    : 16px;
}

.breadcrumb>.active {
	color      : #fff;
	font-weight: 600;
}

/* 3. slider */

.slider-bg {
	min-height         : 1000px !important;
	background-position: center bottom;
	background-size    : cover;
	position           : relative;
	z-index            : 1;
}

.show-bg3:before {
	content   : "";
	position  : absolute;
	left      : 0;
	top       : 0;
	height    : 100%;
	width     : 100%;
	background: #000;
	opacity   : .7;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	z-index   : 1;
}

.slider-bg2 {
	min-height         : 900px;
	background-position: center;
	background-size    : cover;
	position           : relative;
	margin-top         : -118px;
	z-index            : 1;
}

.slider-bg2 .slider-content>span:before {
	content     : "";
	background  : #383838;
	height      : 3px;
	float       : left;
	width       : 60px;
	margin-top  : 15px;
	margin-right: 10px;
}

.slider-bg2 .slider-content h2 {
	color: #fff;
}

.slider-bg2 .slider-content p {
	color        : #676f67;
	font-size    : 16px;
	margin-bottom: 0;
}

.slider-bg2 .slider-content>span {
	font-size    : 22px;
	font-weight  : 500;
	color        : #ffc010;
	display      : block;
	margin-bottom: 20px;
	margin-top   : 30px;
}

.slider-bg .video-i i {
	background   : none;
	padding      : 14px;
	border-radius: 100%;
	width        : 50px;
	display      : inline-block;
	text-align   : center;
	color        : #ffc010;
	height       : 50px;
	line-height  : 20px;
	border       : 2px solid #ffc010;
}

.slider-bg2 .video-i {
	background   : #383838;
	padding      : 14px;
	border-radius: 100%;
	width        : 51px;
	display      : inline-block;
	text-align   : center;
	color        : #fff;
	margin-left  : 20px;
}

.slider-bg2 .video-i:hover {
	background   : #ffc010;
	padding      : 14px;
	border-radius: 100%;
	width        : 51px;
	display      : inline-block;
	text-align   : center;
	color        : #fff;
	margin-left  : 20px;
}

#particles-js {
	position: absolute;
	left    : 0;
	width   : 100%;
	height  : 100%;
	z-index : -1;
}

.slider-content>span {
	font-size     : 16px;
	font-weight   : 500;
	color         : #ffc010;
	text-transform: uppercase;
	letter-spacing: 6px;
	display       : block;
	margin-bottom : 20px;
}

.slider-content h2 {
	font-size     : 82px;
	color         : #fff;
	letter-spacing: 1.0px;
	line-height   : 1.2;
	margin-bottom : 40px;
	font-weight   : 700;
	letter-spacing: 2.5px;
}

.slider-content h2 span {
	color: #ffc010;
}

.slider-content h5 {
	color         : #68fe9a;
	margin-bottom : 15px;
	text-transform: uppercase;
	font-size     : 18px;
	display       : inline-block;
	letter-spacing: 4px;
	font-weight   : 500;
}

.slider-content p span {
	display     : inline-block;
	height      : 2px;
	width       : 40px;
	background  : #ffc010;
	margin-right: 20px;
	position    : relative;
	top         : -4px;
}

.slider-content p {
	color        : #fff;
	font-size    : 18px;
	margin-bottom: 0;
}

.slider-btn .btn {
	background: #fff;
	color     : #ffc010;
}

.slider-btn .btn:before {
	content: none;
}

.slider-btn .btn:hover {
	box-shadow: none;
}

.p-relative {
	position: relative;
}

.down-arrow {
	position     : absolute;
	bottom       : 60px;
	left         : 0;
	right        : 0;
	color        : #fff;
	z-index      : 1;
	height       : 60px;
	width        : 30px;
	margin       : auto;
	text-align   : center;
	line-height  : 60px;
	border       : 2px solid #fff;
	border-radius: 50px;
	font-size    : 18px;
}

.down-arrow:hover {
	color: #fff;
}

.slider-active .slick-arrow {
	position          : absolute;
	top               : 45%;
	-webkit-transform : translateY(-50%);
	transform         : translateY(-50%);
	left              : 15%;
	color             : #ffc010;
	font-size         : 40px;
	line-height       : 65px;
	border            : none;
	text-align        : center;
	z-index           : 9;
	cursor            : pointer;
	padding           : 0;
	background        : none;
	-webkit-transition: .5s;
	transition        : .5s;
}

.slider-active .slick-next {
	top: 53%;
}

.slider-active .slick-arrow:hover {
	color: #fff;
}

.second-slider-content h2 {
	font-size    : 90px;
	margin-bottom: 20px;
}

.second-slider-bg:before {
	opacity   : .5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.second-slider-content {
	padding-top   : 85px;
	padding-bottom: 5px;
}

.s-slider-content h2 {
	margin-bottom: 25px;
}

.s-slider-content p {
	font-size    : 16px;
	padding-right: 50px;
	color        : #fff;
	line-height  : 26px;
}

.btn.ss-btn {
	border-radius : 10px;
	color         : #FFF;
	padding       : 20px 30px;
	text-transform: uppercase;
	background    : none;
	border        : 1px solid #ffc010;
}

.btn.ss-btn:hover {
	background: #ffc010;
	color     : #fff;
}

.slider-btn .btn.ss-btn {
	width           : 210px;
	background-color: rgb(255, 192, 16);
	border-radius   : 9px;
	background-image: url(../img/bg/sbtn.png);
	font-size       : 18px;
}

.btn-icon {
	position     : absolute;
	left         : 10px;
	top          : 10px;
	bottom       : 10px;
	background   : #ffb3a5;
	padding      : 10px 15px;
	border-radius: 10px;
	color        : #fff;
}

.btn.ss-btn.active {
	background: #ffc010;
	border    : 2px solid #ffc010;
	color     : #fff;
}

.line {
	display: block;
}

.slider-shape {
	position: absolute !important;
	z-index : 9;
}

.ss-one {
	top : 180px !important;
	left: 115px !important;
}

.ss-two {
	top : 60% !important;
	left: 18% !important;
}

.ss-three {
	top : 80% !important;
	left: 8% !important;
}

.ss-four {
	top : 85% !important;
	left: 30% !important;
}

.ss-five {
	left: 61% !important;
	top : 83% !important;
}

.ss-six {
	left: 88% !important;
	top : 80% !important;
}

.ss-seven {
	top : 20% !important;
	left: 89% !important;
}

.slider-bg .text2 h2 {
	font-size: 58px;
}

.slider-bg .text2 a {
	color: #FFF;
}

.slider-bg .text2 .btn.ss-btn.active {
	background: #ffc010;
	border    : 2px solid #ffc010;
	color     : #FFF;
}

.slider-bg .text2 p {
	padding-right: 22%;
	color        : #fff;
}

/* 4. about */

.about-img {
	margin-left: -50px;
}

.about-text {
	position        : absolute;
	bottom          : 0px;
	right           : 30px;
	text-align      : center;
	border-radius   : 10px;
	background-image: -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	padding         : 30px;
	width           : 203px;
	height          : 202px;
	font-family     : 'Teko', sans-serif;
	font-size       : 25px;
	color           : #fff;
	font-weight     : 600;
}

.Bg {
	border-radius   : 10px;
	background-image: -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	position        : absolute;
	left            : 375px;
	top             : 1640px;
	width           : 203px;
	height          : 202px;
	z-index         : 137;
}

.about-text span {
	font-size    : 80px;
	color        : #fff;
	position     : relative;
	display      : block;
	font-weight  : 600;
	margin-bottom: 27px;
	margin-top   : 18%;
	font-family  : 'Teko', sans-serif;
}

.about-text span sub {
	font-size  : 24px;
	color      : #ffc010;
	font-weight: 400;
	margin-left: -20px;
}

.s-about-img>img {
	display                : block;
	margin-left            : auto;
	border-top-right-radius: 20px;
	border-top-left-radius : 20px;
}

.experience-years {
	position: absolute;
	top     : -50px;
	left    : 10%;
}

.experience-years span {
	position         : absolute;
	top              : 50%;
	left             : 50%;
	color            : #ffc010;
	font-size        : 60px;
	font-weight      : 900;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
}

/* ecperience image spin */
.experience-years img {
	-webkit-animation: spin 20s infinite linear;
	animation        : spin 20s infinite linear;
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(1turn);
		transform        : rotate(1turn);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(1turn);
		transform        : rotate(1turn);
	}
}


.about-image2 {
	position: absolute;
	top: 50%;
	left    : 0;
	bottom  : -35px;
}

.about-image2 img {
	border-radius: 20px;
}

.about-image3 {
	position: absolute;
	right   : -60px;
	bottom  : -45px;
}

.about-content.s-about-content {
	max-width  : 466px;
	margin-left: auto;
}

.s-about-content p b {
	font-size     : 18px;
	font-weight   : 600;
	font-style    : italic;
	text-transform: uppercase;
}

.about-title>span {
	font-size     : 14px;
	font-weight   : 500;
	text-transform: uppercase;
	color         : #fff;
	background    : #ffc010;
	height        : 30px;
	display       : inline-block;
	text-align    : center;
	line-height   : 30px;
	margin-bottom : 30px;
}

.about-title h2 {
	font-size    : 60px !important;
	margin-bottom: 0 !important;
}

.about-title h5 {
	color        : #ffc010;
	font-size    : 22px;
	margin-bottom: 15px;
}

.about-title .title-strong {
	color      : #4f4f4f;
	font-weight: 500;
	font-size  : 18px;
}

.about-title .title-strong span {
	padding      : 3px 12px;
	background   : #ffc010;
	border-radius: 5px;
	color        : #fff;
}

.about-title p span {
	display     : inline-block;
	height      : 2px;
	width       : 40px;
	background  : #ffc010;
	margin-right: 20px;
	position    : relative;
	top         : -5px;
}

.about-title p {
	margin-bottom: 0;
}

.about-content p {
	margin-bottom: 45px;
}

.about-content li {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
	margin-bottom: 10px;
}

.about-title h2 span {
	color: #ffc010;
}

.about-content li .icon i {
	width        : 60px;
	height       : 60px;
	background   : #FFFFFF 0% 0% no-repeat padding-box;
	border       : 2px solid #EEEEEE;
	border-radius: 50%;
	text-align   : center;
	line-height  : 60px;
	margin-right : 20px;
	color        : #ffc010;
}

.about-content .exprince {
	border-radius   : 10px;
	background-color: rgb(255, 255, 255);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
	text-align      : center;
	padding         : 30px 0;
}

.about-content .exprince h5 {
	margin-top   : 15px;
	margin-bottom: 0;
	font-size    : 25px;
	font-weight  : 500;
	color        : #0595b8;
}

.about-content .exprince p {
	margin-bottom: 0;
}

.ab-ul {
	margin-top: 30px;
}

.ab-ul li {
	float: left;
	width: 50%;
}

.ab-ul li .icon i {
	width        : 40px;
	height       : 40px;
	background   : #FFFFFF 0% 0% no-repeat padding-box;
	border       : 2px solid #EEEEEE;
	border-radius: 50%;
	text-align   : center;
	line-height  : 40px;
	margin-right : 20px;
	color        : #ffc010;
}

.sr-ul {
	margin-top: 30px;
}

.sr-ul li {
	float           : left;
	width           : 46.2%;
	background-color: rgb(255, 255, 255);
	box-shadow      : 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
	margin          : 0 20px 30px 0;
	padding         : 15px 20px;
}

.sr-ul li .icon i {
	width        : inherit;
	height       : inherit;
	background   : inherit;
	border       : inherit;
	border-radius: inherit;
	line-height  : inherit;
	font-size    : 30px;
	color        : #4cc3c1;
}

.sr-ul li .text {
	font-size  : 14px;
	font-weight: bold;
	color      : #181e23;
}

.sr-tw-ul {
	margin-top: 30px;
	position  : relative;
	z-index   : 1;
}

.sr-tw-ul h4 {
	font-size: 25px;
}

.sr-tw-ul li {
	float  : left;
	width  : 100%;
	padding: 20px;
}

.sr-tw-ul li:hover {
	background       : url(../img/bg/sr-hover-bg.png);
	background-repeat: no-repeat;
	background-size  : cover;
	box-shadow       : 0px 1px 59px 0px rgba(0, 0, 0, 0.11);
	border-radius    : 4px;
}

.sr-tw-ul li .icon {
	display     : inline-block;
	text-align  : center;
	margin-top  : 15px;
	margin-right: 15px;
	width       : 140px;
}

.sr-tw-ul li .icon-right {
	display    : inline-block;
	text-align : center;
	margin-top : 15px;
	margin-left: 15px;
	width      : 130px;
}

.section-t h2 {
	font-size  : 350px;
	position   : absolute;
	left       : 0;
	right      : 0;
	text-align : center;
	z-index    : -1;
	color      : #010f2e;
	opacity    : .05;
	-ms-filter : "progid:DXImageTransform.Microsoft.Alpha(Opacity=5)";
	margin     : 0;
	font-family: 'Playfair Display', serif;
	font-weight: 500;
	font-style : italic;
	top        : -35px;
	line-height: 1;
}

.second-about {
	top              : 0;
	-webkit-transform: translate(8%, -50px);
	transform        : translate(8%, -50px);
	left             : 0;
	right            : 0;
	bottom           : 0;
}

.second-atitle>span {
	font-size     : 16px;
	font-weight   : 500;
	text-transform: uppercase;
	color         : #ffc010;
	letter-spacing: 2px;
	display       : inline-block;
	margin-bottom : 20px;
	background    : transparent;
}

.s-about-content p {
	margin-bottom: 15px;
}

.about-content strong {
	color: #ffc010;
}

.sinature-box {
	float     : left;
	width     : 100%;
	box-shadow: 0px 16px 32px #0000000A;
	padding   : 25px 30px 15px 30px;
	margin-top: 50px;
}

.sinature-box h2 {
	font-size    : 20px;
	font-weight  : 500;
	font-family  : 'Teko', sans-serif;
	color        : #010f2e;
	margin-bottom: 5px;
}

.sinature-box h3 {
	font-size  : 14px;
	font-family: 'Teko', sans-serif;
	color      : #4CC3C1;
}

.sinature-box .user-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.sinature-box .user-box .text {
	padding-top : 10px;
	padding-left: 15px;
}

.signature-text {
	font-size  : 22px;
	color      : #ffc010;
	font-family: 'Playfair Display', serif;
	font-weight: 600;
}

.circle-right {
	display: inline-block;
}

.ab-coutner li {
	display     : inline-block;
	margin-right: 22px;
}

.ab-coutner .single-counter {
	background-color: rgb(250, 162, 146);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
	border-radius   : 10px;
	padding         : 70px 0;
}

.ab-coutner .single-counter p {
	margin-top : 15px;
	color      : #4f4f4f;
	font-family: 'Playfair Display', serif;
	font-size  : 20px;
	font-weight: 600;
}

.clinet-abimg {
	position               : absolute;
	bottom                 : -76px;
	right                  : 0;
	animation              : alltuchtopdown 3s infinite;
	-webkit-animation      : alltuchtopdown 3s infinite;
	animation-delay        : 0s;
	-webkit-animation-delay: 1s;
}

/* 5. features */

.features-content h4 {
	font-size    : 24px;
	margin-bottom: 17px;
}

.features-content p {
	padding      : 0 40px;
	margin-bottom: 25px;
}

.s-btn {
	font-size    : 14px;
	font-weight  : 500;
	color        : #010f2e;
	display      : inline-block;
	border       : 1px solid #ccc;
	padding      : 9px 30px;
	border-radius: 50px;
}

.s-btn:hover {
	background  : #ffc010;
	border-color: #ffc010;
	color       : #fff;
}

.section-title span {
	color: #ffc010;
}

.section-title h5 {
	color        : #ffc010;
	margin-bottom: 15px;
}

.section-title h2 {
	font-size     : 48px;
	padding-bottom: 0;
	margin-bottom : 10px !important;
	position      : relative;
}

.section-title h2:before {
	content           : "";
	position          : absolute;
	left              : 0;
	right             : 0;
	bottom            : 0;
	height            : 4px;
	width             : 60px;
	background        : #ffc010;
	margin            : auto;
	-webkit-transition: .3s;
	transition        : .3s;
	display           : none;
}

.section-title p {
	width : 65%;
	margin: 10px auto;
}

section:hover .section-title h2:before {
	width: 100px;
}

.features-p {
	padding-top: 380px;
}

.features-icon img {
	border            : 5px solid transparent;
	border-radius     : 50%;
	-webkit-transition: .5s;
	transition        : .5s;
}

.s-features:hover .features-icon img {
	border: 5px solid #ffc010;
}

.features-shape {
	position: absolute;
	z-index : -1;
}

.fshape-one {
	left: 120px;
	top : 25%;
}

.fshape-two {
	left: 15%;
	top : 51%;
}

.fshape-three {
	top : 69%;
	left: 6%;
}

.fshape-four {
	top : 40%;
	left: 89%;
}

.fshape-five {
	top : 71%;
	left: 83%;
}

.about-content3 li:before {
	font-family : "Font Awesome 5 Pro";
	content     : "";
	margin-right: 10px;
	color       : #ffc010;
}

/* 6. services */

#feature-top {
	margin-top: -120px;
	z-index   : 999;
	position  : relative;
}

.services-two .s-single-services {
	border-radius: 0 0 0 0;
}

.services-two .s-single-services .btn2 {
	display: inline-block;
	color  : #ffc010;
}

.services-area.gray-bg {
	position: relative;
	z-index : 1;
	overflow: hidden;
}

.single-services {
	background: #fff;
	box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.07);
	position  : relative;
}

.single-services:before {
	content           : "";
	height            : 100%;
	width             : 100%;
	position          : absolute;
	left              : 5px;
	top               : 5px;
	background        : #ffc010;
	z-index           : -1;
	-webkit-transition: .3s;
	transition        : .3s;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.service-t h2 {
	top: 55px;
}

.services-thumb img {
	width: 100%;
}

.services-content span {
	display           : block;
	height            : 2px;
	width             : 150px;
	background        : #ffc010;
	-webkit-transition: .3s;
	transition        : .3s;
	margin-bottom     : 55px;
}

.services-content {
	padding       : 60px 40px;
	padding-bottom: 55px;
}

.services-content small {
	font-size     : 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	display       : block;
	margin-bottom : 20px;
}

.services-content h4 {
	font-size    : 24px;
	margin-bottom: 23px;
}

.services-content h4:hover a {
	color: #ffc010;
}

.services-content p {
	margin-bottom: 0;
}

.single-services:hover .services-content span {
	background: #ffc010;
}

.single-services:hover:before {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.services-active .slick-dots {
	text-align : center;
	position   : absolute;
	left       : 0;
	right      : 0;
	bottom     : -40px;
	line-height: 1;
}

.services-active .slick-dots li {
	display: inline-block;
	margin : 0 5px;
}

.services-active .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	height            : 5px;
	width             : 20px;
	background        : #ffc010;
	border-radius     : 50px;
	z-index           : 1;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
}

.services-active .slick-dots li.slick-active button {
	width     : 50px;
	background: #ffc010;
}

.services-active .slick-track {
	padding-bottom: 10px;
}

.services-two .services-icon {
	display      : inline-block;
	width        : 90px;
	height       : 90px;
	background   : #FFFFFF 0% 0% no-repeat padding-box;
	text-align   : center;
	border-radius: 50%;
	padding      : 25px;
	margin-bottom: 30px;
}

.services-two .services-icon img {
	width: 100%;
}

.services-two .brline {
	margin-top   : 15px;
	margin-bottom: 25px;
}

.services-two ul li {
	margin-bottom: 25px;
}

.services-two ul li:before {
	font-family : "Font Awesome 5 Pro";
	content     : "";
	margin-right: 10px;
	color       : #ffc010;
}

.services-icon .glyph-icon:before {
	font-size  : 48px;
	color      : #3763eb;
	margin-left: 0;
}

.services-icon2 {
	position: absolute;
	right   : 5%;
	top     : 0;
}

.services-icon2 .glyph-icon:before {
	font-size  : 120px;
	color      : #8ca8ff29;
	margin-left: 0;
}

.services-two .second-services-content {
	float: left;
	width: 100%;
}

.services-two .s-single-services {
	-webkit-transition: .3s;
	transition        : .3s;
	padding           : 45px;
	border            : none;
	margin-bottom     : 30px;
	border-radius     : 0;
}

.services-two .s-single-services {
	float: left;
	width: 100%;
}

.services-three .services-icon {
	display      : inline-block;
	width        : 90px;
	height       : 90px;
	background   : #FFFFFF 0% 0% no-repeat padding-box;
	text-align   : center;
	border-radius: 50%;
	padding      : 25px;
	margin-bottom: 30px;
	box-shadow   : 0px 16px 32px #00000014;
}

.services-three .services-icon img {
	width: 100%;
}

.services-three .brline {
	margin-top   : 15px;
	margin-bottom: 25px;
}

.services-three ul li {
	margin-bottom: 25px;
}

.services-three ul li:before {
	font-family : "Font Awesome 5 Pro";
	content     : "";
	margin-right: 10px;
	color       : #ffc010;
}

.services-three .second-services-content {
	float: left;
	width: 100%;
}

.services-three .s-single-services {
	-webkit-transition: .3s;
	transition        : .3s;
	padding           : 40px 40px 25px;
	border            : none;
	margin-bottom     : 30px;
	border-radius     : 0;
	border            : 2px solid #F0F0F0;
}

.services-three .s-single-services {
	float: left;
	width: 100%;
}

.services-three .s-single-services.active {
	background: #fff;
	margin-top: 0;
	position  : relative;
	z-index   : 1;
	left      : 0;
}

.services-three .s-single-services.active .services-icon {
	background: #fff 0% 0% no-repeat padding-box;
	width     : 90px;
	height    : 90px;
}

.services-three .s-single-services.active a.ss-btn {
	display: none;
}

.second-services-content h5 {
	font-weight       : 600;
	-webkit-transition: .3s;
	transition        : .3s;
	letter-spacing    : 0;
	font-size         : 22px;
	color             : #010f2e;
}

.second-services-content p {
	margin-bottom: 0;
}

/* .second-services-content a {
	font-size: 14px;
	font-weight: 500;
	color: #444d69;
	position: relative;
	transition: .5s;
} */

.second-services-content a:after {
	left      : -40px;
	right     : unset;
	opacity   : 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.s-single-services {
	-webkit-transition: .3s;
	transition        : .3s;
	padding           : 30px;
	border            : 5px solid #f5f8fa;
}

.s-single-services a.ss-btn {
	display: none;
}

.s-single-services .active-icon {
	display: none;
}

.s-single-services .d-active-icon {
	display: block;
}

.s-single-services:hover {
	background: #fff;
}

.s-single-services:hover .second-services-content h5 {
	color: #fff;
}

.s-single-services:hover .second-services-content a {
	color: #ffc010;
}

.s-single-services.active {
	background: #fff;
	margin-top: -160px;
	position  : absolute;
	z-index   : 1;
	left      : 0;
}

.s-single-services.active a.ss-btn {
	display: inline-block;
}

.s-single-services.active .services-icon {
	background: #ffc010 0% no-repeat padding-box;
	width     : 160px;
	height    : 160px;
	padding   : 10%;
}

.s-single-services.active .active-icon {
	display: block;
}

.s-single-services.active .d-active-icon {
	display: none;
}

.services-bg {
	background-position: center;
	background-size    : cover;
}

.services-active2 {
	padding-top   : 50px;
	padding-bottom: 300px;
}

.services-active2 .slick-arrow {
	position          : absolute;
	top               : 50%;
	left              : -60px;
	border            : none;
	background        : none;
	padding           : 0;
	font-size         : 24px;
	color             : #ffc010;
	z-index           : 9;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
}

.services-active2 .slick-next {
	left : inherit;
	right: -60px;
}

.services-active2 .slider-nav .slick-list.draggable {
	width: 1100px;
	float: right;
}

.services-item {
	background   : #fff;
	margin       : 22px 15px;
	box-shadow   : 3px 4px 15px #0000001A;
	text-align   : center;
	border-radius: 10px;
}

.services-active2 .slider-nav {
	position: absolute;
	bottom  : 0px;
}

.services-active2 li {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
	margin-bottom: 10px;
}

.services-item h3 {
	font-size: 20px;
}

.services-item .glyph-icon:before {
	font-size: 60px;
}

.services-active2 li .icon {
	padding-right: 10px;
}

.services-item.slick-slide.slick-current.slick-active {
	background: #3763eb;
}

.services-item.slick-slide.slick-current.slick-active .glyph-icon:before {
	color: #fff;
}

.services-item.slick-slide.slick-current.slick-active h3 {
	color: #fff;
}

.services-area .services-box {
	position          : relative;
	padding           : 40px 50px 45px;
	background        : transparent;
	z-index           : 1;
	box-shadow        : 0px 1px 59px 0px rgba(0, 0, 0, 0.11);
	-webkit-clip-path : polygon(0 35px, 35px 0, 100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 0);
	clip-path         : polygon(0 35px, 35px 0, 100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 0);
	-webkit-transition: .3s;
	transition        : .3s;
	z-index           : 1;
}

.services-area .services-box:hover {
	background-color: #ffc010;
}

.services-area .services-box:before {
	content          : '';
	position         : absolute;
	top              : 1px;
	left             : 1px;
	width            : calc(100% - 2px);
	height           : calc(100% - 2px);
	background       : #20132a;
	z-index          : -1;
	-webkit-clip-path: polygon(0 35px, 35px 0, 100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 0);
	clip-path        : polygon(0 35px, 35px 0, 100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 0);
}

.services-box .services-content2 .services-icon {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	width            : 100%;
	margin-bottom    : 15px;
}

.services-box .services-content2 .services-icon i {
	-webkit-box-flex  : 0;
	-ms-flex          : none;
	flex              : none;
	width             : 90px;
	height            : 90px;
	color             : #ffc010;
	font-size         : 50px;
	line-height       : 96px;
	text-align        : center;
	background        : #202020;
	margin-right      : 30px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.services-box:hover .services-content2 .services-icon i {
	color     : #fff;
	background: #ffc010;
}

.services-box .services-content2 p {
	margin-bottom: 0;
}

.services-content2 h5 {
	font-size     : 24px;
	margin-bottom : 0px;
	text-transform: capitalize;
	font-weight   : 500;
}

.services-content2 h5 a {
	color: #fff;
}

.best-gaming {
	background   : #1e1226;
	border-radius: 20px;
	position     : relative;
	z-index      : 1;
}

.best-gaming .about-shape {
	position: absolute;
	top     : 0;
	left    : 0;
	width   : 58%;
	z-index : -1;
}

.best-gaming .about-content.s-about-content {
	max-width  : 480px;
	margin-left: 100px;
	padding-top: 110px;
}

.best-gaming p {
	margin-bottom : 30px;
	font-size     : 18px;
	font-style    : italic;
	font-weight   : 500;
	text-transform: uppercase;
}

.best-gaming h2 {
	font-size    : 48px;
	margin-bottom: 10px !important;
}

.about-right-image {
	margin-top    : -50px;
	margin-right  : -30px;
	padding-bottom: 100px;
}

.about-right-image img {
	border-radius: 20px;
}

.services-content3 h6 {
	font-weight: 500;
	color      : #ffffffc7;
}

.services-box.service2 {
	padding          : 0;
	box-shadow       : none;
	-webkit-clip-path: none;
	clip-path        : none;
}

.services-box.service2:hover {
	background: none;
}

.services-box.service2:before {
	content: none;
}

.services-box.service2 .services-icon {
	width        : 270px;
	height       : 270px;
	padding-top  : 50px;
	border-radius: 50%;
	background   : #20132a;
	margin-left  : auto;
	margin-right : auto;
}

.services-box.service2 .services-icon span {
	color            : #0e140f;
	font-size        : 14px;
	padding          : 0 15px;
	display          : inline-block;
	margin-bottom    : 15px;
	position         : absolute;
	bottom           : 5px;
	left             : 50%;
	width            : 87px;
	height           : 27px;
	text-align       : center;
	font-weight      : 600;
	background       : #ffc010;
	-webkit-transform: translateX(-50%);
	transform        : translateX(-50%);
	text-transform   : uppercase;
	-webkit-clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 100%, 10px 100%);
	clip-path        : polygon(0 0, calc(100% - 10px) 0, 100% 100%, 10px 100%);
}

.services-box .ss-btn2 {
	width             : 60px;
	height            : 60px;
	background        : #ffc010 0% no-repeat padding-box;
	box-shadow        : 0px 16px 32px #ffc01033;
	display           : none;
	color             : #fff;
	line-height       : 60px;
	font-size         : 31px;
	border-radius     : 50%;
	position          : absolute;
	left              : 42%;
	bottom            : -25px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.services-box .services-content2:hover .ss-btn2 {
	display: inline-block;
}

.services-02 ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border : 2px solid #F5F5F5;
}

.services-02 ul li {
	padding           : 30px;
	border-right      : 2px solid #F5F5F5;
	background        : #fff;
	-webkit-transition: .2s;
	transition        : .2s;
}

.services-02 ul li:last-child {
	border: none;
}

.services-icon-02 {
	margin-bottom: 30px;
}

.services-content-02 h5 {
	font-size    : 24px;
	margin-bottom: 20px;
}

.services-content-02 {
	font-size: 14px;
}

.services-content-02 .ss-btn2 {
	margin-top: 20px;
	display   : block;
}

.services-content-02 .ss-btn2 i {
	width        : 40px;
	height       : 40px;
	border       : 2px solid #E6E6E6;
	border-radius: 50%;
	line-height  : 35px;
	text-align   : center;
	color        : #777;
}

.services-content-02 .ss-btn2 span {
	color             : #ffc010;
	margin-left       : -10px;
	font-weight       : 600;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.5s ease 0s;
	transition        : all 0.5s ease 0s;
}

.services-02 ul li:hover {
	border            : none;
	box-shadow        : 0px 16px 32px #0000000A;
	-webkit-transform : scale(1.08);
	transform         : scale(1.08);
	-webkit-transition: .2s;
	transition        : .2s;
}

.services-02 ul li:hover .ss-btn2 span {
	margin-left       : 5px;
	opacity           : 1;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-webkit-transition: .2s;
	transition        : .2s;
}

.services-box-03 {
	background: #fff;
	padding   : 50px;
}

.services-box-04 {
	border-radius   : 3px;
	background-color: rgb(255, 255, 255);
	box-shadow      : 0px 8px 38px 0px rgba(40, 40, 40, 0.04);
	padding         : 45px;
}

.services-icon-04 {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
	margin-bottom: 30px;
}

.services-icon-04 img {
	margin-right: 15px;
}

.services-icon-04 h4 {
	padding: 15px 0 0;
}

.services-box-04 .services-content-02 li {
	font-weight: 500;
	font-size  : 16px;
	margin-top : 10px;
	color      : #181e23;
}

.services-box-04 .services-content-02 li:before {
	font-family : "Font Awesome 5 Pro";
	content     : "";
	margin-right: 10px;
	color       : #ffc010;
}

.services-box-04 .services-content-02 .blue li:before {
	color: #4cc3c1;
}

.services-box-04 .services-content-02 .green li:before {
	color: #2db065;
}

.services-05 h2 {
	color: #fff;
}

.services-05:before {
	content   : "";
	background: #181e23;
	position  : absolute;
	top       : 0;
	width     : 100%;
	height    : 529px;
}

.services-content-05 {
	background-color: rgb(255, 255, 255);
	box-shadow      : 0px 8px 38px 0px rgba(40, 40, 40, 0.04);
	padding         : 30px;
	margin-bottom   : 50px;
}

.services-content-05 span {
	color        : #ffc010;
	font-size    : 15px;
	font-weight  : 500;
	margin-bottom: 10px;
	display      : block;
}

.services-text05 p {
	font-size: 20px;
}

.services-text05 a {
	font-size      : 20px;
	color          : #ffc010;
	font-weight    : 500;
	text-decoration: underline;
}

.services-07 .section-title h2 {
	color: #fff;
}

.services-box07 .sr-contner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.services-box07 .sr-contner .icon {
	width       : 100px;
	margin-right: 30px;
}

.services-box07 .sr-contner .text h5 {
	color    : #fff;
	font-size: 18px;
}

.services-box07 .sr-contner .text p {
	color     : #9aa4a9;
	margin-top: 20px;
}

.services-07 .user-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.services-07 .user-box .text {
	padding-top : 10px;
	padding-left: 15px;
}

.services-07 .user-box .text h2 {
	font-size    : 24px;
	font-weight  : 500;
	font-family  : 'Teko', sans-serif;
	color        : #fff;
	margin-bottom: 5px;
}

.services-07 .user-box .text h3 {
	font-size  : 14px;
	font-family: 'Teko', sans-serif;
	color      : #9a9fa2;
}

.services-08-item {
	text-align: center;
}

.services-08-item {
	text-align: center;
	padding   : 30px 15px;
}

.services-08 .services-08-item--wrapper {
	border-bottom: 2px solid #eeeeee17;
}

.services-08 .services-08-item--wrapper:nth-child(3) {
	border-bottom: none;
}

.services-08-item--wrapper>div {
	border-right: 2px solid #eeeeee17;
}

.services-08-item--wrapper>div:nth-child(3) {
	border-right: none;
}

.services-08-thumb {
	margin-bottom: 30px;
}

.services-08-content h3 {
	margin-bottom: 20px;
	font-size    : 24px;
}

.services-08-content,
.services-08-thumb {
	position: relative;
	z-index : 1;
}

.services-08-item:before {
	position          : absolute;
	content           : '';
	left              : 0px;
	top               : 0px;
	width             : 100%;
	height            : 100%;
	-webkit-transition: .3s;
	transition        : .3s;
	z-index           : 1;
}

.services-08-item:hover:before {
	background-color: #fff;
	width           : calc(100% + 20px);
	height          : calc(100% + 20px);
	top             : -10px;
	left            : -10px;
	background-color: #fff;
	box-shadow      : 0px 30px 40px 0px rgba(32, 85, 125, 0.06);
}

.services-09:before {
	display: none;
}

.services-09 p,
.services-09 .section-title h5 {
	color: #fff;
}

.services-area2 .container {
	z-index : 1;
	position: relative;
}

.services-area2 h2 {
	color: #fff;
}

.services-area2 .services-box2 {
	border-radius   : 10px;
	background-color: rgb(255, 255, 255);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.07);
	padding         : 30px;
}

.services-area2 .services-box2 h5 {
	font-size : 24px;
	margin-top: 30px;
}

.services-area2 .services-box2 .services-icon {
	border-radius   : 15px;
	background-color: #fff7e7;
	width           : 80px;
	height          : 80px;
	line-height     : 80px;
	display         : inline-block;
	text-align      : center;
}

.services-area2 .services-box2 .services-icon i {
	border: none;
	color : #fcaf17;
}

.services-area2 .services-box2 p {
	margin-bottom: 0;
}

.services-area2 .services-box2:hover {
	background: #ffc010;
	color     : #fff;
}

.services-area2 .services-box2:hover h5 a {
	color: #fff;
}

/* 7. video */

.video-full-img p {
	color: #ffffffc7;
}

.video-grid .video-full-img p {
	color: #fff;
}

.video-area .btn.ss-btn {
	background-image: -webkit-linear-gradient(0deg, rgb(245, 105, 22) 0%, rgb(241, 171, 15) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(245, 105, 22) 0%, rgb(241, 171, 15) 100%);
	border-radius   : 4px;
	color           : #FFF;
	padding         : 20px 30px;
	box-shadow      : 2.5px 4.33px 20px 0px rgba(94, 23, 223, 0.3);
}

.video-position {
	position: absolute;
	bottom  : -170px;
	left    : 50px;
}

.video-img {
	position: relative;
	overflow: hidden;
	z-index : 1;
}

.video-img img {
	width: 100%;
}

.video-img:before {
	content   : "";
	position  : absolute;
	left      : 0;
	top       : 0;
	height    : 100%;
	width     : 100%;
	opacity   : .65;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
}

.video-img a {
	position         : absolute;
	top              : 50%;
	left             : 50%;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
	z-index          : 9;
	font-size        : 14px;
	color            : #ffc010;
	height           : 80px;
	width            : 80px;
	text-align       : center;
	background       : transparent -webkit-linear-gradient(left, #ffc010, #ffc01010) 0% 0% no-repeat padding-box;
	background       : transparent linear-gradient(90deg, #ffc010, #ffc01010) 0% 0% no-repeat padding-box;
	border-radius    : 50%;
	padding          : 25px;
}

.video-img a:before {
	content          : "";
	position         : absolute;
	left             : 50%;
	top              : 50%;
	height           : 90px;
	width            : 90px;
	background       : transparent -webkit-linear-gradient(left, #ffc010, #ffc01010) 0% 0% no-repeat padding-box;
	background       : transparent linear-gradient(90deg, #ffc010, #ffc01010) 0% 0% no-repeat padding-box;
	z-index          : -1;
	border-radius    : 50%;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation        : pulse-border 1500ms ease-out infinite;
}

@-webkit-keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform        : translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity          : 1;
		-ms-filter       : "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	}

	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform        : translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity          : 0;
		-ms-filter       : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
}

@keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform        : translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity          : 1;
		-ms-filter       : "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	}

	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform        : translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity          : 0;
		-ms-filter       : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
}

.left-align h2:before {
	margin: unset;
}

.video-content p {
	margin-bottom: 30px;
}

.v-list ul li {
	margin-bottom: 20px;
}

.v-list ul li:last-child {
	margin-bottom: 0px;
}

.v-list ul li i {
	color       : #ffc010;
	display     : inline-block;
	margin-right: 5px;
}

.v-padding {
	padding-top: 35px;
}

.s-video-wrap {
	background-position: center;
	background-size    : cover;
	width              : 100%;
	height             : 647px;
	display            : -webkit-box;
	display            : -ms-flexbox;
	display            : flex;
	-webkit-box-align  : center;
	-ms-flex-align     : center;
	align-items        : center;
	margin             : auto;
}

.s-video-content {
	float     : left;
	width     : 100%;
	text-align: center;
}

.s-video-content a {
	z-index      : 9;
	color        : #ffc010;
	font-size    : 90px;
	text-align   : center;
	border-radius: 50%;
	display      : inline-block;
	margin-bottom: 25px;
	position     : relative;
}

.s-video-content a:before {
	content          : "";
	position         : absolute;
	left             : 50%;
	top              : 50%;
	height           : 178px;
	width            : 178px;
	background       : rgba(255, 255, 255, 0.479);
	z-index          : -1;
	border-radius    : 50%;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation        : pulse-border 1500ms ease-out infinite;
	display          : none;
}

.s-video-content h1 {
	font-size     : 48px;
	margin-top    : 40px;
	letter-spacing: 3px;
}

.s-video-content h2 {
	font-size     : 55px;
	color         : #fff;
	font-weight   : 600;
	letter-spacing: -2px;
	margin-bottom : 20px;
}

.s-video-content p {
	font-size    : 18px;
	color        : #fff;
	margin-bottom: 0;
}

.video-active .slick-arrow {
	border-radius     : 50%;
	position          : absolute;
	top               : 43%;
	-webkit-transform : translateY(43%);
	transform         : translateY(43%);
	left              : -20px;
	border            : none;
	background        : none;
	background-image  : none;
	padding           : 0;
	color             : #f6fbff;
	z-index           : 1;
	-webkit-transition: all 0.3s ease-out 0s;
	transition        : all 0.3s ease-out 0s;
	cursor            : pointer;
	width             : 80px;
	height            : 80px;
	text-align        : center;
	color             : #fff;
	background-color  : #20132a;
}

.video-active .slick-arrow.slick-next {
	right: -30px;
	left : auto;
}

.video-active .slick-arrow:hover,
.video-active .slick-arrow:focus {
	background-color: rgb(255, 192, 16);
}

.video-active2 .s-video-wrap {
	background-position: 0 0;
	background-size    : 100%;
	width              : 100%;
	height             : 330px;
	display            : -webkit-box;
	display            : -ms-flexbox;
	display            : flex;
	-webkit-box-align  : center;
	-ms-flex-align     : center;
	align-items        : center;
	margin             : auto;
	background-repeat  : no-repeat;
}

.video-active2 {
	margin-left : -300px;
	margin-right: -300px;
}

.video-active2 .play-btn img {
	display      : inline-block;
	margin-bottom: 30px;
}

.macthe-active table,
.macthe-active table td {
	width: 100%;
}

.macthe-active .team,
.macthe-active .team2 {
	background: #2a2145;
	padding   : 30px;
	display   : inline-block;
	width     : 100%;
	min-height: 260px;
}

.macthe-active .team2 img {
	float: right;
}

.macthe-active .slick-dots .slick-active button {
	background-color: rgb(255, 192, 16);
}

.macthe-active .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	margin-left       : 10px;
	z-index           : 1;
	cursor            : pointer;
	background        : no-repeat;
	background-color  : rgba(0, 0, 0, 0);
	-webkit-transition: .3s;
	transition        : .3s;
	background-color  : #282f35;
	height            : 15px;
	width             : 15px;
	border-radius     : 50%;
}

.macthe-active .slick-dots li {
	display: inline-block;
	margin : 0 0px;
}

.macthe-active .slick-dots {
	text-align   : center;
	margin-bottom: 120px;
	margin-top   : 30px;
}

.testimonial-area .macthe-active .slick-dots {
	margin-bottom: 0 !important;
}

/* 8. choose */

.chosse-img {
	background-size    : cover;
	height             : 100%;
	right              : 0;
	position           : absolute;
	top                : 0;
	width              : 50%;
	background-repeat  : no-repeat;
	background-position: center;
}

.choose-list {
	float: left;
	width: 100%;
}

.choose-list ul li {
	margin-bottom: 20px;
	color        : #444d69;
	width        : 50%;
	float        : left;
}

.choose-list ul li i {
	color       : #ffc010;
	display     : inline-block;
	margin-right: 5px;
	font-size   : 18px;
}

.choose-area {
	background-size    : cover;
	background-position: center;
}

.choose-content p {
	margin-bottom: 15px;
	color        : #444d69;
	padding-right: 50px;
}

/* 9. brand */

.single-brand {
	text-align: center;
}

.single-brand img {
	display   : inline-block;
	opacity   : .5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.single-brand img:hover {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.brand-area2 {
	margin-top: -70px;
	position  : relative;
}

/* 10. work-process */

.wp-bg {
	background-position: center;
	background-size    : cover;
}

.w-title h2 {
	color: #383838;
}

.w-title h2:before {
	background-color: #ffc010;
}

.wp-list {
	background    : #232c8e;
	box-shadow    : 0px 10px 30px 0px rgba(29, 38, 129, 0.48);
	padding       : 80px 50px;
	padding-bottom: 75px;
}

.wp-list ul li {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: start;
	-ms-flex-align   : start;
	align-items      : start;
	overflow         : hidden;
	margin-bottom    : 45px;
}

.wp-list ul li:last-child {
	margin-bottom: 0px;
}

.wp-icon {
	margin-right: 25px;
}

.wp-content h5 {
	color        : #fff;
	font-size    : 24px;
	margin-bottom: 15px;
}

.wp-content p {
	color        : #fff;
	margin-bottom: 0;
}

.wp-tag {
	position               : absolute;
	left                   : 70px;
	top                    : 90px;
	animation              : alltuchtopdown 3s infinite;
	-webkit-animation      : alltuchtopdown 3s infinite;
	animation-delay        : 0s;
	-webkit-animation-delay: 1s;
}

@keyframes alltuchtopdown {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}

	50% {
		-webkit-transform: rotateX(0deg) translateY(-10px);
		transform        : rotateX(0deg) translateY(-10px);
	}

	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}
}

@-webkit-keyframes alltuchtopdown {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}

	50% {
		-webkit-transform: rotateX(0deg) translateY(-10px);
		transform        : rotateX(0deg) translateY(-10px);
	}

	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}
}

.wp-img {
	margin-right : -60px;
	margin-top   : -60px;
	margin-bottom: -15px;
}

.wp-thumb img {
	width: 100%;
}

.inner-wp-icon {
	float       : left;
	display     : block;
	margin-right: 30px;
}

.inner-wp-c {
	overflow: hidden;
	display : block;
}

.inner-wp-c h5 {
	font-size    : 24px;
	font-weight  : 600;
	margin-bottom: 15px;
}

.inner-wp-c p {
	margin-bottom: 0;
}

/* 11. team */

.team-area .container {
	z-index : 1;
	position: relative;
}

.team-active .slick-dots {
	text-align: center;
}

.team-active .slick-dots li {
	display: inline-block;
	margin : 0 5px;
}

.team-active .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	margin-left       : 10px;
	border-radius     : 50px;
	z-index           : 1;
	cursor            : pointer;
	background        : no-repeat;
	-webkit-transition: .3s;
	transition        : .3s;
}

.team-active .slick-dots li.slick-active button:before {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.team-active .slick-dots li button:before {
	content            : "";
	width              : 19px;
	height             : 19px;
	float              : left;
	position           : relative;
	margin-top         : -9px;
	left               : -19px;
	border-radius      : 50%;
	opacity            : .7;
	-ms-filter         : "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	background-repeat  : no-repeat;
	background-position: center;
}

.team-thumb {
	text-align   : center;
	display      : block;
	width        : 100%;
	overflow     : hidden;
	margin-bottom: 30px;
}

.team-thumb .brd {
	background   : #ffc010;
	border-radius: 50%;
	position     : relative;
	z-index      : 1;
}

.team-thumb .brd:before {
	position          : absolute;
	content           : '';
	width             : 100%;
	height            : 100%;
	top               : 0;
	left              : 0;
	border-radius     : 50%;
	background        : transparent;
	-webkit-transition: .3s;
	transition        : .3s;
}

.single-team:hover .team-thumb .brd:before {
	background: rgba(255, 192, 16, .5);
}

.team-info {
	padding           : 0 20px;
	-webkit-transition: .3s;
	transition        : .3s;
	overflow          : hidden;
	position          : absolute;
	z-index           : 1;
	top               : 50%;
	left              : 50%;
	width             : 100%;
	-webkit-transform : translate(-50%, -50%);
	transform         : translate(-50%, -50%);
}

.team-thumb .social {
	position          : absolute;
	top               : 0;
	content           : "";
	height            : 100%;
	background        : #000;
	right             : 0;
	text-align        : center;
	background-image  : -webkit-linear-gradient(90deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image  : -ms-linear-gradient(90deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	width             : 60px;
	display           : -ms-inline-grid;
	display           : inline-grid;
	-webkit-transform : translateX(123%);
	transform         : translateX(123%);
	-webkit-transition: all 0.3s ease-out 0s;
	transition        : all 0.3s ease-out 0s;
}

.single-team:hover .team-thumb .social {
	-webkit-transform: translateX(0);
	transform        : translateX(0);
}

.team-thumb .social a {
	display      : block;
	color        : #fff;
	margin-bottom: 15px;
	margin-top   : 15px;
}

.team-thumb img {
	max-width: 100%;
	display  : inline-block;
	width    : 100%;
}

.team-info h4 {
	font-size         : 24px;
	margin-bottom     : 5px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.team-info span {
	font-size         : 16px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.team-social a {
	font-size    : 12px;
	color        : #fff;
	margin       : 0 5px;
	display      : inline-block;
	width        : 40px;
	height       : 40px;
	text-align   : center;
	border-radius: 50%;
	line-height  : 40px;
	box-shadow   : 3px 4px 15px #00000026;
}

.team-info .team-social a:nth-child(1) {
	background: #345aa8;
}

.team-info .team-social a:nth-child(2) {
	background: #00ACED;
}

.team-info .team-social a:nth-child(3) {
	background: #0057FF;
}

.team-info .team-social a:nth-child(4) {
	background: #CD201F;
}

.single-team:hover .team-thumb .brd:before,
.single-team:hover .team-thumb .brd:after {
	color: #ffc010;
}

.single-team:hover .team-info {
	border-color: #ffc010;
}

.team-t h2 {
	top: 50px;
}

.slick-slide {
	outline: none;
}

.team-active {
	position: relative;
}

.team-active .slick-arrow {
	position          : absolute;
	top               : 40%;
	left              : -40px;
	border            : none;
	background        : none;
	padding           : 0;
	font-size         : 50px;
	color             : #ffc010;
	z-index           : 9;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
}

.team-active .slick-next {
	left : inherit;
	right: -40px;
}

.team-area-content {
	padding: 100px 0;
}

.team-info h4 a:hover {
	color: #ffc010;
}

.team-area-content span {
	margin-top   : 10px;
	margin-bottom: 15px;
	color        : #ffc010;
	font-weight  : bold;
	font-size    : 16px;
	display      : inline-block;
}

.team-area-content ul {
	margin-top   : 30px;
	margin-bottom: 50px;
}

.team-area-content li {
	display   : -webkit-box;
	display   : -ms-flexbox;
	display   : flex;
	margin-top: 15px;
}

.team-area-content li .icon i {
	margin-right: 10px;
	color       : #ffc010;
	width       : 20px;
}

.team-area-content li .icon strong {
	color: #181e23;
}

.team-area-content li .icon {
	margin-right: 10px;
	width       : 25%;
}

.team-area-content .social a {
	font-size    : 12px;
	color        : #fff;
	margin       : 0 5px;
	display      : inline-block;
	width        : 40px;
	height       : 40px;
	text-align   : center;
	border-radius: 50%;
	line-height  : 40px;
	box-shadow   : 3px 4px 15px #00000026;
}

.team-area-content .social a:nth-child(1) {
	background: #345aa8;
}

.team-area-content .social a:nth-child(2) {
	background: #00ACED;
}

.team-area-content .social a:nth-child(3) {
	background: #0057FF;
}

.team-area-content .social a:nth-child(4) {
	background: #CD201F;
}

/* 12. project */

.project h2 {
	color: #fff;
}

.single-project .project-info {
	position     : absolute;
	bottom       : 15%;
	right        : 13px;
	border-radius: 20px 0px 0px 20px;
	background   : #ffffffed;
	padding      : 20px;
	width        : 85%;
}

.single-project .project-info h4 {
	font-size: 18px;
}

.single-project .project-info a {
	color: #ffc010;
}

.single-project .project-info a img {
	display    : inline;
	margin-left: 5px;
}

.portfolio-active {
	margin-left : -150px;
	margin-right: -150px;
}

.portfolio-area2 h2 {
	color: #fff;
}

.portfolio-active .single-project {
	position: relative;
}

.portfolio-active .slick-arrow {
	position          : absolute;
	top               : -124px;
	left              : 70.5%;
	border            : none;
	background        : none;
	padding           : 0;
	color             : #fff;
	z-index           : 9;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
	border            : 2px solid #ffc010;
	width             : 50px;
	height            : 50px;
	text-align        : center;
	border-radius     : 50%;
	color             : #ffc010;
}

.portfolio-active .slick-arrow:hover {
	background: #ffc010;
	color     : #fff;
}

.portfolio-active .slick-next {
	left: 73.5%;
}

.portfolio-box {
	width        : 100%;
	height       : 390px;
	box-shadow   : 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.07);
	overflow     : hidden;
	border-radius: 10px;
	position     : relative;
}

.portfolio-box img {
	height     : 390px;
	max-width  : 600px;
	margin-left: -150px;
}

.portfolio-box .caption {
	position          : absolute;
	bottom            : -150px;
	padding           : 30px;
	width             : 100%;
	background        : #fff;
	-webkit-transition: opacity .4s ease, visibility .2s ease;
	transition        : opacity .4s ease, visibility .2s ease;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.portfolio-box:hover .caption {
	bottom    : 0;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.portfolio-box .caption .caption-text {
	position: relative;
}

.portfolio-box .caption .arrow-icon {
	position : absolute;
	right    : 40px;
	top      : 25%;
	font-size: 28px;
	color    : #ffc010;
}

/* 12. counter */

.counter-area h2 {
	color: #fff;
}

.counter-area .col-lg-3.col-md-6 {
	border-right: 2px dotted;
}

.counter-area .col-lg-3.col-md-6:last-child {
	border-right: none;
}

.single-counter i {
	display      : inline-block;
	width        : 80px;
	height       : 80px;
	background   : #fe576b;
	border-radius: 50%;
	margin-bottom: 30px;
	color        : #fff;
	font-size    : 40px;
	line-height  : 80px;
}

.single-counter .icon {
	margin-bottom: 15px;
}

.count {
	font-size    : 50px;
	color        : #fff;
	font-weight  : 600;
	margin-bottom: 5px;
	line-height  : 1;
	display      : inline-block;
	font-family  : 'Teko', sans-serif;
}

.counter small {
	font-size  : 35px;
	font-weight: 600;
	color      : #002c47;
	line-height: 1;
	position   : relative;
}

.single-counter p {
	font-size    : 15px;
	margin-bottom: 0;
	color        : #a7acaf;
}

.single-counter .line {
	display      : inline-block;
	width        : 1px;
	height       : 50px;
	background   : #fe576b;
	margin-bottom: -5px;
	margin-top   : 10px;
}

/* 13. cta */

.cta-bg {
	position           : relative;
	background-size    : cover;
	background-position: center;
	z-index            : 1;
}

.cta-area.cta-bg:before {
	background: #f1f4ff;
	content   : "";
	height    : 70%;
	width     : 100%;
	position  : absolute;
	top       : 15%;
}

.cta-title h2 {
	padding-bottom: 0;
	font-size     : 48px;
}

.cta-title h3 {
	padding-bottom: 0;
	font-size     : 50px;
}

.cta-title p {
	font-size: 18px;
}

.cta-title h2:before {
	content: none;
}

.cta-content p {
	color        : #fff;
	padding-right: 80px;
	margin-bottom: 35px;
}

.cta-btn .btn:before {
	background: #fff;
}

.cta-right p {
	margin-bottom: 0;
	color        : #fff;
	font-size    : 24px;
	font-weight  : 500;
}

.call-tag {
	margin-bottom          : 20px;
	animation              : alltuchtopdown 3s infinite;
	-webkit-animation      : alltuchtopdown 3s infinite;
	animation-delay        : 0s;
	-webkit-animation-delay: 1s;
}

.cta-bg .video-img {
	height: 757px;
}

.cta-bg .social {
	text-align: center;
}

.cta-bg .social .icon {
	display      : inline-block;
	width        : 110px;
	height       : 110px;
	background   : #ffc010;
	padding      : 30px;
	border-radius: 50%;
	line-height  : 47px;
	margin-bottom: 30px;
	box-shadow   : 3px 4px 15px #00000066;
}

.cta-bg .line {
	text-align: center;
}

.cta-bg .social a {
	font-size  : 22px;
	color      : #fff;
	font-weight: 600;
}

.portfolio .button-group {
	padding-bottom: 0;
}

/* 14. testimonial */

.testimonial-area .container {
	z-index : 1;
	position: relative;
}

.testimonial-area .test-bg {
	position: absolute;
	top     : 0;
	left    : -27px;
}

.testimonial-active2 {
	width     : 95%;
	margin    : auto;
	margin-top: 83px;
}

.ta-bg {
	height           : 475px;
	width            : 475px;
	background-repeat: no-repeat;
	margin-top       : 25px;
}

.testimonial-author .ta {
	position          : absolute;
	left              : 50%;
	top               : 50%;
	overflow          : hidden;
	transition        : all 1200ms ease;
	-moz-transition   : all 1200ms ease;
	-webkit-transition: all 1200ms ease;
	-ms-transition    : all 1200ms ease;
	-o-transition     : all 1200ms ease;
}

.author-one.now-in-view {
	top : -25px;
	left: 110px;
}

.author-two.now-in-view {
	top : 21%;
	left: 69%;
}

.author-three.now-in-view {
	left: 67%;
	top : 66%;
}

.author-four.now-in-view {
	top : 77%;
	left: 53px;
}

.author-five.now-in-view {
	left: 50px;
	top : 41%;
}

.testi-author>img {
	float        : left;
	width        : 124px;
	height       : 124px;
	border-radius: 50%;
	margin-bottom: 20px;
}

.ta-info {
	overflow: hidden;
	display : block;
}

.ta-info h6 {
	font-size    : 20px;
	margin-bottom: 5px;
	color        : #fff;
}

.ta-info span {
	color    : #fff;
	font-size: 14px;
}

.testi-author {
	overflow          : hidden;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	-ms-grid-row-align: center;
	align-items       : center;
}

.single-testimonial p {
	color        : #fff;
	font-size    : 14px;
	font-weight  : 500;
	font-style   : italic;
	margin-bottom: 25px;
}

.testimonial-active .slick-arrow {
	position          : absolute;
	bottom            : -80px;
	left              : 0;
	border            : none;
	background        : none;
	padding           : 0;
	font-size         : 24px;
	color             : #fff;
	z-index           : 9;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
	width             : 94%;
}

.testimonial-active .slick-next {
	left: 40px;
}

.testimonial-avatar.p-relative {
	min-height: 455px;
	display   : block;
	overflow  : hidden;
}

.testimonial-avatar .ta {
	position          : absolute;
	left              : 50%;
	top               : 50%;
	overflow          : hidden;
	transition        : all 1200ms ease;
	-moz-transition   : all 1200ms ease;
	-webkit-transition: all 1200ms ease;
	-ms-transition    : all 1200ms ease;
	-o-transition     : all 1200ms ease;
}

.avatar-one.now-in-view {
	top : 0%;
	left: 28%;
}

.avatar-two.now-in-view {
	top              : 50%;
	left             : 0;
	-webkit-transform: translateY(-50%);
	transform        : translateY(-50%);
}

.avatar-three.now-in-view {
	top              : 50%;
	left             : 50%;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
}

.avatar-four.now-in-view {
	top : 20%;
	left: 70%;
}

.avatar-five.now-in-view {
	top : 70%;
	left: 25%;
}

.avatar-six.now-in-view {
	top : 59%;
	left: 64%;
}

.single-testimonial-bg {
	background   : #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow   : 3px 4px 15px #0000001A;
	border-radius: 10px;
	width        : 85%;
	float        : right;
	padding      : 30px 50px 100px 150px;
	margin       : 20px;
	color        : #525c7b;
}

.testimonial-active .slick-slide {
	padding: 15px;
}

.testimonial-active .slick-slide .single-testimonial {
	position: relative;
	padding : 30px;
}

.testimonial-active .slick-slide .single-testimonial:after {
	position         : absolute;
	content          : '';
	top              : 0;
	left             : 0;
	width            : 100%;
	height           : 80%;
	z-index          : -1;
	background       : #20132A;
	-webkit-clip-path: polygon(0 50px, 60px 0, 100% 0, 100% calc(100% - 75px), calc(100% - 90px) 100%, 0 100%, 0 0);
	clip-path        : polygon(0 50px, 60px 0, 100% 0, 100% calc(100% - 75px), calc(100% - 90px) 100%, 0 100%, 0 0);
}

.testimonial-active.style-two .slick-slide .single-testimonial:after {
	content: none;
}

.testimonial-active .testi-img {
	float        : left;
	margin-bottom: 20px;
	position     : absolute;
	left         : 0;
	top          : 80px;
}

.testimonial-active .ta-info {
	overflow   : hidden;
	float      : left;
	margin-left: 15px;
	padding-top: 15px;
}

.testimonial-active .ta-info span {
	color      : #ffc010;
	font-size  : 14px;
	font-weight: 500;
}

.testimonial-active .ta-info h6 {
	font-size    : 20px;
	margin-top   : 5px;
	margin-bottom: -5px !important;
	color        : #fff;
}

.testimonial-active .slick-arrow {
	position          : absolute;
	bottom            : 0;
	left              : 40px;
	border            : none;
	background        : none;
	padding           : 0;
	color             : #fff;
	z-index           : 9;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
	width             : 50px;
	height            : 50px;
	text-align        : center;
	color             : #ffc010;
}

.testimonial-active .slick-next {
	left: 100px;
}

.testimonial-active .com-icon {
	position: absolute;
	right   : 50px;
	top     : 45px;
}

.testimonial-active .slick-dots {
	text-align: center;
	position  : relative;
	width     : 100%;
	margin-top: 50px;
}

.testimonial-active .slick-dots li {
	display: inline-block;
	margin : 0 0px;
}

.testimonial-active .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	margin-left       : 10px;
	z-index           : 1;
	cursor            : pointer;
	background        : no-repeat;
	-webkit-transition: .3s;
	transition        : .3s;
	background-color  : #282f35;
	height            : 6px;
	width             : 30px;
	border-radius     : 5px;
}

.testimonial-active .slick-dots .slick-active button {
	width           : 40px;
	border-radius   : 5px;
	background-color: rgb(250, 162, 146);
}

.testimonial-active .qt-img {
	margin-bottom: 30px;
	text-align   : right;
}

.testimonial-active .qt-img img {
	display: inline-block !important;
}

.testimonial-active .qt-img2 {
	margin-bottom: 0px;
	padding-top  : 20px;
}

.testimonial-active .qt-img2 img {
	margin-left: 30px;
	display    : inline-block !important;
}

.testimonial-active2 .qt-img {
	margin-bottom: 30px;
}

.testimonial-active2 .qt-img img {
	display: inline-block;
}

.testimonial-active2 .slider-nav {
	float: right;
	width: 100%;
}

.slider-nav .slick-list.draggable {
	width: 233px;
	float: right;
}

.testimonial-active2 .slick-arrow {
	position          : absolute;
	top               : inherit;
	left              : inherit;
	border            : none;
	background        : none;
	padding           : 0;
	font-size         : 24px;
	color             : #fff;
	z-index           : 9;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
	right             : -96px;
	bottom            : 0;
}

.testimonial-active2 .slick-next {
	left : inherit;
	right: -165px;
}

.testimonial-active2 .slick-arrow:hover {
	color: #ffc010;
}

.testimonial-avatar.p-relative {
	min-height: 455px;
	display   : block;
	overflow  : hidden;
}

.testimonial-active2 .slick-next.slick-arrow i,
.testimonial-active2 .slick-arrow i {
	border-radius   : 50%;
	background-color: rgb(80, 82, 171);
	box-shadow      : 2.5px 4.33px 15px 0px rgba(80, 82, 171, 0.4);
	width           : 60px;
	height          : 60px;
	line-height     : 60px;
}

.testimonial-active2 .ta-info span {
	color    : #ffc010;
	font-size: 14px;
}

.testimonial-active2 .ta-info h6 {
	font-size    : 20px;
	margin-bottom: 5px;
	color        : #fff;
}

.com-icon {
	position: absolute;
	right   : 0;
	top     : 20px;
}

.testimonial-item img {
	width : 80px;
	cursor: pointer;
}

.testimonial-active2 .testi-author img {
	float        : none;
	margin-bottom: 0;
	display      : inline-block;
}

.testimonial-active2 .slick-dots li {
	display: inline-block;
	margin : 0 0px;
}

.testimonial-active2 .slick-dots {
	text-align: center;
	position  : relative;
	width     : 100%;
	margin-top: 25px;
}

.testimonial-active2 .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	margin-left       : 10px;
	z-index           : 1;
	cursor            : pointer;
	background        : no-repeat;
	-webkit-transition: .3s;
	transition        : .3s;
	background-color  : #282f35;
	height            : 6px;
	width             : 30px;
	border-radius     : 5px;
}

.testimonial-active2 .ta-info {
	overflow  : hidden;
	margin-top: 20px;
}

.testimonial-active2 .single-testimonial p {
	width        : 80%;
	margin       : auto;
	margin-bottom: 30px;
	color        : #fff;
}

.testimonial-active2 .slick-dots .slick-active button {
	width           : 40px;
	border-radius   : 5px;
	background-color: rgb(250, 162, 146);
}

.testimonial-area .test-an-01 {
	position               : absolute;
	bottom                 : 0;
	animation              : alltuchtopdown 3s infinite;
	-webkit-animation      : alltuchtopdown 3s infinite;
	-webkit-animation-delay: 0s;
	animation-delay        : 0s;
	animation-delay        : 0s;
	-webkit-animation-delay: 1s;
}

.testimonial-area .test-an-02 {
	position: absolute;
	top     : 0;
	right   : 0;
}

.test-line {
	margin-top   : 30px;
	margin-bottom: 30px;
}

.test-line img {
	display: inline-block;
}

.testimonial-area .test-img {
	position: absolute;
	top     : -160px;
	left    : -75px;
}

.testimonial-area .section-title {
	padding-right: 100px;
}

/* faq */

.accordion-button {
	font-size     : 17px;
	font-weight   : 600;
	border        : none;
	outline       : none;
	cursor        : pointer;
	padding       : 20px 30px;
	width         : 100%;
	text-align    : left;
	padding-right : 75px;
	background    : #201328;
	border-radius : 0;
	color         : #fff;
	letter-spacing: 1.5px;
	position      : relative;
}

.accordion-item .collapse.show {
	display: block;
}

.accordion-button.collapsed {
	background: no-repeat;
	color     : #fff;
}

.faq-wrap .accordion-header:first-child {
	border-radius: 0;
}

.faq-wrap .accordion-header {
	padding         : 0;
	margin-bottom   : 0;
	background-color: unset;
	border-bottom   : none;
}

.faq-wrap .accordion-body {
	-webkit-box-flex: 1;
	-ms-flex        : 1 1 auto;
	flex            : unset;
	padding         : 30px;
}

.faq-wrap .accordion-header h2 {
	font-size: unset;
}

.faq-wrap .accordion-item {
	border       : none;
	border-radius: 0 !important;
	margin-bottom: 15px;
	box-shadow   : 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.09);
	background   : #201328;
}

.faq-wrap .accordion-item:last-child {
	margin-bottom: 0;
}

.faq-wrap .accordion-header button:after {
	position     : absolute;
	content      : "\f00d";
	top          : 7px;
	right        : 18px;
	font-size    : 16px;
	font-family  : "Font Awesome 5 Pro";
	font-weight  : 600;
	background   : none;
	width        : 40px;
	height       : 40px;
	border-radius: 50%;
	box-shadow   : 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
	text-align   : center;
	line-height  : 40px;
	color        : #fff;
}

.faq-wrap .accordion-header button.collapsed:after {
	content   : "\f067";
	color     : #fff;
	box-shadow: none;
}

/* 15. blog */

.home-blog-active .slick-dots {
	text-align: center;
	margin-top: 30px;
}

.home-blog-active .slick-dots li {
	display: inline-block;
	margin : 0 5px;
}

.home-blog-active .slick-dots li button {
	text-indent       : -99999px;
	border            : none;
	padding           : 0;
	margin-left       : 10px;
	border-radius     : 50px;
	z-index           : 1;
	cursor            : pointer;
	background        : no-repeat;
	-webkit-transition: .3s;
	transition        : .3s;
}

.home-blog-active .slick-dots li.slick-active button:before {
	/* background: url("../img/bg/active-dote.png"); */
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.home-blog-active .slick-dots li button:before {
	content            : "";
	width              : 19px;
	height             : 19px;
	/* background      : url("../img/bg/dote.png"); */
	float              : left;
	position           : relative;
	margin-top         : -9px;
	left               : -19px;
	border-radius      : 50%;
	opacity            : .7;
	-ms-filter         : "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	background-repeat  : no-repeat;
	background-position: center;
}

.home-blog-active .slick-arrow {
	border-radius     : 4px;
	position          : absolute;
	top               : -100px;
	-webkit-transform : translateY(-50%);
	transform         : translateY(-50%);
	left              : -84px;
	border            : none;
	background        : none;
	padding           : 0;
	color             : #f6fbff;
	z-index           : 1;
	-webkit-transition: all 0.3s ease-out 0s;
	transition        : all 0.3s ease-out 0s;
	cursor            : pointer;
	width             : 60px;
	height            : 60px;
	text-align        : center;
	color             : #fff;
	background-image  : -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image  : -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
}

.home-blog-active .slick-prev {
	right: 110px;
	left : initial !important;
}

.vedio.video-active .slick-next:before,
.vedio.video-active .slick-prev:before {
	font-family: "dripicons-v2" !important;
}

.vedio.video-active .slick-prev:before {
	content: "\6c";
}

.vedio.video-active .slick-next:before {
	content: "\6d";
}

.home-blog-active .slick-arrow.slick-next {
	right: 35px;
	left : auto;
}

.home-blog-active .slick-arrow:hover {
	background: #ffc010;
	color     : #fff;
}

.blog-thumb img {
	width: 100%;
}

.blog-thumb .b-meta {
	padding : 30px 25px 0;
	color   : #ffc010;
	position: absolute;
	top     : 0;
	left    : 0;
	z-index : 1;
}

.blog-thumb img {
	visibility: hidden;
}

.blog-thumb:hover img {
	visibility: visible;
}

.blog-content {
	background: #fff;
	padding   : 40px 30px;
	position  : relative;
	z-index   : 1;
}

.single-post .blog-content {
	position      : absolute;
	bottom        : 0;
	left          : 0;
	width         : 100%;
	background    : none;
	padding-bottom: 30px;
}

.blog-content h4 {
	font-size    : 19px;
	margin-bottom: 20px;
	line-height  : 1.3;
}

.blog-content h4:hover a {
	color: #ffc010;
}

.blog-thumb:hover:after {
	content   : "";
	position  : absolute;
	left      : 0;
	top       : 0;
	width     : 100%;
	height    : 100%;
	background: #010f2e 0% 0% no-repeat padding-box;
	opacity   : 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	display   : inline-block;
}

.blog-thumb2 {
	overflow     : hidden;
	width        : 100%;
	position     : relative;
	border-radius: 10px;
}

.blog-thumb2 .date {
	border-radius   : 4px;
	background-image: -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	position        : absolute;
	bottom          : 10%;
	left            : 5%;
	color           : #fff;
	padding         : 5px 15px;
}

.blog-thumb2 img {
	width    : auto;
	max-width: inherit;
	height   : 526px;
}

.blog-thumb2 .b-meta {
	padding: 30px 25px 0;
	color  : #ffc010;
	top    : 0;
	left   : 0;
	z-index: 1;
}

.blog-content2 {
	padding           : 30px;
	position          : absolute;
	z-index           : 1;
	bottom            : 0;
	border-radius     : 8px;
	background-color  : rgba(23, 15, 15, 0.8);
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
}

.blog-content2 .b-meta {
	margin-bottom: 15px;
	font-size    : 14px;
}

.blog-content2 h4 {
	font-size    : 24px;
	margin-bottom: 15px;
}

.blog-content2 h4 a {
	text-decoration: none;
	line-height    : 30px;
}

.blog-content2 p {
	height            : 60px;
	overflow          : hidden;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
}

.single-post2:hover .blog-content2 p {
	height: 120px;
}

.style-two.blog-area .single-post2:hover .blog-content2 {
	background-color: rgba(255, 192, 16, 0.9);
}

.style-two.blog-area .blog-content2 h4 a:hover {
	color: #fff;
}

.adim-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.adim-box .text {
	padding-top : 10px;
	padding-left: 15px;
	font-size   : 14px;
	color       : #010f2e;
}

.blog-thumb:hover .b-meta h4,
.blog-thumb:hover .b-meta h4 a:hover {
	color: #fff;
}

.b-meta ul li {
	display      : inline-block;
	margin-right : 8px;
	padding-right: 8px;
}

.b-meta ul li:last-child {
	margin-right : 0;
	padding-right: 0;
	border       : none;
}

.b-meta ul li a {
	font-size     : 14px;
	text-transform: uppercase;
	color         : #444d69;
}

.b-meta ul li a.corpo {
	color: #ffc010;
}

.b-meta ul li a:hover {
	color: #ffc010;
}

.single-post:hover .blog-btn {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.blog-btn a {
	display        : inline-block;
	color          : #ffc010;
	text-align     : center;
	font-size      : 14px;
	text-decoration: underline;
}

.blog-btn2 a {
	display   : inline-block;
	color     : #C0C0C0;
	text-align: center;
	font-size : 14px;
}

.blog-btn a i {
	margin-left: 5px;
}

.b-meta i {
	margin-right      : 5px;
	color             : #ffc010;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
}

.style-two.blog-area .single-post2:hover .b-meta i {
	color: #0e140f;
}

.single-post {
	-webkit-transition: .3s;
	transition        : .3s;
	background        : #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow        : 3px 4px 15px #00000026;
	overflow          : hidden;
	margin            : 10px 0;
}

.blog-thumb {
	position: relative;
}

.bsingle__post-thumb img {
	width: 100%;
}

.bsingle__post .video-p {
	position: relative;
}

.bsingle__post .video-p .video-i {
	height           : 80px;
	width            : 80px;
	display          : inline-block;
	position         : absolute;
	top              : 50%;
	left             : 50%;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
	text-align       : center;
	line-height      : 80px;
	border-radius    : 50%;
	background       : #fff;
	color            : #ffc010;
}

.blog-active .slick-arrow {
	position          : absolute;
	top               : 50%;
	-webkit-transform : translateY(-50%);
	transform         : translateY(-50%);
	left              : 40px;
	border            : none;
	background        : none;
	padding           : 0;
	font-size         : 30px;
	color             : #fff;
	z-index           : 9;
	opacity           : .4;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
	-webkit-transition: all 0.3s ease-out 0s;
	transition        : all 0.3s ease-out 0s;
	cursor            : pointer;
}

.blog-active .slick-arrow.slick-next {
	right: 40px;
	left : auto;
}

.blog-active .slick-arrow:hover {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.bsingle__content {
	padding   : 40px 30px;
	background: #262228;
	position  : relative;
}

.bsingle__content .admin {
	position        : absolute;
	top             : -35px;
	background      : #fff;
	padding         : 15px 25px;
	border-radius   : 10px;
	right           : 20px;
	border-radius   : 16px;
	background-color: rgb(255, 192, 16);
	box-shadow      : 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
	font-weight     : 500;
}

.bsingle__content .admin a:hover {
	color: #fff;
}

.bsingle__content .admin i {
	margin-right: 10px;
	font-size   : 18px;
}

.blog-details-wrap .meta-info ul {
	padding-top   : 25px;
	border-top    : 2px solid #eeeeee17;
	padding-bottom: 25px;
}

.blog-details-wrap .meta-info ul li {
	display      : inline-block;
	font-size    : 14px;
	margin-right : 15px;
	font-weight  : 500;
	padding-right: 15px;
	border-right : 2px solid #eeeeee17;
}

.blog-details-wrap .meta-info ul li i {
	color       : #ffc010;
	margin-right: 5px;
}

.blog-details-wrap .meta-info ul li:last-child {
	border: none;
}

.bsingle__content .meta-info ul {
	padding-top: 25px;
	border-top : 2px solid #eeeeee17;
}

.bsingle__content .meta-info ul li {
	display      : inline-block;
	font-size    : 14px;
	margin-right : 15px;
	font-weight  : 500;
	padding-right: 15px;
	border-right : 2px solid #eeeeee17;
}

.bsingle__content .meta-info ul li:last-child {
	border: none;
}

.bsingle__content .meta-info ul li i {
	color: #ffc010;
}

.bsingle__content .meta-info ul li a {
	color         : #3763eb;
	text-transform: capitalize;
}

.bsingle__content .meta-info ul li a i {
	margin-right: 5px;
}

.bsingle__content .meta-info ul li a:hover {
	color: #ffc010;
}

.bsingle__content .meta-info ul li i {
	margin-right: 5px;
}

.bsingle__content h2 {
	font-size    : 30px;
	line-height  : 1.3;
	margin-bottom: 20px;
	font-weight  : 500;
}

.bsingle__content h2:hover a {
	color: #ffc010;
}

.bsingle__content p {
	margin-bottom: 25px;
}

.bsingle__content .blog__btn .btn {
	background    : transparent;
	border        : 2px solid #ffc010;
	font-size     : 16px;
	text-transform: uppercase;
	color         : #010f2e;
}

.bsingle__content .blog__btn .btn:before {
	content: none;
}

.bsingle__content .blog__btn .btn:hover {
	background: #ffc010;
	color     : #fff;
}

/* 18. pricing */

.pricing-area .nav {
	box-shadow   : 0px 16px 32px #00000014;
	margin-top   : 50px;
	border-radius: 30px;
	padding      : 15px;
}

.pricing-area .nav-pills .nav-link {
	padding: 15px 30px;
}

.pricing-area .nav-pills .nav-link.active {
	background   : #4cc3c1;
	padding      : 15px 40px;
	border-radius: 30px;
}

.pricing-area .nav-pills .nav-link span {
	background   : #ffebe4;
	padding      : 5px 9px;
	font-size    : 12px;
	border-radius: 15px;
	color        : #ffc010;
	font-weight  : 600;
}

.pricing-box {
	float        : left;
	width        : 100%;
	position     : relative;
	background   : #fff;
	border-color : rgb(204, 204, 204);
	border-style : dashed;
	border-width : 3px;
	padding      : 30px 0;
	border-radius: 4px;
}

.pricing-btn .btn.ss-btn {
	color       : #fff;
	border-color: #4CC3C1;
}

.pricing-head {
	float: left;
	width: 100%;
}

.pricing-head h3 {
	font-size  : 24px;
	font-weight: 600;
	color      : #ffc010;
}

.price-count sub {
	font-size: 14px;
}

.pricing-head .glyph-icon:before {
	font-size    : 32px;
	margin-left  : 0;
	width        : 85px;
	background   : #3763eb;
	height       : 85px;
	display      : inline-block;
	line-height  : 65px;
	border-radius: 50%;
	color        : #fff;
	margin-bottom: 30px;
	border       : 10px solid #eee;
}

.pricing-head h4 {
	font-size     : 30px;
	font-weight   : 600;
	color         : #010f2e;
	margin-bottom : 10px;
	text-transform: uppercase;
}

.price-count h2 {
	font-size    : 60px;
	font-weight  : 600;
	margin-bottom: 0;
	color        : #010f2e;
}

.price-count h2 strong {
	font-size  : 18px;
	margin-left: -5px;
	color      : #171819;
	font-weight: 500;
}

.price-count small {
	font-size   : 18px;
	font-weight : 600;
	position    : relative;
	top         : -20px;
	margin-right: 3px;
}

.price-count span {
	font-size    : 18px;
	font-weight  : 500;
	font-family  : 'Teko', sans-serif;
	color        : #ffc010;
	margin-bottom: 15px;
	display      : inline-block;
}

.pricing-btn p {
	margin-top   : 20px;
	margin-bottom: 0;
	color        : #ffc010;
}

.pricing-body {
	padding: 0px 15px 0;
	width  : 100%;
}

.pricing-body li {
	margin-top: 10px;
}

.pricing-body li:first-child {
	margin-top: 0;
}

.pricing-body li:after {
	content     : '';
	font-family : "Font Awesome 5 Pro";
	color       : #ffc010;
	margin-right: 10px;
	float       : right;
	display     : none;
}

.pricing-body p {
	margin-bottom: 15px;
	font-size    : 14px;
}

.pricing-box.active {
	border-color: rgb(151, 192, 255);
	background  : #181e23;
	color       : #9da7b0;
	box-shadow  : 0.5px 0.866px 59px 0px rgba(0, 0, 0, 0.1);
}

.pricing-box.active .pricing-head .glyph-icon:before {
	background: #fff;
	color     : #3763eb;
}

.pricing-box.active .pricing-head h4 {
	color: #fff;
}

.pricing-body {
	float: left;
}

.pricing-box2 .icon {
	margin-top: 30px;
}

.pricing-box2 hr {
	background: #97c0ff;
	border    : none;
	margin    : auto;
	margin-top: auto;
	padding   : 0;
	display   : inline-block;
	width     : 80%;
	height    : 1px;
	margin-top: 25px;
}

.pricing-box2.active {
	background: #fff;
	color     : #777;
}

.pricing-box2 .pricing-head p {
	margin-bottom: 0px;
}

.pricing-tab {
	text-align    : center;
	text-transform: uppercase;
	font-size     : 18px;
}

.pricing-tab span {
	vertical-align: middle;
}

.pricing-tab .pricing-tab-switcher {
	height            : 30px;
	width             : 60px;
	background-image  : -webkit-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	background-image  : -ms-linear-gradient(0deg, rgb(94, 23, 223) 0%, rgb(29, 117, 250) 100%);
	box-shadow        : inset 1px 1.732px 3px 0px rgba(0, 0, 0, 0.15);
	display           : inline-block;
	border-radius     : 50px;
	position          : relative;
	-webkit-transition: all 0.3s ease;
	transition        : all 0.3s ease;
	margin            : 0 18px;
	-webkit-transform : translateY(-3px);
	transform         : translateY(-3px);
	cursor            : pointer;
}

.pricing-tab .pricing-tab-switcher:before {
	content           : '';
	position          : absolute;
	left              : 5px;
	top               : 3px;
	background        : #fff;
	width             : 24px;
	height            : 24px;
	border-radius     : 100%;
	-webkit-transition: all 0.3s ease;
	transition        : all 0.3s ease;
}

.pricing-tab .pricing-tab-switcher.active:before {
	left: calc(100% - 30px);
}

.pricing-amount {
	position: relative;
	color   : #ff3494;
}

.pricing-amount .annual_price {
	display: none;
}

.pricing-amount .monthly_price {
	display: block;
	float  : left;
	width  : 100%;
}

.pricing-amount.change-subs-duration .monthly_price {
	display: none;
}

.pricing-amount.change-subs-duration .annual_price {
	display: block;
	float  : left;
	width  : 100%;
}

.pricing-body ul {
	margin       : auto;
	padding-left : 18%;
	padding-right: 18%;
}

.pricing-body li:before {
	content     : "";
	font-family : "Font Awesome 5 Pro";
	margin-right: 10px;
	color       : #4926e1;
}

/* 19. contact */

.contact-map {
	min-height: 1140px;
	margin-top: -208px;
}

.contact-area {
	background-repeat  : no-repeat;
	background-position: center center;
}

.contact-area .map {
	position: absolute;
	right   : 0;
	top     : 0;
}

.contact-wrapper textarea {
	border            : 0;
	color             : #000;
	font-size         : 15px;
	height            : 200px;
	width             : 100%;
	text-transform    : capitalize;
	-webkit-transition: .3s;
	transition        : .3s;
	background        : #f4f4fe;
	padding           : 30px 40px;
}

.contact-wrapper textarea::-moz-placeholder {
	color    : #b3bdcd;
	font-size: 14px;
}

.contact-wrapper textarea::-webkit-input-placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.contact-wrapper textarea:-ms-input-placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.contact-wrapper textarea::placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.c-icon i {
	position         : absolute;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	transform        : translateY(-50%);
	right            : 40px;
	color            : #8990b0;
	font-size        : 14px;
}

.contact-message.c-icon i {
	top              : 30px;
	-webkit-transform: unset;
	transform        : unset;
}

.contact-wrapper input {
	border            : 0;
	color             : #000;
	font-size         : 15px;
	height            : 80px;
	text-transform    : capitalize;
	width             : 100%;
	padding           : 0 40px;
	-webkit-transition: .3s;
	transition        : .3s;
	background        : #f4f4fe;
}

.contact-wrapper input::-moz-placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.contact-wrapper input::-webkit-input-placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.contact-wrapper input:-ms-input-placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.contact-wrapper input::placeholder {
	color    : #8990b0;
	font-size: 14px;
}

.contact-name {
	position: relative;
}

.contact-field label {
	display       : block;
	font-size     : 16px;
	font-weight   : 500;
	text-transform: uppercase;
	color         : #010f2e;
	margin-bottom : 20px;
	cursor        : unset;
}

.contact-field {
	padding-right: 0;
}

.contact-field input {
	width             : 100%;
	border            : none;
	background        : #262228;
	padding           : 15px 15px;
	-webkit-transition: .3s;
	transition        : .3s;
	border-radius     : 0;
	border            : 2px solid #eeeeee17;
	color             : #fff;
}

.contact-field.c-name:after {
	content: "\f007";
}

.contact-field:after {
	position   : absolute;
	font-family: "Font Awesome 5 Pro";
	display    : inline-block;
	font-size  : 14px;
	text-align : center;
	right      : 25px;
	color      : #ffc010;
	bottom     : 22px;
	display    : none;
}

.contact-field.c-email:after {
	content: "\f0e0";
}

.contact-field.c-subject:after {
	content: "\f249";
}

.contact-field.c-message:after {
	content    : "\f303";
	font-weight: 600;
	top        : 20px;
	bottom     : unset;
}

.contact-field input::-webkit-input-placeholder,
.contact-field textarea::-webkit-input-placeholder {
	color: #9e9e9e;
}

.contact-field input::-moz-placeholder,
.contact-field textarea::-moz-placeholder {
	color: #9e9e9e;
}

.contact-field input:-ms-input-placeholder,
.contact-field textarea:-ms-input-placeholder {
	color: #9e9e9e;
}

.contact-field input::placeholder,
.contact-field textarea::placeholder {
	color: #9e9e9e;
}

.contact-field textarea {
	height            : 120px;
	width             : 100%;
	border            : none;
	background        : #262228;
	padding           : 15px 15px;
	-webkit-transition: .3s;
	transition        : .3s;
	border-radius     : 0;
	border            : 2px solid #eeeeee17;
	color             : #fff;
}

.contact-bg {
	background-size    : cover;
	background-position: center;
	z-index            : 1;
}

.contact-img {
	position: absolute;
	bottom  : 0;
	right   : 170px;
	z-index : -1;
}

.contact-area .section-title h2 {
	margin-bottom : 30px !important;
	padding-bottom: 20px;
}

.contact-area .section-title h2:after {
	content   : "";
	position  : absolute;
	left      : 0;
	bottom    : 0;
	height    : 4px;
	width     : 34px;
	background: #ffc010;
}


/* 20. footer */

.footer-top-heiding {
	border-bottom: 2px solid #20282E;
	margin-bottom: 50px;
}

.f-contact {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.f-contact a {
	color: #90949c !important;
}

.f-contact a:hover {
	color: #ffc010 !important;
}

.footer-top-heiding .col-lg-4 {
	border-right: 2px solid #20282E;
}

.footer-top-heiding .col-lg-4:last-child {
	border-right: none;
}

.f-contact .icon {
	margin-right: 20px;
}

.f-contact span {
	color      : #90949c;
	display    : block;
	font-size  : 14px;
	font-weight: 600;
}

.f-contact h3 {
	color    : #fff;
	font-size: 24px;
}

.footer-top-heiding h2 {
	color: #fff;
}

.footer-bg {
	color              : #90949c;
	border-bottom      : 4px solid #170f0f;
	background         : #1e191f;
	background-position: center 0 !important;
}

.f-cta-area.gray-bg {
	background: #f4f4fe;
	border-top: 3px solid #ffc010;
}

.footer-text p {
	color        : #ddd;
	margin-bottom: 0;
}

.footer-social span {
	font-size   : 14px;
	color       : #fff;
	font-weight : 500;
	font-family : 'Playfair Display', serif;
	display     : inline-block;
	margin-right: 20px;
}

.footer-social a {
	font-size       : 14px;
	display         : inline-block;
	margin-right    : 10px;
	width           : 36px;
	height          : 36px;
	border-radius   : 10px;
	background-color: rgb(255, 192, 16);
	text-align      : center;
	line-height     : 36px;
	color           : rgb(255, 255, 255) !important;
}

.footer-social a:hover {
	background: rgb(255, 138, 1);
	color     : #fff !important;
}

.f-widget-title h2 {
	font-size     : 24px;
	margin-bottom : 35px;
	position      : relative;
	padding-bottom: 18px;
	color         : #fff;
}

.f-widget-title h2:before {
	content   : "";
	position  : absolute;
	left      : 0;
	bottom    : 0;
	height    : 4px;
	width     : 34px;
	background: #ffc010;
}

.footer-link ul li i {
	color       : #ffc010;
	margin-right: 5px;
}

.footer-link ul li a {
	color      : #90949c;
	font-weight: 600;
	display    : inline-block;
}

.footer-link ul li a:hover {
	padding-left: 5px;
	color       : #ffc010;
}

.footer-link ul li {
	margin-bottom: 15px;
}

.footer-link ul li:last-child {
	margin-bottom: 0;
}

.f-insta ul li {
	display      : inline-block;
	margin-bottom: 15px;
	margin-right : 9px;
	width        : 30.5% !important;
}

.f-insta ul li:nth-child(3),
.f-insta ul li:nth-child(6) {
	margin-right: 0 !important;
}

.f-insta ul li a {
	display : block;
	position: relative;
}

.f-insta ul li a:before {
	content           : "\e035";
	font-family       : "dripicons-v2" !important;
	position          : absolute;
	top               : 40%;
	left              : 50%;
	-webkit-transform : translate(-50%, -50%);
	transform         : translate(-50%, -50%);
	font-size         : 24px;
	color             : #ffc010;
	-webkit-transition: .3s;
	transition        : .3s;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	z-index           : 9;
}

.f-insta ul li a:after {
	content           : "";
	position          : absolute;
	left              : 0;
	top               : 0;
	height            : 100%;
	width             : 100%;
	background        : #ffc010;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: .3s;
	transition        : .3s;
}

.f-insta ul li:hover a:before {
	top       : 50%;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.f-insta ul li:hover a:after {
	opacity   : .6;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
}

.copyright-wrap {
	color        : #fff;
	font-size    : 16px;
	font-weight  : 600;
	margin-bottom: 30px;
	font-family  : 'Teko', sans-serif;
}

.copyright-wrap span {
	color: #ffc010;
}

.copyright-wrap li {
	display     : inline;
	padding-left: 15px;
	margin-left : 10px;
	position    : relative;
}

.copyright-wrap li a {
	color: #fff;
}

.copyright-wrap li:before {
	background-color: rgb(29, 117, 250);
	width           : 8px;
	height          : 8px;
	content         : "";
	position        : absolute;
	left            : -4px;
	top             : 5px;
	border-radius   : 8px;
}

/* Footer Map */
.map {
	height: 130px;
}

.leaflet-bottom.leaflet-right {
	display: none;
}

.f-contact i {
	background   : #3763eb;
	line-height  : 28px;
	color        : #90949c !important;
	background   : none;
	text-align   : center;
	border-radius: 10px;
	float        : left;
}

.f-contact li {
	float        : left;
	margin-bottom: 5px !important;
	width        : 100%;
}

.f-contact li:last-child {
	margin-bottom: 0px !important;
}

#contact-form4 {
	margin-top: 20px;
}

#contact-form4 input {
	float        : left;
	border-radius: 10px 0 0px 10px;
	padding      : 23px 30px;
	border       : none;
	width        : 75%;
}

#contact-form4 button {
	padding      : 15px 30px;
	border-radius: 0px 10px 10px 0;
}

/* Fillter css */

.portfolio .grid {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-ms-flex-wrap    : wrap;
	flex-wrap        : wrap;
}

.portfolio .col2 .grid-item {
	width  : 49%;
	padding: 15px;
}

.portfolio .col3 .grid-item {
	width  : 33.33%;
	margin : 0;
	padding: 15px;
}

.portfolio .col3 .grid-item .box20 img {
	width        : auto;
	height       : 380px;
	border-radius: 0px;
	max-width    : inherit;
	margin-left  : -150px;
}

.portfolio .col4 .grid-item {
	width  : 24%;
	margin : 0 5px 10px;
	padding: 0;
}

.portfolio .col4 .grid-item .box-hover {
	position          : absolute;
	width             : 100%;
	left              : 0;
	height            : 100%;
	background        : #ffc010b3;
	top               : 0;
	text-align        : center;
	padding-top       : 45%;
	font-size         : 36px;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	color             : #fff;
}

.portfolio .col4 .grid-item:hover .box-hover {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.gallery-image figcaption {
	background        : black;
	width             : 100%;
	padding           : 35px 0 35px 30px;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	color             : #ffffffc7;
	font-size         : 10px;
	text-transform    : uppercase;
	letter-spacing    : 1.8px;
	font-weight       : 500;
	position          : relative;
}

.gallery-image:hover figcaption {
	background: #ffc010;
}

.gallery-image figcaption h4 {
	font-weight: 600;
	font-size  : 15px;
}

.gallery-image figcaption span {
	color            : #0e140f;
	font-size        : 14px;
	padding          : 0 15px;
	display          : inline-block;
	margin-bottom    : 15px;
	position         : absolute;
	top              : -26px;
	right            : 0;
	width            : 87px;
	height           : 27px;
	text-align       : center;
	font-weight      : 600;
	background       : #ffc010;
	text-transform   : uppercase;
	-webkit-clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 100%, 10px 100%);
	clip-path        : polygon(0 0, calc(100% - 10px) 0, 100% 100%, 10px 100%);
}

.gallery-image figcaption p {
	margin-bottom: 0;
}

.gallery-image figcaption .icon {
	position   : absolute;
	right      : 0;
	background : #3763eb;
	width      : 45px;
	height     : 45px;
	line-height: 45px;
	top        : 0;
	color      : #fff;
	text-align : center;
	font-size  : 40px;
}
.gallery-image figcaption .custom-icons {
	position: absolute;
	bottom: 10px;
	left: 10px;
	display: flex;
	gap: 10px;
  }
  
  .custom-icons img {
	width: 30px;
	height: 30px;
	background-color: white;
	border-radius: 50%;
	padding: 5px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
.button-group {
	padding-bottom: 15px;
}

.button-group button {
	border            : none;
	background        : none;
	-webkit-transition: .3s;
	transition        : .3s;
	padding-bottom    : 15px;
	cursor            : pointer;
	outline           : none;
	color             : #fff;
	font-weight       : 600;
	text-transform    : uppercase;
	position          : relative;
	padding-top       : 15px;
	padding-left      : 25px;
	padding-right     : 25px;
	border-radius     : 10px;
	letter-spacing    : 1.5px;
}

.button-group button:hover {
	color: #ffc010;
}

.button-group button.active {
	color: #ffc010;
}

.gallery-image img {
	box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

/* 21. Menu Theme dropdown default css */

.f-cta-icon {
	float       : left;
	margin-right: 30px;
}

.single-cta {
	line-height: 28px;
}

.single-cta a {
	color: #010f2e;
}

.main-menu ul li.has-sub .current a {
	color: #222;
}

.main-menu ul li.current a {
	color: #ffc010;
}

.main-menu .has-sub>ul {
	display           : none;
	position          : absolute;
	background-color  : #ffff;
	min-width         : 250px;
	z-index           : 1;
	-webkit-transition: all 0.3s ease-in-out;
	transition        : all 0.3s ease-in-out;
	margin-top        : 15px;
	border-top        : 4px solid #ffc010;
	box-shadow        : 0 10px 15px rgba(25, 25, 25, 0.1);
	-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.main-menu .has-sub li a {
	padding: 15px;
}

.main-menu .has-sub li {
	margin-left  : 0 !important;
	float        : left;
	border-bottom: 1px solid #ddd;
	width        : 100%;
	text-align   : left;
}

.main-menu .has-sub a:hover {
	color: #ffc010;
}

.main-menu .has-sub:hover>ul {
	display   : block;
	margin-top: 0px;
}

.main-menu .has-sub>ul>.has-sub>ul {
	display         : none;
	position        : absolute;
	background-color: #ffff;
	width           : 250px;
	z-index         : 1;
	padding         : 0;
	margin-left     : -255px;
	margin-top      : 0;
	top             : -3px;
}

.main-menu .has-sub>ul>.has-sub:hover>ul {
	display: block;
}

.main-menu .has-sub>ul>.has-sub>ul>.has-sub>ul {
	display         : none;
	position        : absolute;
	background-color: #ffff;
	width           : 250px;
	z-index         : 1;
	padding         : 0;
	margin-left     : 255px;
	margin-top      : 0;
	top             : -3px;
}

.main-menu .has-sub>ul>.has-sub>ul>.has-sub:hover>ul {
	display: block;
}

.main-menu .has-sub ul li a {
	color: #4f4f4f;
}

.main-menu .has-sub ul li a:hover {
	color: #ffc010;
}

/* 23. mega menu */

.offcanvas-menu .has-mega-menu ul {
	display: none;
}

.offcanvas-menu li.has-sub.has-mega-menu:hover>ul {
	margin-left: -1160px;
	background : #00081b;
	top        : 121px;
}

.offcanvas-menu .mega-menu-column.has-sub ul li a {
	color    : #fff;
	font-size: 14px;
}

.offcanvas-menu .mega-menu-column.has-sub ul li a:hover {
	color: #ffc010;
}

.has-mega-menu {
	position: static;
}

li.has-sub.has-mega-menu ul {
	position: absolute;
	top     : NNpx;
	/*insert the needed value*/
	z-index : 100;
	left    : 0px;
	right   : 0px;
	width   : 1180px;
}

.main-menu .mega-menu-column.has-sub a {
	color: #333;
}

.main-menu .mega-menu-column.has-sub a:hover {
	color: #ffc010;
}

li.has-sub.has-mega-menu:hover>ul {
	/*flexbox fallback for browsers that do not support CSS GRID lyout*/
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-ms-flex-wrap        : wrap;
	flex-wrap            : wrap;
	/*CSS GRID lyout*/
	display              : -ms-grid;
	display              : grid;
	-ms-grid-columns     : (minmax(25%, 1fr))[auto-fit];
	grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
	margin-left          : -820px;
}

li.has-sub.has-mega-menu:hover>ul>li>ul {
	/*flexbox fallback for browsers that do not support CSS GRID lyout*/
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
	-ms-flex-wrap: wrap;
	flex-wrap    : wrap;
	width        : auto;
	background   : none;
	/*CSS GRID lyout*/
	display      : -ms-grid;
	display      : grid;
	position     : relative;
	border       : none;
	margin-left  : 0;
}

@media screen and (min-width: 992px) {
	li.has-mega-menu:hover>ul {
		-ms-grid-columns     : (minmax(33.3333%, 1fr))[auto-fit];
		grid-template-columns: repeat(auto-fit, minmax(33.3333%, 1fr));
	}
}

@media screen and (min-width: 1200px) {
	li.has-mega-menu:hover>ul {
		-ms-grid-columns     : (minmax(25%, 1fr))[auto-fit];
		grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
	}
}

@media screen and (min-width: 600px) {
	li.mega-menu-column {
		width           : 100%;
		max-width       : 100%;
		min-height      : 1px;
		padding         : 10px 25px;
		-webkit-box-flex: 1;
		-ms-flex        : 1 0 calc(50%);
		flex            : 1 0 calc(50%);
	}
}

@media screen and (min-width: 992px) {
	li.mega-menu-column {
		-webkit-box-flex: 1;
		-ms-flex        : 1 0 calc(33.333%);
		flex            : 1 0 calc(33.333%);
	}
}

.main-menu .has-sub .mega-menu-column li {
	-webkit-box-flex: 1;
	-ms-flex        : 1 0 calc(25%);
	flex            : 1 0 calc(25%);
	border          : none;
}

/* 22. Theme sidebar widge css */

.sidebar-widget a {
	color: #ffffffc7;
}

.tag-cloud-link {
	font-size     : 14px !important;
	border        : 2px solid #eeeeee17;
	padding       : 10px 19px !important;
	display       : inline-block;
	margin        : 5px 1px;
	text-transform: capitalize;
	float         : none !important;
	width         : auto !important;
}

.tag-cloud-link:hover {
	border: 2px solid #ffc010;
	color : #6a6a6a;
}

.widget_text img {
	width : 100%;
	height: auto;
}

.sidebar-widget select {
	width  : 100%;
	padding: 10px;
	border : 2px solid #eeeeee17;
}

.recentcomments a {
	color: #010f2e;
}

.sidebar-widget a.rsswidget {
	color: #010f2e;
}

#wp-calendar caption {
	color      : #010f2e;
	font-weight: 500;
	font-size  : 14px;
}

.widget_media_image a:hover {
	padding-left: 0 !important;
}

.sidebar-widget .widget li .children {
	border-top    : 1px solid #f6f6f6;
	margin-top    : 10px;
	padding-bottom: 0px;
	display       : inline-block;
	width         : 100%;
}

.sidebar-widget .widget li.page_item_has_children {
	padding-bottom: 0;
}

.sidebar-widget .widget .children {
	padding-left: 15px;
}

.sidebar-widget .widget .children ul.children {
	padding-left: 30px;
}

.sidebar-widget .page_item_has_children li {
	padding-top: 10px;
	display    : block;
}

.sidebar-widget .widget .children li {
	padding-top: 10px;
}

.sidebar-widget .widget .children .children li {
	padding-top: 10px;
}

.sidebar-widget .widget li:last-child,
.sidebar-widget .widget .children li:last-child,
.sidebar-widget .widget .children .children li:last-child {
	border-bottom : none;
	padding-bottom: 0;
	margin-bottom : 0;
}

.sticky .bsingle__content,
.tag-sticky-2 {
	background: #f6f6f6;
}

.sticky .bsingle__content ul li {
	margin-bottom: 15px;
	list-style   : disc;
	margin-left  : 10px;
}

#attachment_907 {
	width: 100% !important;
}

.wp-image-907 {
	width : 100% !important;
	height: auto;
}

.sidebar-widget .widget a {
	width     : 85%;
	text-align: left;
}

.sidebar-widget .widget a:hover {
	color       : #ffc010;
	padding-left: 10px;
}

.sidebar-widget .widget .widget_archive li:hover,
.widget_categories li:hover,
.sidebar-widget .widget_archive li:hover {
	color: #ffc010;
}

.post-password-form input[type="password"] {
	background: #f6f6f6;
	border    : none;
	width     : 300px;
	padding   : 10px 30px;
}

.post-password-form input[type="submit"] {
	border    : none;
	background: #010f2e;
	padding   : 10px 30px;
	color     : #ffffff;
	font-size : 14px;
	cursor    : pointer;
}

.post-password-form input[type="submit"]:hover {
	background: #ffc010;
}

.wp-block-cover__video-background {
	width: 100%;
}

.sidebar-widget .widget_nav_menu .sub-menu {
	padding-left: 15px;
}

.page #comments {
	float     : left;
	width     : 100%;
	margin-top: 50px;
}

video {
	width: 100%;
}

.sidebar-widget .widget_text a:hover {
	padding-left: 0;
}

.dsnone {
	display: none;
}

.wp-block-media-text__media img {
	width: 100%;
}

.wp-block-image .alignleft {
	float       : left;
	margin-right: 1em;
}

.wp-block-image figure.alignright {
	margin-left: 1.5em;
}

.wp-block-image .alignright {
	float      : right;
	margin-left: 1em;
}

.wp-block-image img {
	max-width: 100%;
}

.wp-block-galler {
	overflow: hidden;
	display : inline-block;
	position: relative;
	width   : 100%;
}

.blocks-gallery-item {
	margin  : 0;
	position: relative;
}

.blocks-gallery-item img,
.blocks-gallery-item img:hover {
	background    : 0 0;
	border        : none;
	box-shadow    : none;
	max-width     : 100%;
	padding       : 0;
	vertical-align: middle;
}

.blocks-gallery-item figcaption {
	background   : #eee;
	background   : rgba(255, 255, 255, .8);
	color        : #333;
	font-size    : 13px;
	font-weight  : 400;
	overflow     : hidden;
	padding      : 10px 0;
	position     : absolute;
	bottom       : 0;
	text-indent  : 10px;
	text-overflow: ellipsis;
	width        : 100%;
	white-space  : nowrap;
	opacity      : 0;
	-ms-filter   : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.blocks-gallery-item:hover figcaption {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.wp-block-gallery.columns-3 li {
	width  : 33.333%;
	padding: 10px;
	height : 200px;
}

.wp-block-gallery.columns-2 li {
	width  : 50%;
	padding: 10px;
}

.wp-block-gallery {
	width: 100%;
	float: left;
}

.wp-block-gallery.columns-5 li {
	width          : auto;
	height         : auto;
	overflow       : hidden;
	-webkit-columns: auto;
	-moz-columns   : auto;
	columns        : auto;
}

.bsingle__content .alignright {
	float      : right;
	margin-left: 1.5em;
}

.bsingle__content .alignleft {
	float       : left;
	margin-right: 1em;
}

.alignright {
	float      : right;
	margin-left: 1.5em;
}

.alignleft {
	float       : left;
	margin-right: 1em;
}

figure.aligncenter {
	width     : 100% !important;
	text-align: center;
	float     : left;
}

.aligncenter {
	margin : auto;
	display: inherit;
}

.page-links {
	float     : left;
	width     : 100%;
	margin-top: 50px;
}

#comments {
	float: left;
	width: 100%;
}

.bsingle__content ul,
.pages-content ul {
	padding-left: 0px;
}

.comment-text ul {
	padding-left: 20px;
}

.bsingle__content ul li,
.comment-text ul li,
.pages-content ul li {
	list-style: disc;
}

.bsingle__content table th,
.bsingle__content table td,
.comment-text table th,
.comment-text table td,
.pages-content table th,
.pages-content table td {
	border : 1px solid #f6f6f6;
	padding: 10px;
}

.bsingle__content table,
.comment-text table,
.pages-content table {
	margin-bottom: 20px;
}

.wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile {
	padding: 30px;
}

.bsingle__content ol {
	padding: 0 0 0 1.5em;
}

.bsingle__content ol li,
.comment-text ol li,
.pages-content ol li {
	list-style: decimal;
}

.bsingle__content figure {
	margin: 0 0 16px;
	margin: 0 0 1rem;
}


.has-text-color.has-background.has-very-light-gray-color {
	color  : #fff;
	padding: 21px;
}

.wp-block-cover-text {
	color  : #fff;
	padding: 30px;
}

.tag-template img {
	width: auto !important;
}

.error-page .error-code {
	display      : inline-block;
	font-size    : 250px;
	color        : #fff;
	margin-bottom: 20px;
	text-shadow  : 5px 5px 1px rgba(0, 0, 0, .1);
	margin-bottom: 150px;
}

.error-body a {
	margin-top   : 30px;
	margin-bottom: 100px;
	background   : #ffc010;
	border-radius: 10px;
}

.error-img {
	margin-top: -130px;
}

.tag-markup-2 li>ul,
li>ol,
.pages-content li>ul,
li>ol {
	padding-left: 1.5em;
}

.tag-markup-2 ul li,
.tag-markup-2 ol li,
.comment-text ul li,
.comment-text ol li,
.pages-content ul li,
.pages-content ol li {
	margin-top   : 10px;
	margin-bottom: 0;
}

.parent-pageid-1725 figure.aligncenter {
	margin: 50px 0;
}

.bsingle__content ul ul li,
.pages-content ul ul li {
	list-style: circle;
}

footer #wp-calendar caption {
	color: #fff;
}

ol {
	padding-left: 15px;
}

iframe {
	width: 100%;
}

.comment-list li {
	margin-bottom: 30px;
}

.wp-block-button__link {
	border       : none;
	font-weight  : 600;
	padding      : 12.16px 16px;
	padding      : .76rem 1rem;
	outline      : none;
	outline      : none;
	display      : inline-block;
	background   : #010f2e;
	color        : #fff !important;
	width        : auto;
	border-radius: 5px;
	text-align   : center;
}

.wp-block-button.aligncenter .wp-block-button__link {
	width  : 50%;
	display: block;
	margin : auto;
}

.wp-block-button.is-style-outline .wp-block-button__link {
	background: none;
	border    : 2px solid #010f2e;
	color     : #010f2e !important;
}

.wp-block-button.is-style-squared .wp-block-button__link {
	border-radius: 0;
}

.has-2-columns,
.has-3-columns,
.has-5-columns,
.has-6-columns {
	float: left;
	width: 100%;
}

.has-2-columns .wp-block-column {
	float        : left;
	width        : 50%;
	padding-right: 20px;
}

.has-3-columns .wp-block-column {
	float        : left;
	width        : 33.333%;
	padding-right: 20px;
}

.has-5-columns .wp-block-column {
	float        : left;
	width        : 20%;
	padding-right: 20px;
}

.has-4-columns .wp-block-column {
	float        : left;
	width        : 25%;
	padding-right: 20px;
}

.has-6-columns .wp-block-column {
	float        : left;
	width        : 16.66666666666667%;
	padding-right: 20px;
}

.wp-block-media-text.alignwide figure {
	float       : left;
	width       : 50%;
	margin-right: 20px;
}

.wp-block-media-text.alignwide .has-large-font-size {
	font-size: 28px;
	color    : #010f2e;
}

.wp-block-media-text__content {
	float      : left;
	width      : 47%;
	padding-top: 0;
}

.wp-block-columns.alignwide.has-3-columns blockquote {
	margin-top  : 0;
	padding-left: 20px;
}

.columns-3.is-cropped li {
	list-style: none;
	float     : left;
	width     : 33.333%;
}

.wp-block-gallery.alignleft.columns-2.is-cropped li {
	float     : left;
	list-style: none;
}

.wp-block-gallery.alignwide.columns-4.is-cropped li {
	float     : left;
	list-style: none;
	width     : 25%;
	padding   : 10px;
}

.wp-block-gallery.columns-6.is-cropped li {
	float     : left;
	width     : 16.666%;
	height    : 129px;
	list-style: none;
	padding   : 10px;
}

.wp-block-gallery.columns-7.is-cropped li {
	float     : left;
	width     : 14%;
	padding   : 10px;
	list-style: none;
	min-height: 150px;
}

.wp-block-gallery.columns-8.is-cropped li {
	float     : left;
	width     : 12%;
	padding   : 10px;
	list-style: none;
	min-height: 100px;
}

.wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile {
	float: left;
}

.wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile figure {
	float       : left;
	width       : 50%;
	margin-right: 20px;
}

.footer-widget .widgettitle,
.footer-widget .cat-item,
.footer-widget .widget ul li {
	text-align: left;
	color     : #fff;
}

.footer-widget select {
	width  : 100%;
	padding: 10px;
	border : 2px solid #eeeeee17;
}

.blog-deatails-box.single .single {
	display: none;
}

.footer-widget .tag-cloud-link,
.footer-widget p {
	color: #fff;
}

.redux-footer h2 {
	margin-top: 0px;
}

.footer-widget td {
	padding   : 5px;
	text-align: center;
}

.footer-widget table {
	width     : 100%;
	padding   : 10px;
	background: #f5f5f5;
}

.footer-widget th {
	background: #f6f6f6;
	padding   : 10px 5px;
	text-align: center;
	color     : #010f2e;
}

.bsingle__content h1,
.bsingle__content h2,
.bsingle__content h3,
.bsingle__content h4,
.bsingle__content h5,
.bsingle__content h6 {
	line-height  : 1.3;
	margin-bottom: 20px;
	font-weight  : 600;
}

.pages-content h1,
.pages-content h2,
.pages-content h3,
.pages-content h4,
.pages-content h5,
.pages-content h6 {
	line-height  : 1.3;
	margin-bottom: 20px;
	font-weight  : 600;
}

.comment-list h1,
.comment-list h2,
.comment-list h3,
.comment-list h4,
.comment-list h5,
.comment-list h6 {
	line-height  : 1.3;
	margin-bottom: 20px;
	font-weight  : 600;
}

.inner-linke-page {
	font-size  : 16px;
	font-weight: 600;
	color      : #010f2e;
}

.inner-linke-page a {
	border       : none;
	height       : 32px;
	width        : 32px;
	display      : inline-block;
	line-height  : 32px;
	background   : #ffc010;
	border-radius: 50%;
	color        : #fff;
	font-weight  : 400;
	font-size    : 14px;
	text-align   : center;
}

.inner-linke-page a:hover {
	color     : #ffffff;
	background: #010f2e;
}

.inner-linke-page>span {
	border       : none;
	height       : 32px;
	width        : 32px;
	display      : inline-block;
	line-height  : 32px;
	font-weight  : 400;
	border-radius: 50%;
	font-size    : 14px;
	text-align   : center;
	background   : #010f2e;
	color        : #ffffff;
	box-shadow   : 0px 8px 16px 0px rgba(0, 0, 0, 0.32);
}

pre {
	padding   : 30px;
	background: #f6f6f6;
}

pre cite {
	color: #000;
}

.inner-linke-page {
	margin-bottom: 50px;
}

.attachment-aports-featured-large {
	width : 100%;
	height: auto;
}

.quote-post {
	min-height: 200px;
}

/* 0. Woocommcrece Theme default css */

.woocommerce ul.products li.product .price ins {
	background : 0 0;
	font-weight: 400;
	display    : inline-block;
}

.short-ul {
	margin-bottom: 50px;
}

.woocommerce .cst-woocommerce .cart .button,
.woocommerce .cart input.button,
.woocommerce .cst-woocommerce #respond input#submit.alt,
.woocommerce .cst-woocommerce a.button.alt,
.woocommerce .cst-woocommerce button.button.alt,
.woocommerce .cst-woocommerce input.button.alt,
.woocommerce .cst-woocommerce #respond input#submit,
.woocommerce .cst-woocommerce a.button,
.woocommerce .cst-woocommerce button.button,
.woocommerce .cst-woocommerce input.button,
.woocommerce .cart .button,
.woocommerce .cart input.button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
	text-transform    : uppercase;
	-moz-user-select  : none;
	background        : #ffc010;
	border            : none;
	border-radius     : 50px;
	color             : #fff;
	display           : inline-block;
	font-size         : 16px;
	font-weight       : 500;
	letter-spacing    : 0;
	line-height       : 1;
	margin-bottom     : 0;
	padding           : 15px 30px;
	text-align        : center;
	text-transform    : unset;
	-ms-touch-action  : manipulation;
	touch-action      : manipulation;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	vertical-align    : middle;
	white-space       : nowrap;
	position          : relative;
}

.woocommerce-cart .page,
.woocommerce-account .page,
.woocommerce-checkout .page {
	width: 100%;
}

.cst-woocommerce .columns-3 .button.product_type_simple.add_to_cart_button.ajax_add_to_cart {
	left: 30%;
}

.cst-woocommerce .columns-3 .button.product_type_variable.add_to_cart_button {
	left: 28%;
}

.woocommerce div.product div.images .flex-control-thumbs {
	margin-top: 30px;
}

.woocommerce div.product div.images .flex-control-thumbs img {
	width: auto;
}

.woocommerce .summary .price {
	font-size  : 18px !important;
	font-weight: 500;
}

.woocommerce-review-link .count,
.woocommerce-review-link {
	font-size: 14px;
	color    : #ababab;
}

.woocommerce .star-rating {
	color: #f1d700;
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
	float   : left;
	width   : 48%;
	overflow: hidden;
}

.woocommerce .button.product_type_simple.add_to_cart_button.ajax_add_to_cart,
.woocommerce .button.product_type_variable.add_to_cart_button,
.woocommerce .button.product_type_grouped {
	top       : 37%;
	opacity   : 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	position  : absolute !important;
	left      : 21%;
}

.woocommerce .products li:hover .button.product_type_simple.add_to_cart_button.ajax_add_to_cart,
.woocommerce .products li:hover .button.product_type_variable.add_to_cart_button,
.woocommerce .products li:hover .button.product_type_grouped {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	margin-top: 0px;
}

.woocommerce ul.products li.product .onsale {
	top             : 0;
	right           : 0;
	left            : auto;
	margin          : 0.5em 0.5em 0 0;
	min-height      : 2.236em;
	min-width       : 5.236em;
	background-color: #f00;
	border-radius   : 0;
	line-height     : 2.236;
	font-weight     : 500;
	text-transform  : uppercase;
}

.woocommerce span.onsale {
	margin          : 15px;
	min-height      : 2.236em;
	min-width       : 5.236em;
	background-color: #f00;
	border-radius   : 0;
	line-height     : 2.236;
	font-weight     : 500;
	text-transform  : uppercase;
}

.posted_in a {
	color      : #444d69;
	font-weight: 400;
}

.woocommerce div.product form.cart {
	margin: 2em 0;
}

.woocommerce .quantity .qty {
	width        : 90px;
	line-height  : 30px;
	border       : 1px solid #ccc;
	padding      : 3px 8px;
	margin-top   : 3px;
	margin-bottom: 7px;
	margin-right : 7px;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
	font-size: 18px;
}

.woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
	vertical-align: middle;
}

.woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label label a {
	margin-top: 10px;
	color     : #010f2e;
	display   : inline-block;
}

.woocommerce ul li {
	list-style: none !important;
}

.woocommerce div.product form.cart .group_table td {
	border        : 0;
	vertical-align: middle;
}

.woocommerce .orderby {
	width      : 200px;
	line-height: 44px;
	border     : 1px solid #ccc;
	padding    : 8px 10px;
	margin-top : -14px;
	background : #f5f5f5;
	color      : #5a5a5a;
}

.woocommerce .product_meta {
	font-weight: 500;
	color      : #010f2e;
}

.woocommerce .sku {
	font-weight: 400;
	color      : #444d69;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active:before,
.woocommerce div.product .woocommerce-tabs ul.tabs li:after {
	box-shadow   : inherit !important;
	border-radius: 0;
	border       : none;
}

.woocommerce .sku_wrapper {
	display      : block;
	margin-bottom: 10px;
}

.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
	background    : #f5f5f566;
	text-align    : center;
	padding-bottom: 20px;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
	padding: 0 0 0 0;
	margin : 0 0 30px 0;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
	font-weight: 500;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
	border       : none;
	background   : none;
	border-radius: 0 0 0 0;
	margin       : 0 0px;
	padding      : 10px 3em;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
	background         : #ffc010;
	z-index            : 2;
	border-bottom-color: #ffc010;
	border             : none;
	color              : #fff;
	border-radius      : 0;
}

.woocommerce-tabs.wc-tabs-wrapper {
	margin-top: 50px;
	display   : inline-block;
	width     : 100%;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce ul.products li.product .price {
	color    : #ffc010;
	font-size: 12px;
}

.woocommerce-tabs.wc-tabs-wrapper h2 {
	font-size         : 20px;
	font-weight       : 600;
	margin-bottom     : 10px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.related.products h2,
.woocommerce-order-details h2,
.woocommerce-customer-details h2 {
	font-size         : 24px;
	font-weight       : 600;
	margin-bottom     : 30px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.woocommerce-cart-form th,
.shop_table.woocommerce-checkout-review-order-table th,
.shop_table.order_details th,
.account-orders-table th {
	background: #010f2e;
	padding   : 10px 0;
	color     : #fff;
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
	width        : 173px;
	padding      : 12px;
	border-radius: 30px;
}

.woocommerce ul.products li.product .star-rating {
	font-size: .857em;
	position : absolute;
	top      : 15px;
	left     : 15px;
}

.cst-woocommerce .page-title {
	display: none;
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
	width   : 100%;
	overflow: visible;
}

.woocommerce form .form-row label {
	line-height: 2;
	width      : 100%;
}

.woocommerce-input-wrapper {
	width: 100%;
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea,
.woocommerce form .form-row select {
	box-sizing : border-box;
	width      : 100%;
	margin     : 0;
	outline    : 0;
	line-height: normal;
	float      : left;
	padding    : 10px;
	border     : 1px solid #ccc;
}

.col2-set {
	margin-bottom: 50px;
}

.woocommerce-MyAccount-navigation li {
	background   : #010f2e;
	padding      : 15px 25px;
	margin-bottom: 3px;
}

.woocommerce-MyAccount-navigation li:hover {
	background   : #ffc010;
	padding      : 15px 25px;
	margin-bottom: 10px;
}

.woocommerce-MyAccount-navigation li a {
	color: #fff;
}

.woocommerce-account .woocommerce-MyAccount-content {
	float     : right;
	width     : 68%;
	background: #f5f5f5;
	padding   : 50px;
	min-height: 500px;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
	background-color      : #010f2e;
	color                 : #fff;
	-webkit-font-smoothing: antialiased;
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
	background-color: #ffc010;
	color           : #fff;
}

.wc-prodcut {
	background        : #fff;
	-webkit-transition: .3s;
	transition        : .3s;
}

.wc-prodcut:hover {
	box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
}

.wc-prodcut:hover .blog-btn {
	color     : #fff;
	background: #ffc010;
}

.wc-prodcut.active {
	box-shadow: 0px 10px 80px 0px rgba(193, 193, 193, 0.41);
}

.wc-prodcut.active .blog-btn {
	color     : #fff;
	background: #ffc010;
}

.wc-content {
	background: #fff;
	padding   : 40px 30px;
	text-align: center;
	z-index   : 1;
}

.wc-content h4 {
	font-size  : 19px;
	line-height: 1.3;
}

.wc-prodcut .product-buttons a {
	background   : #ffc010;
	color        : #fff;
	padding      : 15px 30px;
	float        : left;
	position     : absolute;
	top          : 30%;
	left         : 32%;
	border-radius: 30px;
	font-size    : 16px;
	font-weight  : 500;
	opacity      : 0;
	-ms-filter   : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.wc-prodcut:hover .product-buttons a {
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	top       : 40%;
}

.woocommerce-Price-amount.amount {
	color      : #ffc010;
	font-size  : 1.25em;
	font-weight: 500;
}

.woocommerce nav.woocommerce-pagination ul li {
	border-right: none;
}

.woocommerce nav.woocommerce-pagination ul {
	border: none;
}

.button.product_type_external {
	display: none !important;
}

/* 16. pagination */

.woocommerce-pagination .page-numbers {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : block;
	padding-left : 0;
	list-style   : none;
	border-radius: 0;
}

.woocommerce-pagination .page-numbers .page-numbers {
	display: inline-block;
	margin : 0 5px;
}

.woocommerce-pagination .page-numbers .page-numbers {
	border       : none;
	height       : 50px;
	width        : 50px;
	display      : block;
	line-height  : 40px;
	background   : #ffc010;
	border-radius: 50%;
	color        : #fff;
	font-size    : 14px;
	text-align   : center;
}

.woocommerce-pagination .page-numbers .page-numbers:hover {
	box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
	background: #010f2e;
	color     : #fff;
}

.woocommerce-pagination .page-numbers .page-numbers.current {
	background: #010f2e;
	color     : #ffffff;
}

.pagination-wrap .pagination {
	display      : block;
	border-radius: unset;
}

.pagination-wrap .pagination li {
	display      : inline-block;
	margin-right : 6px;
	margin-left  : 0;
	margin-bottom: 0;
}

.pagination-wrap .pagination li.active a {
	background: #ffc010;
	color     : #ffffff;
	box-shadow: 0px 8px 16px 0px rgba(26, 35, 126, 0.32);
}

.pagination-wrap .pagination li a {
	border       : none;
	height       : 50px;
	width        : 50px;
	display      : block;
	line-height  : 50px;
	background   : transparent -webkit-linear-gradient(left, #ffc010, #ffc01010) 0% 0% no-repeat padding-box;
	background   : transparent linear-gradient(90deg, #ffc010, #ffc01010) 0% 0% no-repeat padding-box;
	border-radius: 50%;
	color        : #fff;
	font-size    : 14px;
	text-align   : center;
}

.pagination-wrap .pagination li a:hover {
	color     : #ffffff;
	background: #ffc010;
}

/* Here's the new CSS to add... */

.admin-bar .menu-area {
	margin-top: 0px;
}

.admin-bar .second-header {
	margin-bottom: 0px;
}

.admin-bar .sticky-menu {
	top       : 32px;
	margin-top: 0;
}

/* 24. call */

.call-area h2 {
	color    : #fff;
	font-size: 38px;
}

.call-area h2 span {
	color: #ffc010;
}

.call-area p {
	color     : #fff;
	opacity   : 0.9;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

.number {
	font-size  : 55px;
	font-weight: 600;
	color      : #ffc010;
}

/* Shop */

.shop-area select {
	padding: 10px;
}

.product__img {
	position: relative;
}

.product__img img {
	width: 100%;
}

.product:hover .product-action a {
	margin    : 0 5px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
}

.product-action {
	position: absolute;
	left    : 0;
	right   : 0;
	bottom  : 30px;
}

.product-action a {
	display      : inline-block;
	background   : #ffffff;
	line-height  : 40px;
	color        : #a39fb4;
	margin       : 0 8px;
	opacity      : 0;
	-ms-filter   : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility   : hidden;
	border-radius: 5px;
	padding      : 0 20px;
}

.product-action a:hover {
	background: #ffc010;
	color     : #ffffff;
}

.pro-cat {
	margin-bottom: 15px;
	display      : block;
}

.pro-cat a {
	color         : #ffc010;
	font-size     : 14px;
	letter-spacing: 2px;
	font-weight   : bold;
	text-transform: uppercase;
}

.pro-title {
	margin-bottom: 12px;
}

.pro-title a {
	font-size: 24px;
}

.pro-title a:hover {
	color: #ffc010;
}

.price span {
	color    : #595B6B;
	font-size: 14px;
	display  : inline-block;
	margin   : 0 5px;
}

.price span.old-price {
	color          : #b5b5b5;
	text-decoration: line-through;
}

.product-showing p {
	margin        : 0;
	border        : 2px solid #eaedff;
	padding       : 17px 30px;
	text-align    : center;
	line-height   : 1;
	display       : inline-block;
	text-transform: uppercase;
	font-size     : 12px;
	letter-spacing: 2px;
	font-weight   : 500;
}

@media (max-width: 767px) {
	.product-showing p {
		padding: 17px 15px;
	}
}

.pro-filter {
	position: relative;
	display : inline-block;
}

@media (max-width: 767px) {
	.pro-filter {
		float: left;
	}
}

.img,
img {
	max-width         : 100%;
	-webkit-transition: all 0.3s ease-out 0s;
	transition        : all 0.3s ease-out 0s;
}

.pro-filter select {
	border            : 2px solid #eaedff;
	padding           : 17px 30px;
	display           : inline-block;
	text-transform    : uppercase;
	font-size         : 12px;
	letter-spacing    : 2px;
	line-height       : 1;
	color             : #6f7172;
	-webkit-appearance: none;
	appearance        : none;
	-moz-appearance   : none;
	width             : 145px;
	font-weight       : 500;
}

.pro-filter .nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color           : #fff;
	border-radius              : 0;
	border                     : solid 1px #e8e8e8;
	box-sizing                 : border-box;
	clear                      : both;
	cursor                     : pointer;
	display                    : block;
	float                      : left;
	font-family                : inherit;
	font-size                  : 14px;
	font-weight                : normal;
	height                     : 50px;
	line-height                : 48px;
	outline                    : none;
	padding-left               : 18px;
	padding-right              : 30px;
	position                   : relative;
	text-align                 : left !important;
	-webkit-transition         : all 0.2s ease-in-out;
	transition                 : all 0.2s ease-in-out;
	-webkit-user-select        : none;
	-moz-user-select           : none;
	-ms-user-select            : none;
	user-select                : none;
	white-space                : nowrap;
	width                      : auto;
}

.pro-filter:before {
	content    : "\f107";
	right      : 15px;
	top        : 15px;
	position   : absolute;
	color      : #758799;
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
}

.shop-tab ul li {
	margin-left: 20px;
}

@media (max-width: 767px) {
	.shop-tab ul li {
		margin-left: 10px;
	}
}

.shop-tab ul li a {
	font-size     : 14px;
	font-weight   : 500;
	color         : #6f7172;
	letter-spacing: 2px;
	padding       : 0;
	text-transform: uppercase;
	position      : relative;
	height        : 50px;
	width         : 50px;
	border-radius : 50%;
	background    : #f6f6ff;
	line-height   : 51px;
	text-align    : center;
}

.shop-tab ul li a.active {
	color     : white;
	background: #8fb569;
}

.shop-thumb-tab {
	width: 160px;
	float: right;
}

@media (max-width: 767px) {
	.shop-thumb-tab {
		float        : none;
		width        : 100%;
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.shop-thumb-tab ul {
		margin: 0 -5px;
	}
}

.shop-thumb-tab ul li {
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.shop-thumb-tab ul li {
		width  : 33.33%;
		float  : left;
		padding: 0 5px;
	}
}

.shop-thumb-tab ul li a {
	padding: 0;
}

.product-details-img {
	margin-right: 180px;
	overflow    : hidden;
}

@media (max-width: 767px) {
	.product-details-img {
		margin-left: 0;
	}
}

.product-details-title p {
	color         : #6a667b;
	font-size     : 12px;
	text-transform: uppercase;
	font-weight   : 500;
	letter-spacing: 1px;
	margin-bottom : 10px;
}

.product-details-title h1 {
	font-size     : 40px;
	margin        : 0;
	line-height   : 1.2;
	letter-spacing: -2px;
	margin-bottom : 15px;
}

@media (max-width: 767px) {
	.product-details-title h1 {
		font-size: 36px;
	}
}

.details-price span {
	color       : #ffc010;
	font-size   : 20px;
	font-weight : 400;
	margin-left : 0;
	margin-right: 10px;
}

.details-price {
	border-bottom: 2px solid #eaedff;
}

.product-cat span {
	text-transform: uppercase;
	font-size     : 14px;
	font-weight   : 500;
	color         : #100d1c;
}

.product-cat a {
	text-transform: uppercase;
	font-size     : 14px;
	font-weight   : 500;
	letter-spacing: 2px;
}

.product-cat a:hover {
	color: #8fb569;
}

.product-social a {
	margin-right : 10px;
	background   : #f5f5ff;
	height       : 50px;
	width        : 50px;
	line-height  : 48px;
	border-radius: 30px;
	color        : #6f7172;
	display      : inline-block;
	text-align   : center;
	font-size    : 14px;
}

@media (max-width: 767px) {
	.product-social a {
		margin-bottom: 10px;
	}
}

.product-social a:hover {
	background: #8fb569;
	color     : #ffffff;
}

.plus-minus {
	display: inline-block;
}

@media (max-width: 767px) {
	.plus-minus {
		display      : block;
		margin-bottom: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.plus-minus {
		display      : inline-block;
		margin-bottom: 0;
	}
}

.action-btn {
	background : #8fb569;
	padding    : 20px 25px;
	border     : none;
	margin-left: 15px;
	color      : #ffffff;
	display    : inline-block;
}

.action-btn:hover {
	background: #ffc010;
	color     : #ffffff;
}

.product-action-list {
	overflow: hidden;
}

.product-action-list a {
	float: left;
}

@media (max-width: 767px) {
	.product-action-list a.btn {
		padding      : 23px 30px;
		margin-bottom: 7px;
	}
}

@media (max-width: 767px) {
	.product-action-list a.action-btn {
		margin-left  : 5px;
		margin-bottom: 7px;
	}
}

.cart-plus-minus input {
	height            : 60px;
	width             : 100px;
	border            : 0;
	border            : 2px solid #eaedff;
	text-align        : center;
	-moz-appearance   : none;
	-webkit-appearance: none;
	appearance        : none;
}

.cart-plus-minus {
	display     : inline-block;
	position    : relative;
	margin-right: 15px;
}

.cart-plus-minus .qtybutton {
	position : absolute;
	top      : 15px;
	left     : 17px;
	font-size: 20px;
	color    : #c4bedd;
	cursor   : pointer;
}

.cart-plus-minus .inc {
	left : auto;
	right: 17px;
}

.additional-info .table td,
.additional-info .table th {
	padding       : 12px 0;
	padding       : .75rem 0;
	vertical-align: top;
	border-top    : 1px solid #f6f6f6;
	font-weight   : 400;
}

.shop-cat a {
	padding: 45px 30px;
	border : 2px solid #eaedff;
	display: block;
}

.shop-cat a i {
	font-size         : 100px;
	color             : #8fb569;
	margin-bottom     : 30px;
	-webkit-transition: .3s;
	transition        : .3s;
}

.shop-cat a h4 {
	text-transform    : uppercase;
	font-size         : 16px;
	font-weight       : 600;
	font-family       : "Poppins", sans-serif;
	-webkit-transition: .3s;
	transition        : .3s;
	letter-spacing    : 1px;
}

.shop-cat a:hover {
	background  : #8fb569;
	border-color: #8fb569;
}

.shop-cat a:hover i {
	color: #ffffff;
}

.shop-cat a:hover h4 {
	color: #ffffff;
}

.product-list-content .price span:first-child {
	margin-left: 0;
}

.bakix-details-tab ul {
	border-bottom: 2px solid #e1e1e1;
}

.bakix-details-tab ul li a {
	font-size     : 16px;
	font-weight   : 500;
	color         : #010f2e;
	letter-spacing: 2px;
	padding       : 0;
	text-transform: capitalize;
	position      : relative;
	padding       : 0 25px;
}

@media (max-width: 767px) {
	.bakix-details-tab ul li a {
		padding: 0 10px;
	}
}

.bakix-details-tab ul li a.active {
	color: #ffc010;
}

.bakix-details-tab ul li a.active:before {
	position          : absolute;
	bottom            : -32px;
	left              : 0;
	height            : 2px;
	width             : 100%;
	content           : "";
	background        : #ffc010;
	-webkit-transition: .3s;
	transition        : .3s;
}

@media (max-width: 767px) {
	.bakix-details-tab ul li a.active:before {
		display: none;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.bakix-details-tab ul li a.active:before {
		display: block;
	}
}

/* Cart */

.table-content table {
	background   : #ffffff;
	border-color : #eaedff;
	border-radius: 0;
	border-style : solid;
	border-width : 1px 0 0 1px;
	text-align   : center;
	width        : 100%;
	margin-bottom: 0;
}

.table-content table td.product-name {
	font-size     : 16px;
	font-weight   : 400;
	text-transform: capitalize;
}

.table-content table td.product-name a:hover {
	color: #ffc010;
}

.table-content table td {
	border-top    : medium none;
	padding       : 20px 10px;
	vertical-align: middle;
	font-size     : 16px;
}

.table-content table th,
.table-content table td {
	border-bottom: 1px solid #eaedff;
	border-right : 1px solid #eaedff;
}

.product-quantity input {
	border     : none;
	color      : #6f7172;
	font-size  : 14px;
	font-weight: normal;
	border     : 0;
}

.table td,
.table th {
	border-top: 1px solid #eaedff;
}

.product-quantity>input {
	width        : 80px;
	border-radius: 3px;
}

.table-content table td.product-subtotal {
	font-size: 16px;
}

.table-content table td .cart-plus-minus {
	float : none;
	margin: 0 auto;
}

.coupon-all {
	margin-top: 50px;
}

.coupon {
	float: left;
}

@media (max-width: 767px) {
	.coupon {
		float: none;
	}
}

#coupon_code {
	height      : 62px;
	border      : 2px solid #eaedff;
	padding     : 0 15px;
	margin-right: 10px;
}

@media (max-width: 767px) {
	#coupon_code {
		margin-bottom: 15px;
	}
}

.coupon2 {
	float: right;
}

@media (max-width: 767px) {
	.coupon2 {
		float     : none;
		margin-top: 15px;
	}
}

.cart-page-total {
	padding-top: 50px;
}

.cart-page-total>h2 {
	font-size     : 25px;
	margin-bottom : 20px;
	text-transform: capitalize;
}

.cart-page-total>ul {
	border: 1px solid #eaedff;
}

.cart-page-total>ul>li {
	list-style   : none;
	font-size    : 15px;
	color        : #6f7172;
	padding      : 10px 30px;
	border-bottom: 1px solid #eaedff;
	font-weight  : 400;
}

.cart-page-total ul>li>span {
	float: right;
}

.cart-page-total li:last-child {
	border-bottom: 0;
}

td.product-thumbnail img {
	width: 125px;
}

/*Checkout */

.coupon-accordion h3 {
	background-color: #fff1f0;
	border-top      : 3px solid #ffc010;
	font-size       : 14px;
	font-weight     : 400;
	margin          : 0 0 25px;
	padding         : 1em 2em 1em 3.5em;
	position        : relative;
	width           : auto;
}

.coupon-accordion h3:before {
	content    : "\f07b";
	left       : 15px;
	top        : 13px;
	position   : absolute;
	color      : #6f7172;
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
}

.coupon-accordion span {
	color             : #6f7172;
	cursor            : pointer;
	-webkit-transition: .3s;
	transition        : .3s;
}

.coupon-accordion span:hover,
p.lost-password a:hover {
	color: #ffc010;
}

.coupon-content {
	border       : 1px solid #eaedff;
	display      : none;
	margin-bottom: 20px;
	padding      : 30px;
}

.coupon-info p.coupon-text {
	margin-bottom: 15px;
}

.coupon-info p {
	margin-bottom: 0;
}

.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
	display: block;
	color  : #6f7172;
}

.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
	color      : red;
	font-weight: 600;
}

.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
	border   : 1px solid #eaedff;
	height   : 45px;
	margin   : 0 0 14px;
	max-width: 100%;
	padding  : 0 0 0 10px;
	width    : 100%;
}

.coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
	background: #ffc010 none repeat scroll 0 0;
}

.coupon-info p.form-row input[type="checkbox"] {
	position: relative;
	top     : 2px;
}

.form-row>label {
	margin-top : 15px;
	margin-left: 15px;
	color      : #6f7172;
}

.buttons-cart input,
.coupon input[type="submit"],
.buttons-cart a,
.coupon-info p.form-row input[type="submit"] {
	background        : #010f2e none repeat scroll 0 0;
	border            : medium none;
	color             : #fff;
	display           : inline-block;
	float             : left;
	font-size         : 12px;
	font-weight       : 600;
	height            : 40px;
	line-height       : 40px;
	margin-right      : 15px;
	padding           : 0 15px;
	text-transform    : uppercase;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
}

p.lost-password {
	margin-top: 15px;
}

p.lost-password a {
	color: #6f6f6f;
}

p.checkout-coupon input[type="text"] {
	height       : 45px;
	padding      : 0 15px;
	width        : 100%;
	border       : 1px solid #eaedff;
	margin-bottom: 15px;
}

.coupon-checkout-content {
	display: none;
}

.checkbox-form h3 {
	border-bottom : 1px solid #eaedff;
	font-size     : 26px;
	margin        : 0 0 20px;
	padding-bottom: 10px;
	width         : 100%;
}

.country-select {
	margin-bottom: 30px;
	position     : relative;
}

.country-select label,
.checkout-form-list label {
	color  : #6f7172;
	display: block;
	margin : 0 0 5px;
}

.country-select label span.required,
.checkout-form-list label span.required {
	color: red;
}

.country-select select {
	-moz-appearance: none;
	border         : 1px solid #eaedff;
	height         : 45px;
	padding-left   : 10px;
	width          : 100%;
	color          : #6f7172;
}

.country-select:before {
	content    : "\f107";
	right      : 15px;
	top        : 38px;
	position   : absolute;
	color      : #6f7172;
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
}

.checkout-form-list {
	margin-bottom: 30px;
}

.checkout-form-list label {
	color: #6f7172;
}

.checkout-form-list input[type="text"],
.checkout-form-list input[type="password"],
.checkout-form-list input[type="email"] {
	background   : #ffffff;
	border       : 1px solid #eaedff;
	border-radius: 0;
	height       : 45px;
	padding      : 0 0 0 10px;
	width        : 100%;
}

.checkout-form-list input[type="text"]::-moz-placeholder,
.checkout-form-list input[type="password"]::-moz-placeholder,
.checkout-form-list input[type="email"]::-moz-placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.checkout-form-list input[type="text"]::-webkit-input-placeholder,
.checkout-form-list input[type="password"]::-webkit-input-placeholder,
.checkout-form-list input[type="email"]::-webkit-input-placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.checkout-form-list input[type="text"]:-ms-input-placeholder,
.checkout-form-list input[type="password"]:-ms-input-placeholder,
.checkout-form-list input[type="email"]:-ms-input-placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.checkout-form-list input[type="text"]::placeholder,
.checkout-form-list input[type="password"]::placeholder,
.checkout-form-list input[type="email"]::placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.checkout-form-list input[type="checkbox"] {
	display     : inline-block;
	margin-right: 10px;
	position    : relative;
	top         : 1px;
}

.create-acc label {
	color  : #6f7172;
	display: inline-block;
}

.create-account {
	display: none;
}

.ship-different-title h3 label {
	display     : inline-block;
	margin-right: 20px;
	color       : #6f7172;
}

.order-notes textarea {
	border : 1px solid #eaedff;
	height : 90px;
	padding: 15px;
	width  : 100%;
}

.order-notes textarea::-moz-placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.order-notes textarea::-webkit-input-placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.order-notes textarea:-ms-input-placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.order-notes textarea::placeholder {
	color     : #6f7172;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

#ship-box-info {
	display: none;
}

.panel-group .panel {
	border-radius: 0;
}

.panel-default>.panel-heading {
	border-radius: 0;
}

.your-order {
	padding: 30px 40px 45px;
	border : 3px solid #eaedff;
}

@media (max-width: 767px) {
	.your-order {
		padding: 15px;
	}
}

.your-order h3 {
	border-bottom : 1px solid #eaedff;
	font-size     : 30px;
	margin        : 0 0 20px;
	padding-bottom: 10px;
	width         : 100%;
}

.your-order-table table {
	background: none;
	border    : 0;
	width     : 100%;
}

.your-order-table table th,
.your-order-table table td {
	border-bottom: 1px solid #eaedff;
	border-right : medium none;
	color        : #6f7172;
	font-size    : 14px;
	padding      : 15px 0;
	text-align   : left;
}

@media (max-width: 767px) {

	.your-order-table table th,
	.your-order-table table td {
		padding-right: 10px;
	}
}

.your-order-table table th {
	border-top    : medium none;
	color         : #6f7172;
	font-weight   : normal;
	text-align    : left;
	vertical-align: middle;
	white-space   : nowrap;
	width         : 250px;
}

.panel-body>p {
	color: #222;
}

.your-order-table table .shipping ul li input {
	position: relative;
	top     : 2px;
}

.your-order-table table .shipping ul li label {
	color: #6f7172;
}

.your-order-table table .shipping th {
	vertical-align: top;
}

.your-order-table table .order-total th {
	border-bottom: 0;
	font-size    : 14px;
}

.your-order-table table .order-total td {
	border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
	background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
	color      : #ffc010;
	font-size  : 18px;
	font-weight: 500;
}

.payment-method {
	margin-top: 40px;
}

.panel-title>a {
	display: block;
}

.order-button-payment input {
	background        : #232323 none repeat scroll 0 0;
	border            : medium none;
	color             : #fff;
	font-size         : 15px;
	font-weight       : 600;
	height            : 40px;
	margin            : 0px 0 0;
	padding           : 0;
	text-transform    : uppercase;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	width             : 100%;
}

.order-button-payment input:hover {
	background: #FE4536 none repeat scroll 0 0;
}

.payment-method .btn-link {
	background        : no-repeat;
	border            : medium none;
	border-radius     : 0;
	color             : #444;
	cursor            : pointer;
	display           : inline-block;
	font-size         : 14px;
	font-weight       : 500;
	letter-spacing    : 1px;
	line-height       : 1;
	margin-bottom     : 0;
	padding           : 3px 10px;
	text-align        : center;
	text-transform    : uppercase;
	-webkit-transition: all 0.3s ease 0s;
	transition        : all 0.3s ease 0s;
	vertical-align    : middle;
	white-space       : nowrap;
	text-decoration   : none;
}

.payment-method .card {
	background-color: #ffffff;
	border          : 1px solid #eaedff;
	border-radius   : 0;
	margin-bottom   : 10px;
}

.payment-method .accordion .card:first-of-type {
	border: 1px solid #eaedff;
}

.accordion-header:first-child {
	border-radius: 0;
}

.payment-method .accordion-header {
	background-color: #ffffff;
	border-bottom   : 1px solid #eaedff;
}

.order-button-payment button {
	width: 100%;
}

.country-select .nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color           : #fff;
	border-radius              : 0;
	border                     : solid 1px #eaedff;
	box-sizing                 : border-box;
	clear                      : both;
	cursor                     : pointer;
	display                    : block;
	float                      : left;
	font-family                : inherit;
	font-size                  : 14px;
	font-weight                : normal;
	height                     : 45px;
	line-height                : 45px;
	outline                    : none;
	padding-left               : 18px;
	padding-right              : 30px;
	position                   : relative;
	text-align                 : left !important;
	-webkit-transition         : all 0.2s ease-in-out;
	transition                 : all 0.2s ease-in-out;
	-webkit-user-select        : none;
	-moz-user-select           : none;
	-ms-user-select            : none;
	user-select                : none;
	white-space                : nowrap;
	width                      : 100%;
	margin-bottom              : 19px;
}

/* 24. Login */

.zcube-login {
	padding: 90px;
	border : 2px solid #eaedff;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.zcube-login {
		padding: 50px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.zcube-login {
		padding: 40px;
	}
}

@media (max-width: 767px) {
	.zcube-login {
		padding: 30px;
	}
}

.zcube-login h3 {
	font-size: 30px;
}

.zcube-login input {
	width        : 100%;
	height       : 60px;
	border       : 2px solid #eaedff;
	color        : #6f7172;
	padding      : 0 20px;
	margin-bottom: 20px;
}

.zcube-login label {
	color  : #222;
	display: block;
}

.zcube-login label span {
	color: #fe4536;
}

.login-action input {
	width : inherit;
	height: auto;
}

.login-action label {
	display    : inline-block;
	margin-left: 5px;
}

.or-divide {
	border-top: 2px solid #eaedff;
	margin    : 40px 0;
	text-align: center;
	position  : relative;
}

.or-divide span {
	position   : absolute;
	left       : 0;
	right      : 0;
	top        : -9px;
	background : #ffffff;
	display    : inline-block;
	width      : 40px;
	margin     : auto;
	line-height: 1;
	font-weight: 500;
}

@media (max-width: 767px) {
	.forgot-login {
		float: none;
	}
}

.forgot-login a {
	color: #fe4536;
}

.forgot-login a:hover {
	color: #84b77c;
}

@media (max-width: 767px) {
	.log-rem {
		float        : none;
		margin-bottom: 10px;
		display      : block;
	}
}

/*Services Category*/

.services-categories {
	position        : relative;
	margin-bottom   : 50px;
	background-color: rgba(0, 0, 0, 0.03);
}

.services-categories li {
	position     : relative;
	border       : 1px solid #e7e7e7;
	border-bottom: 0;
	z-index      : 9;
}

.services-categories li:last-child {
	border-bottom: 1px solid #e7e7e7;
}

.services-categories li:before {
	position        : absolute;
	top             : 0;
	left            : 0;
	height          : 100%;
	width           : 4px;
	background-color: #30313d;
	content         : "";
	opacity         : 0;
	-ms-filter      : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility      : hidden;
}

.services-categories li.active:before,
.services-categories li:hover:before {
	opacity           : 1;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility        : visible;
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.services-categories li a {
	font-size         : 16px;
	font-weight       : 500;
	color             : #222222;
	line-height       : 30px;
	padding           : 10px 25px;
	display           : block;
	padding-left      : 38px;
	overflow          : hidden;
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.services-categories li.active a,
.services-categories li:hover a {
	color: #ffffff;
}

.services-categories li a:before {
	position          : absolute;
	left              : 20px;
	top               : 10px;
	font-size         : 12px;
	line-height       : 30px;
	color             : #222222;
	font-weight       : 900;
	content           : "\f0da";
	font-family       : "Font Awesome 5 pro";
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.services-categories li.active a:before,
.services-categories li:hover a:before {
	color: #ffffff;
}

.services-categories li a:after {
	position                : absolute;
	left                    : -1px;
	top                     : 0;
	height                  : 100%;
	width                   : 100%;
	content                 : "";
	background-color        : #ffc010;
	z-index                 : -1;
	-webkit-transition      : -webkit-transform 0.4s ease;
	transition              : -webkit-transform 0.4s ease;
	transition              : transform 0.4s ease;
	transition              : transform 0.4s ease, -webkit-transform 0.4s ease;
	-webkit-transform       : scale(0, 1);
	transform               : scale(0, 1);
	-webkit-transform-origin: right top;
	transform-origin        : right top;
}

.services-categories li.active a:after,
.services-categories li:hover a:after {
	-webkit-transform-origin: left bottom;
	transform-origin        : left bottom;
	-webkit-transform       : scale(1, 1);
	transform               : scale(1, 1);
}

/*=== Brochures Box ===*/

.brochures-box {
	position     : relative;
	margin-bottom: 50px;
}

.brochures-box .inner {
	position        : relative;
	background-color: #2a2e48;
	padding         : 35px 30px 25px;
}

.brochures-box h4 {
	display      : block;
	font-size    : 17px;
	line-height  : 1.2em;
	color        : #ffffff;
	font-weight  : 600;
	margin-bottom: 15px;
}

.brochures-box .text {
	font-size    : 15px;
	line-height  : 23px;
	color        : #ffffff;
	margin-bottom: 20px;
}

.brochures-box .theme-btn {
	font-size     : 14px;
	line-height   : 20px;
	padding       : 8px 20px;
	display       : block;
	width         : 100%;
	text-align    : center;
	text-transform: uppercase;
	margin-bottom : 10px;
	color         : #fff;
}

.brochures-box .theme-btn i {
	margin-right: 3px;
}

.btn-style-one {
	position        : relative;
	font-size       : 16px;
	line-height     : 20px;
	color           : #ffffff;
	background-color: #cb212a;
	padding         : 15px 35px;
	font-weight     : 600;
	overflow        : hidden;
}

/*Help Box*/

.help-box {
	position       : relative;
	background-size: cover;
	padding        : 30px 25px;
}

.help-box:before {
	position        : absolute;
	content         : '';
	left            : 0px;
	top             : 0px;
	width           : 100%;
	height          : 100%;
	display         : block;
	background-color: rgba(54, 54, 54, 0.90);
}

.help-box>span {
	position     : relative;
	display      : block;
	font-size    : 14px;
	line-height  : 20px;
	color        : #ffffff;
	margin-bottom: 10px;
}

.help-box h4 {
	position     : relative;
	color        : #ffffff;
	font-size    : 21px;
	font-weight  : 700;
	line-height  : 1.2em;
	margin-bottom: 15px;
}

.help-box p {
	position     : relative;
	color        : #ffffff;
	font-size    : 14px;
	line-height  : 24px;
	margin-bottom: 20px;
}

.help-box .theme-btn {
	padding    : 8px 30px;
	font-size  : 14px;
	font-weight: 600;
}

/*** 

====================================================================
		Service Detail
====================================================================

***/

.service-detail {
	position    : relative;
	padding-left: 20px;
}

.service-detail .images-box {
	position: relative;
	margin  : 0 -5px;
}

.service-detail .images-box .column {
	padding: 0 5px;
}

.service-detail .images-box .image {
	position     : relative;
	margin-bottom: 10px;
}

.service-detail .images-box .image img {
	display: block;
	width  : 100%;
	height : auto;
}

.service-detail .content-box {
	position: relative;
	padding : 0px 0 0;
}

.service-detail .content-box h2 {
	position     : relative;
	display      : block;
	font-size    : 32px;
	line-height  : 1.2em;
	color        : #343a40;
	margin-bottom: 20px;
}

.service-detail .content-box h3 {
	position     : relative;
	display      : block;
	font-size    : 24px;
	line-height  : 1.2em;
	color        : #343a40;
	margin-bottom: 20px;
}

.service-detail .content-box p {
	position     : relative;
	display      : block;
	margin-bottom: 25px;
}

.service-detail .two-column {
	position: relative;
}

.service-detail .two-column .title {
	margin-bottom: 40px;
}

.service-detail .two-column p {
	margin-bottom: 25px;
}

.service-detail .two-column .image-column {
	position     : relative;
	margin-bottom: 26px;
}

.service-detail .two-column .image-column .image {
	margin-bottom: 0;
}

.service-detail .two-column .text-column {
	position: relative;
}

.service-detail .prod-tabs {
	position: relative;
}

.service-detail .prod-tabs .tab-btns {
	position     : relative;
	z-index      : 1;
	border-bottom: 1px solid #dddddd;
}

.service-detail .prod-tabs .tab-btns .tab-btn {
	position          : relative;
	top               : 1px;
	display           : block;
	float             : left;
	margin-right      : 5px;
	font-size         : 16px;
	color             : #333333;
	background        : #ffffff;
	text-transform    : capitalize;
	font-weight       : 400;
	line-height       : 23px;
	cursor            : pointer;
	border            : 1px solid #e5e5e5;
	border-bottom     : 0;
	padding           : 10px 25px;
	transition        : all 0.3s ease;
	-moz-transition   : all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition    : all 0.3s ease;
	-o-transition     : all 0.3s ease;
}

.service-detail .prod-tabs .tab-btns .tab-btn:before {
	position          : absolute;
	content           : '';
	top               : 0;
	left              : 50%;
	width             : 0%;
	height            : 3px;
	background-color  : #dc3545;
	transition        : all 0.3s ease;
	-moz-transition   : all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition    : all 0.3s ease;
	-o-transition     : all 0.3s ease;
}

.service-detail .prod-tabs .tab-btns .tab-btn:hover:before,
.service-detail .prod-tabs .tab-btns .tab-btn.active-btn:before {
	width: 100%;
	left : 0;
}

.service-detail .prod-tabs .tab-btns .tab-btn:hover:after,
.service-detail .prod-tabs .tab-btns .tab-btn.active-btn:after {
	position          : absolute;
	content           : '';
	left              : 0px;
	bottom            : -3px;
	width             : 100%;
	height            : 5px;
	background-color  : #ffffff;
	transition        : all 0.3s ease;
	-moz-transition   : all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition    : all 0.3s ease;
	-o-transition     : all 0.3s ease;
}

.service-detail .prod-tabs .tab-btns .tab-btn:hover,
.service-detail .prod-tabs .tab-btns .tab-btn.active-btn {
	color     : #ffc010;
	background: #ffffff;
}

.service-detail .prod-tabs .tabs-content {
	position: relative;
	padding : 25px 30px 45px;
	border  : 1px solid #dddddd;
}

.service-detail .prod-tabs .tabs-content .tab {
	position  : relative;
	display   : none;
	border-top: 0px;
}

.service-detail .prod-tabs .tabs-content .tab.active-tab {
	display: block;
}

.service-detail .prod-tabs .tabs-content .tab .content {
	position: relative;
}

.service-detail .prod-tabs .tabs-content .tab .content p {
	position     : relative;
	font-size    : 16px;
	line-height  : 26px;
	color        : #666666;
	margin-bottom: 0px;
}

.list-style-one {
	position     : relative;
	display      : block;
	margin-bottom: 30px;
}

.list-style-one li {
	position     : relative;
	padding-left : 25px;
	margin-bottom: 10px;
}

.list-style-one li:before {
	position   : absolute;
	left       : 0;
	top        : 0;
	font-size  : 14px;
	line-height: 25px;
	color      : #ffc010;
	content    : "\f101";
	font-weight: 900;
	font-family: "Font Awesome 5 Pro";
}

/*** 

====================================================================
		Project Detail
====================================================================

***/

.project-detail {
	position: relative;
	padding : 100px 0 85px;
}

.project-detail .upper-box {
	position     : relative;
	margin-bottom: 40px;
}

.project-detail .upper-box .image {
	position     : relative;
	margin-bottom: 0;
}

.project-detail .upper-box .image img {
	display: block;
	width  : 100%;
	height : auto;
}

.project-detail .single-item-carousel .owl-nav {
	position  : absolute;
	left      : 0;
	top       : 50%;
	width     : 100%;
	margin-top: -50px;
}

.project-detail .single-item-carousel .owl-next,
.project-detail .single-item-carousel .owl-prev {
	position          : absolute;
	left              : 0;
	height            : 100px;
	width             : 30px;
	background-color  : rgba(0, 0, 0, 0.5);
	text-align        : center;
	font-size         : 18px;
	line-height       : 100px;
	color             : #ffffff;
	font-weight       : 400;
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.project-detail .single-item-carousel .owl-next {
	left : auto;
	right: 0px;
}

.project-detail .single-item-carousel .owl-next:hover,
.project-detail .single-item-carousel .owl-prev:hover {
	background-color: rgba(255, 255, 255, .5);
	color           : #000000;
}

.project-detail .text-column {
	position: relative;
}

.project-detail .text-column .inner-column {
	position     : relative;
	padding-right: 30px;
}

.project-detail .text-column .inner-column .dropcap {
	float        : left;
	background   : #ffc010;
	border-radius: 20px;
	padding      : 30px;
	color        : #FFFFFF;
	font-size    : 48px;
	font-weight  : 600;
	margin-right : 20px;
}

.project-detail .text-column .inner-column p strong {
	color: #010f2e;
}

.project-detail .pr-ul {
	margin-top   : 10px;
	float        : left;
	margin-bottom: 0px;
}

.project-detail .pr-ul li {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
	margin-bottom: 15px;
	float        : left;
	width        : 50%;
}

.project-detail .pr-ul .icon i {
	width        : 40px;
	height       : 40px;
	background   : #FFFFFF 0% 0% no-repeat padding-box;
	border       : 2px solid #EEEEEE;
	border-radius: 50%;
	text-align   : center;
	line-height  : 40px;
	margin-right : 20px;
	color        : #ffc010;
}

/* Lower Content */

.project-detail .lower-content {
	position: relative;
}

.project-detail .lower-content h2 {
	position     : relative;
	display      : block;
	font-size    : 32px;
	font-weight  : 600;
	margin-bottom: 20px;
}

.project-detail .lower-content h3 {
	position     : relative;
	display      : block;
	font-size    : 24px;
	font-weight  : 600;
	margin-bottom: 20px;
}

.project-detail .lower-content h4 {
	position     : relative;
	display      : block;
	font-size    : 22px;
	margin-bottom: 20px;
}

.project-detail .lower-content p {
	position     : relative;
	display      : block;
	margin-bottom: 30px;
}

.project-detail .info-column {
	position: relative;
}

.project-detail .info-column .inner-column {
	position     : relative;
	padding      : 30px;
	box-shadow   : 3px 4px 15px #00000026;
	border-radius: 10px;
}

.project-detail .project-info {
	position: relative;
}

.project-detail .project-info li {
	position     : relative;
	padding-left : 30px;
	margin-bottom: 10px;
}

.project-detail .project-info li .icon {
	position   : absolute;
	left       : 0;
	top        : 0;
	font-size  : 18px;
	line-height: 28px;
	color      : #ffc010;
}

.project-detail .project-info li strong {
	color        : #010f2e;
	font-size    : 20px;
	line-height  : 24px;
	margin-bottom: 10px;
	display      : inline-block;
	font-weight  : 600;
}

.project-detail .project-info li p {
	font-size    : 15px;
	line-height  : 24px;
	font-weight  : 400;
	margin-bottom: 0;
}

.project-detail .project-info li p a {
	display           : inline-block;
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.project-detail .project-info li p a:hover {
	color: #dc3545;
}

/* Load More Option */

.load-more-option {
	position  : relative;
	display   : block;
	width     : 100%;
	text-align: center;
	margin-top: 70px;
}

.load-more-option li.prev a,
.load-more-option li.next a {
	position          : relative;
	display           : block;
	font-size         : 18px;
	line-height       : 20px;
	width             : 80px;
	padding           : 10px 10px;
	text-align        : center;
	color             : #ffffff;
	background-color  : #ffc010;
	border-radius     : 10px;
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.load-more-option li.prev a:hover,
.load-more-option li.next a:hover {
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
}

.load-more-option .load-more {
	position : relative;
	display  : inline-block;
	font-size: 30px;
}

.load-more-option .load-more a {
	position          : relative;
	display           : inline-block;
	font-size         : 30px;
	line-height       : 42px;
	color             : #dc3545;
	-webkit-transition: all 300ms ease;
	transition        : all 300ms ease;
}

.load-more-option .load-more a:hover {
	color: #000000;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.video2 {
	height    : auto;
	min-height: 100%;
	min-width : 100%;
	position  : absolute;
	top       : 0;
	width     : auto;
	z-index   : 0;
}

/* 8. newslater-area */

.newslater-area {
	position: relative;
}

.newslater-area:before {
	content   : "";
	position  : absolute;
	width     : 100%;
	height    : 50%;
	background: #1e191f;
	bottom    : 0;
}

.newslater-area .container {
	border-radius   : 40px;
	background-color: rgb(38, 34, 40);
	padding         : 60px 80px;
	position        : relative;
	z-index         : 1;
	margin-top      : -80px;
}

.newslater-area h2,
.newslater-area p {
	color: #fff;
}

.newslater-area p {
	color: #A0A4A5;
}

.newslater .input-bg {
	background   : #3A3D3E;
	border-radius: 30px !important;
	float        : left;
	padding-right: 5px;
	position     : relative;
	z-index      : 3;
	width        : 40%;
}

.newslater .input-bg2 {
	background   : #3A3D3E;
	border-radius: 30px !important;
	float        : left;
	padding-right: 5px;
	position     : relative;
	z-index      : 2;
	margin-left  : -35px;
	width        : 40%;
}

.newslater input {
	background   : #2d2a2f !important;
	border-radius: 30px;
	border       : 1px solid #2d2a2f !important;
	box-shadow   : none !important;
	padding      : 9px 45px !important;
	color        : #000 !important;
	height       : calc(1.5em + .75rem + 19px);
	width        : 67% !important;
	border-radius: 0 !important;
	position     : relative;
	z-index      : 1;
}

.newslater .form-group {
	margin-bottom: 0;
}

.newslater button {
	background: #fff;
	color     : #000;
}

.newslater button:hover {
	background: #ff3494;
	color     : #fff;
}

.newslater *::-moz-placeholder {
	color     : #939393;
	font-size : 14px;
	opacity   : 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.newslater *::-webkit-input-placeholder {
	color     : #939393;
	opacity   : 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.newslater *:-ms-input-placeholder {
	color     : #939393;
	opacity   : 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.newslater *::placeholder {
	color     : #939393;
	opacity   : 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	font-size : 14px;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.newslater-area #contact-form4 {
	float     : left;
	width     : 100%;
	margin-top: 0;
}

.newslater-area #contact-form4 button {
	width        : 60px;
	background   : #ffc010;
	color        : #fff;
	height       : 56px;
	padding      : 0 0px 0 0px;
	z-index      : 1;
	text-align   : center;
	border-radius: 0;
}

.slick-slide {
	outline: none;
}

.features-area h2,
.features-area h4,
.features-area h5,
.features-area p {
	color: #fff;
}

.features-area h5 {
	margin-bottom: 15px;
}

.features-area ul {
	margin-top: 30px;
	color     : #fff;
}

.features-area li {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
	margin-bottom: 15px;
}

.features-area li .icon {
	margin-right: 20px;
	color       : #84DAA9;
}

/* 8. search-popup */

.search-popup {
	background    : rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border        : medium none;
	box-shadow    : 0 0 0;
	margin-top    : 200px;
	text-transform: uppercase;
}

.search-bg {
	background: #f5f5f5;
	opacity   : 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.close2 {
	color    : #ffc010;
	cursor   : pointer;
	font-size: 14px;
}

.search-bg a i {
	color    : #ffc010;
	font-size: 18px;
}

.search-outer {
	border-bottom : 1px solid #282828;
	float         : left;
	margin-top    : 100px;
	padding-bottom: 16px;
	width         : 100%;
}

.search-outer input {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border    : medium none;
	color     : #000;
	font-size : 14px;
	width     : 100%;
}

.case-study-content {
	padding   : 30px;
	background: #FFF5F4 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 32px #FFF5F4;
}

.case-study-content span {
	margin-bottom: 10px;
	display      : inline-block;
}

.case-study-content02 {
	padding   : 30px 30px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 16px 32px #010f2e0A;
}

.case-study-content02 span {
	margin-bottom: 10px;
	display      : inline-block;
	color        : #ffc010;
}

.case-study-content02 .ss-btn {
	display           : none;
	-webkit-transition: all 0.5s ease 0s;
	transition        : all 0.5s ease 0s;
}

.case-study-box:hover .case-study-content02 .ss-btn {
	display: inline-block;
}

.case-study-box .case-study-content02 {
	-webkit-transition: all 0.5s ease 0s;
	transition        : all 0.5s ease 0s;
}

.case-study-box:hover .case-study-content02 {
	-webkit-transform: translate(0, -62px);
	transform        : translate(0, -62px);
}

/* 24. process-area */

.process-area h2 {
	color: #fff;
}

.process-area .section-title h5 {
	color: #ffc010;
}

.process-area .col-lg-3.col-md-12:nth-child(2),
.process-area .col-lg-3.col-md-12:nth-child(4) {
	margin-top: 50px;
}

.process-area .col-lg-3.col-md-12:nth-child(2) .process-icon,
.process-area .col-lg-3.col-md-12:nth-child(2) .no {
	background: #ffc010 0% no-repeat padding-box;
}

.process-area .col-lg-3.col-md-12:nth-child(3) .process-icon,
.process-area .col-lg-3.col-md-12:nth-child(3) .no {
	background: #27ae61 0% 0% no-repeat padding-box;
}

.process-area .col-lg-3.col-md-12:nth-child(4) .process-icon,
.process-area .col-lg-3.col-md-12:nth-child(4) .no {
	background: #8145DA 0% 0% no-repeat padding-box;
}

.process-area h5 {
	color: #fff;
}

.process-icon {
	width        : 180px;
	height       : 180px;
	line-height  : 180px;
	background   : #4CC3C1 0% 0% no-repeat padding-box;
	text-align   : center;
	border-radius: 50%;
	margin-bottom: 30px;
	position     : relative;
	display      : inline-block;
}

.process-area .no {
	position     : absolute;
	top          : 0;
	line-height  : 40px;
	right        : 0;
	width        : 50px;
	height       : 50px;
	text-align   : center;
	background   : #4CC3C1 0% 0% no-repeat padding-box;
	border       : 4px solid #010f2e;
	border-radius: 50%;
	color        : #FFFFFF;
	font-weight  : 600;
}

/* 24. skills-area */

.skills {
	width    : 100%;
	max-width: 600px;
	padding  : 0 20px 0 0;
}

.skill-name {
	font-size     : 14px;
	font-weight   : 600;
	text-transform: uppercase;
	margin        : 30px 0 20px;
	color         : #fff;
}

.skill-bar {
	height       : 6px;
	background   : #313940;
	border-radius: 8px;
}

.skill-per {
	height            : 6px;
	background-color  : #ffc010;
	border-radius     : 8px;
	width             : 0;
	position          : relative;
	-webkit-transition: 1s linear;
	transition        : 1s linear;
}

.skill-per:before {
	content          : attr(per);
	position         : absolute;
	padding          : 4px 6px;
	background-color : #ffc010;
	color            : #fff;
	font-size        : 12px;
	border-radius    : 4px;
	top              : -35px;
	right            : 0;
	-webkit-transform: translateX(50%);
	transform        : translateX(50%);
}

.skill-per:after {
	content          : "";
	position         : absolute;
	width            : 10px;
	height           : 10px;
	background-color : #ffc010;
	top              : -16px;
	right            : 0;
	-webkit-transform: translateX(50%) rotate(45deg);
	transform        : translateX(50%) rotate(45deg);
	border-radius    : 2px;
}

.skills-content {
	color: #C3C3C3;
}

.skills-title h5 {
	color        : #4CC3C1;
	font-size    : 22px;
	margin-bottom: 15px;
}

.skills-title h2 {
	font-size  : 46px;
	line-height: 1.4;
	color      : #fff;
}

.progress {
	width      : 150px;
	height     : 150px;
	line-height: 150px;
	background : none;
	margin     : 0 0 50px;
	box-shadow : none;
	position   : relative;
}

.progress:after {
	content      : "";
	width        : 100%;
	height       : 100%;
	border-radius: 50%;
	border       : 12px solid #2f373e;
	position     : absolute;
	top          : 0;
	left         : 0;
}

.progress>span {
	width   : 50%;
	height  : 100%;
	overflow: hidden;
	position: absolute;
	top     : 0;
	z-index : 1;
}

.progress .progress-left {
	left: 0;
}

.progress .progress-bar {
	width       : 100%;
	height      : 100%;
	background  : none;
	border-width: 12px;
	border-style: solid;
	position    : absolute;
	top         : 0;
}

.progress .progress-left .progress-bar {
	left                      : 100%;
	border-top-right-radius   : 80px;
	border-bottom-right-radius: 80px;
	border-left               : 0;
	-webkit-transform-origin  : center left;
	transform-origin          : center left;
}

.progress .progress-right {
	right: 0;
}

.progress .progress-right .progress-bar {
	left                     : -100%;
	border-top-left-radius   : 80px;
	border-bottom-left-radius: 80px;
	border-right             : 0;
	-webkit-transform-origin : center right;
	transform-origin         : center right;
	-webkit-animation        : loading-1 1.8s linear forwards;
	animation                : loading-1 1.8s linear forwards;
}

.progress .progress-value {
	width        : 90%;
	height       : 90%;
	border-radius: 50%;
	background   : #181e23;
	font-size    : 42px;
	font-weight  : 600;
	color        : #fff;
	line-height  : 135px;
	text-align   : center;
	position     : absolute;
	top          : 5%;
	left         : 5%;
}

.progress .progress-value sub {
	font-size  : 20px;
	color      : #ffc010;
	margin-left: 5px;
	font-weight: 400;
}

.progress.blue .progress-bar {
	border-color: #ffc010;
}

.progress.blue .progress-left .progress-bar {
	-webkit-animation: loading-2 1.5s linear forwards 1.8s;
	animation        : loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
	border-color: #fdba04;
}

.progress.yellow .progress-left .progress-bar {
	-webkit-animation: loading-3 1s linear forwards 1.8s;
	animation        : loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
	border-color: #ed687c;
}

.progress.pink .progress-left .progress-bar {
	-webkit-animation: loading-4 0.4s linear forwards 1.8s;
	animation        : loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
	border-color: #1abc9c;
}

.progress.green .progress-left .progress-bar {
	-webkit-animation: loading-5 1.2s linear forwards 1.8s;
	animation        : loading-5 1.2s linear forwards 1.8s;
}

@-webkit-keyframes loading-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(180deg);
		transform        : rotate(180deg);
	}
}

@keyframes loading-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(180deg);
		transform        : rotate(180deg);
	}
}

@-webkit-keyframes loading-2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(144deg);
		transform        : rotate(144deg);
	}
}

@keyframes loading-2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(144deg);
		transform        : rotate(144deg);
	}
}

@-webkit-keyframes loading-3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(90deg);
		transform        : rotate(90deg);
	}
}

@keyframes loading-3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(90deg);
		transform        : rotate(90deg);
	}
}

@-webkit-keyframes loading-4 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(36deg);
		transform        : rotate(36deg);
	}
}

@keyframes loading-4 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(36deg);
		transform        : rotate(36deg);
	}
}

@-webkit-keyframes loading-5 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(126deg);
		transform        : rotate(126deg);
	}
}

@keyframes loading-5 {
	0% {
		-webkit-transform: rotate(0deg);
		transform        : rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(126deg);
		transform        : rotate(126deg);
	}
}

@media only screen and (max-width: 990px) {
	.progress {
		margin-bottom: 20px;
	}
}

.conunter-img {
	margin-left: -400px;
}

/* 27. map-area */

.map-img li {
	position: absolute;
	display : inline-block;
	z-index : 999;
	top     : 93px;
	left    : 0;
}

.map-img li:nth-child(2) {
	position: absolute;
	top     : 50%;
	left    : 45%;
}

.map-img li:nth-child(2) .map-text-hover {
	left: -520px;
}

.map-img li:nth-child(3) {
	position: absolute;
	top     : 100px;
	left    : 85%;
}

.map-img li:nth-child(3) .map-text-hover {
	left: -520px;
}

.map-img li:nth-child(4) {
	position: absolute;
	top     : 40%;
	left    : 15%;
}

.map-img li:nth-child(4) .map-text-hover {
	left: 50px;
}

.map-img li:nth-child(5) {
	position: absolute;
	top     : 43%;
	left    : 70%;
}

.map-img li:nth-child(5) .map-text-hover {
	left: -520px;
}

.map-text-hover {
	background-color: rgb(255, 255, 255);
	box-shadow      : 0px 5px 83px 0px rgba(40, 40, 40, 0.15);
	position        : absolute;
	;
	width  : 510px;
	height : 133px;
	z-index: 138;
	top    : 0;
	left   : 50px;
	padding: 30px;
	display: none;
}

.map-img li:hover .map-text-hover {
	display: block;
}

.map-content {
	border-radius   : 3px;
	background-color: rgb(255, 255, 255);
	box-shadow      : 0px 30px 60px 0px rgba(40, 40, 40, 0.04);
	margin-top      : -150px;
	position        : relative;
}

.map-text-user {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.map-text-user .text {
	padding: 30px 30px 0px 30px;
}

.map-text-user .img img {
	max-width: initial;
	height   : 152px;
}

.map-line {
	height    : 100%;
	width     : 5px;
	background: #27ac60;
	position  : absolute;
	left      : 50%;
}

.map-cirl {
	border-radius   : 50%;
	background-color: rgb(255, 255, 255);
	position        : absolute;
	width           : 60px;
	height          : 60px;
	z-index         : 146;
	border          : 5px solid #27ac60;
	text-align      : center;
	line-height     : 50px;
	color           : #27ac60;
	left            : 47.4%;
	top             : 43px;
}

.contact-area .container {
	position: relative;
	z-index : 1;
}

.contact-bg02 .slider-btn {
	width: 100%;
}

.contact-bg02 .btn.ss-btn.active {
	background: #ffc010;
	border    : 2px solid #ffc010;
	color     : #fff;
}

.contact-field select {
	width             : 100%;
	border            : none;
	background-color  : rgb(255, 255, 255);
	padding           : 15px 15px;
	-webkit-transition: .3s;
	transition        : .3s;
	border-radius     : 10px;
	border            : 1px solid rgb(183, 183, 183);
}

.contact-bg-an-01 {
	position               : absolute;
	left                   : 9.8%;
	top                    : 25%;
	animation              : alltuchtopdown 3s infinite;
	-webkit-animation      : alltuchtopdown 3s infinite;
	animation-delay        : 0s;
	-webkit-animation-delay: 1s;
}

.contact-bg-an-02 {
	position               : absolute;
	right                  : 3%;
	bottom                 : 7%;
	animation              : alltuchtopdown 5s infinite;
	-webkit-animation      : alltuchtopdown 5s infinite;
	animation-delay        : 0s;
	-webkit-animation-delay: 3s;
}

@keyframes alltuchtopdown {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}

	50% {
		-webkit-transform: rotateX(0deg) translateY(-20px);
		transform        : rotateX(0deg) translateY(-20px);
	}

	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}
}

@-webkit-keyframes alltuchtopdown {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}

	50% {
		-webkit-transform: rotateX(0deg) translateY(-20px);
		transform        : rotateX(0deg) translateY(-20px);
	}

	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform        : rotateX(0deg) translateY(0px);
	}
}

/* Here's the new blog dtl to add... */

.quote-post {
	background-position: center;
	background-repeat  : no-repeat;
}

.quote-post .quote-icon {
	float       : left;
	margin-right: 30px;
	display     : block;
	margin-top  : 20px;
}

.quote-post h2 {
	overflow     : hidden;
	margin-bottom: 0;
}

.blog-deatails-box.single .bsingle__post {
	margin-bottom: 0;
}

.sidebar-widget .widget table {
	width     : 100%;
	padding   : 10px;
	background: #f5f5f5;
}

.sidebar-widget .widget th,
.footer-widget .widget th {
	background: #ffc010;
	padding   : 10px 5px;
	text-align: center;
	color     : #fff;
}

.sidebar-widget .widget td {
	padding   : 5px;
	text-align: center;
}

.sidebar-widget .widget {
	padding      : 25px;
	overflow     : hidden;
	margin-bottom: 40px;
	border       : 2px solid #eeeeee17 !important;
}

.sidebar-widget .widget li {
	padding-bottom: 5px;
	border-bottom : 1px solid #eeeeee17;
	padding-top   : 5px;
	float         : left;
	width         : 100%;
}

.widget-title {
	margin-bottom: 40px;
}

.sidebar-widget .widgettitle,
.sidebar-widget .widget-title {
	font-size    : 20px;
	margin-bottom: 0;
	line-height  : 1;
	text-align   : left;
	font-weight  : 600;
	margin-bottom: 30px;
	border-left  : 4px solid #ffc010;
	padding-left : 15px;
}

.sidebar-widget .widget .gallery-icon a:hover {
	padding-left: 0;
}

.tags {
	border-bottom: 2px solid #eeeeee17;
	/*float      :left;*/
	width        : 100%;
	display      : inline-block;
}

.search-form {
	position: relative;
}

.search-form input {
	background: #262228;
	border    : none;
	width     : 100%;
	padding   : 18px 30px;
}

.search-form input::-webkit-input-placeholder {
	font-size: 12px;
	color    : #b0b0b0;
}

.search-form input::-moz-placeholder {
	font-size: 12px;
	color    : #b0b0b0;
}

.search-form input:-ms-input-placeholder {
	font-size: 12px;
	color    : #b0b0b0;
}

.search-form input::placeholder {
	font-size: 12px;
	color    : #b0b0b0;
}

.search-form .search-submit {
	position  : absolute;
	right     : 0;
	top       : 0;
	border    : none;
	background: #ffc010;
	padding   : 18px 23px;
	color     : #ffffff;
	font-size : 14px;
	cursor    : pointer;
	height    : 88%;
	width     : 106px;
}

.sidebar-widget .widget .widget_archive,
.widget_categories,
.sidebar-widget .widget_archive {
	text-align: right;
}

.sidebar-widget .widget .widget_archive,
.sidebar-widget .widget_categories a,
.sidebar-widget .widget_archive a {
	float : left;
	height: 30px;
}

.widget-insta-post li {
	display: inline-block;
	margin : 5px 3px;
}

.widget-insta-post li:hover a:before {
	opacity   : .7;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
}

.widget-insta-post li a {
	position: relative;
	display : block;
}

.widget-insta-post li a:before {
	content           : "";
	position          : absolute;
	height            : 100%;
	width             : 100%;
	left              : 0;
	top               : 0;
	background        : #ffc010;
	opacity           : 0;
	-ms-filter        : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.3s ease-out 0s;
	transition        : all 0.3s ease-out 0s;
}

.widget-social a {
	height       : 40px;
	width        : 40px !important;
	line-height  : 40px;
	border-radius: 50%;
	display      : inline-block;
	background   : transparent;
	border       : 1px solid #eeeeee17;
	color        : #fff;
	margin       : 0 3px;
	text-align   : center !important;
}

.widget-social a:hover {
	background  : #ffc010;
	color       : #ffffff !important;
	border-color: #ffc010;
	padding     : 0 !important;
	box-shadow  : 0px 10px 19px 0px #ffc01066 !important;
}

.rpwwt-widget a {
	padding-left: 0 !important;
}

.rpwwt-widget li {
	line-height: 23px !important;
}

.cat__list li {
	padding-bottom: 10px;
	border-bottom : 1px solid #eeeeee17;
	margin-bottom : 10px;
}

.cat__list li:last-child {
	margin-bottom : 0;
	padding-bottom: 0;
	border        : none;
}

.cat__list li:hover a {
	color: #ffc010;
}

.cat__list li a {
	font-size: 14px;
	color    : #777777;
}

.cat__list li a span {
	float  : right;
	display: block;
}

.widget__post ul li {
	margin-bottom: 20px;
	overflow     : hidden;
}

.widget__post ul li:last-child {
	margin-bottom: 0;
}

.widget__post-thumb {
	float       : left;
	display     : block;
	margin-right: 20px;
}

.widget__post-content {
	overflow: hidden;
	display : block;
}

.widget__post-content h6 {
	font-size    : 16px;
	margin-bottom: 1px;
	padding-right: 15px;
	font-weight  : bold;
}

.widget__post-content h6:hover a {
	color: #ffc010;
}

.widget__post-content span {
	font-size: 14px;
}

.widget__post-content span i {
	margin-right: 8px;
}

.widget__tag ul li {
	display: inline-block;
	margin : 5px 3px;
}

.widget__tag ul li:hover a {
	background  : #ffc010;
	border-color: #ffc010;
	color       : #ffffff;
}

.widget__tag ul li a {
	display  : block;
	border   : 1px solid #d8d8d8;
	font-size: 14px;
	color    : #8f8bb8;
	padding  : 9px 20px;
}

.widget.widget__banner {
	border  : none;
	padding : 0;
	position: relative;
}

.widget__banner-thumb img {
	width: 100%;
}

.widget__banner-overly {
	position  : absolute;
	top       : 50px;
	left      : 0;
	right     : 0;
	text-align: center;
}

.widget__banner-overly>span {
	font-size     : 14px;
	font-weight   : bold;
	text-transform: uppercase;
	color         : #ffffff;
	letter-spacing: 2px;
	display       : block;
	margin-bottom : 160px;
}

.widget__banner-overly h3 {
	color        : #ffffff;
	font-size    : 40px;
	margin-bottom: 129px;
	font-weight  : bold;
}

.widget__banner-overly h3 span {
	display    : block;
	font-family: 'Roboto', sans-serif;
	font-size  : 30px;
	font-weight: 300;
}

.widget__banner-overly .btn {
	border-radius : unset;
	background    : #fff;
	border        : 2px solid #fff;
	font-size     : 12px;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding       : 17px 35px;
	color         : #ffc010;
}

.widget__banner-overly .btn:hover {
	background: transparent;
	color     : #fff;
}

.meta__info ul {
	margin-bottom: 7px;
}

.meta__info ul li {
	display     : inline-block;
	font-size   : 14px;
	margin-right: 30px;
}

.meta__info ul li a {
	color         : #777777;
	text-transform: capitalize;
}

.meta__info ul li a i {
	margin-right: 5px;
}

.meta__info ul li a:hover {
	color: #ffc010;
}

.meta__info ul li i {
	margin-right: 5px;
}

.details__content h2 {
	font-size    : 32px;
	line-height  : 1.3;
	margin-bottom: 20px;
	padding-right: 0;
	font-weight  : bold;
}

.details__content p {
	margin-bottom: 18px;
}

blockquote,
.pages-content blockquote,
.comment-list blockquote {
	background   : #262228;
	border-radius: 10px;
	padding      : 50px 45px 57px !important;
	position     : relative;
	z-index      : 5;
	text-align   : center;
	margin-bottom: 25px;
	display      : inline-block;
	width        : 100%;
	font-size    : 18px;
}

.comment-list blockquote a:hover {
	color: #fff;
}

.news-text blockquote h3 {
	color: #fff;
}

.news-text blockquote i {
	font-size: 190px;
	color    : #e4e9ff;
	z-index  : -1;
	position : absolute;
	bottom   : 15px;
	right    : 30px;
}

blockquote p {
	color: #fff;
}

.news-text blockquote footer {
	color        : #FF8000 !important;
	font-weight  : 600;
	margin-bottom: 15px;
}

.news-text figure img {
	display      : inline-block;
	margin-right : 30px;
	margin-bottom: 15px;
}

.news-text ul li {
	line-height: 30px;
	list-style : none;
}

.news-text ul li i,
.quote-post ul li i {
	color        : #F15B26;
	padding-right: 5px;
}

.news-text blockquote p,
.pages-content blockquote p,
.comment-list blockquote p {
	color      : #fff;
	line-height: 28px;
}

.news-text blockquote footer,
.pages-content blockquote footer {
	font-size  : 14px;
	margin-top : 15px;
	color      : #fff;
	font-weight: 600;
}

.wp-block-media-text .wp-block-media-text__content {
	width: 100%;
}

.wp-block-pullquote cite {
	color: #ffc010 !important;
}

cite {
	margin-top : 15px;
	color      : #ffc010;
	font-weight: 600;
}

.post-categories li {
	display: none !important;
}

.post-categories li:first-child {
	display: block !important;
}

.details__content-img {
	margin: 45px 0;
}

.details__content-img img {
	width : 100%;
	height: auto;
}

.details__content figure {
	margin-top   : 45px;
	margin-bottom: 60px;
}

.details__content figure img {
	float       : left;
	width       : 255px;
	margin-right: 30px;
}

.tags.pb-50 {
	padding-bottom: 20px;
}

.post__tag h5 {
	font-size    : 20px;
	margin-bottom: 20px;
	font-weight  : 600;
	padding-top  : 30px;
	border-top   : 2px solid #eeeeee17;
	margin-top   : 25px;
}

.post__tag ul li {
	display     : inline-block;
	margin-right: 10px;
}

.post__tag ul li a {
	font-size     : 12px;
	text-transform: uppercase;
	border        : 2px solid #e5e5e5;
	padding       : 7px 10px;
	font-weight   : bold;
	display       : inline-block;
	border-radius : 3px;
	color         : #9d9d9d;
	margin-bottom : 10px;
}

.post__tag ul li a:hover {
	border-color: #ffc010;
	background  : #ffc010;
	color       : #ffffff;
}

.post__share h5 {
	font-size    : 18px;
	margin-bottom: 30px;
	font-weight  : bold;
}

.post__share ul li {
	margin-left: 20px;
	display    : inline-block;
}

.post__share ul li a {
	font-size: 18px;
	display  : inline-block;
	color    : #b5becc;
}

.post__share ul li a:hover {
	color: #ffc010;
}

.posts_navigation {
	border-top   : 2px solid #eeeeee17;
	border-bottom: 2px solid #eeeeee17;
}

.posts_navigation .prev-link span {
	font-size     : 12px;
	text-transform: uppercase;
	font-weight   : bold;
	display       : block;
	letter-spacing: 2px;
	margin-bottom : 15px;
}

.posts_navigation .prev-link h4 {
	font-size     : 20px;
	margin-bottom : 0;
	font-weight   : bold;
	text-transform: capitalize;
	height        : 30px;
	overflow      : hidden;
}

.navigation.posts-navigation {
	display: none;
}

.avatar_post img {
	border-radius: 50%;
	box-shadow   : 1px 5px 6px #8a8a8a33;
}

.posts_navigation .prev-link h4:hover a {
	color: #ffc010;
}

.posts_navigation .next-link span {
	font-size     : 12px;
	margin-bottom : 15px;
	font-weight   : bold;
	display       : block;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.posts_navigation .next-link h4 {
	font-size    : 20px;
	margin-bottom: 0;
	font-weight  : bold;
	height       : 30px;
	overflow     : hidden;
}

.posts_navigation .next-link h4:hover a {
	color: #ffc010;
}

.related__post .post-title {
	margin-bottom: 35px;
}

.related__post .post-title h4 {
	font-size    : 26px;
	margin-bottom: 0;
	font-weight  : bold;
}

.related-post-wrap .post-thumb img {
	width: 100%;
}

.related-post-wrap .rp__content {
	padding   : 44px;
	border    : 2px solid #eeeeee17;
	border-top: none;
}

.related-post-wrap .rp__content h3 {
	font-size    : 24px;
	margin-bottom: 20px;
	font-weight  : bold;
	line-height  : 1.4;
}

.related-post-wrap .rp__content h3:hover a {
	color: #ffc010;
}

.related-post-wrap .rp__content p {
	margin-bottom: 0;
	font-size    : 14px;
}

.avatar__wrap {
	background: #262228;
	padding   : 50px 80px;
	float     : left;
	width     : 100%;
}

.avatar__wrap .avatar-img {
	margin-top   : -115px;
	margin-bottom: 35px;
}

.avatar__wrap-content p {
	font-size    : 14px;
	margin-bottom: 0;
}

.avatar__info h5 {
	font-size    : 26px;
	margin-bottom: 10px;
	font-weight  : bold;
}

.avatar__info-social {
	margin-bottom: 20px;
}

.avatar__info-social a {
	font-size: 12px;
	color    : #ffc010;
	display  : inline-block;
	margin   : 0 5px;
}

.avatar__info-social a:hover {
	color: #ffc010;
}

code {
	font-size  : 15px;
	display    : inline-block;
	font-family: 'Teko', sans-serif;
	margin-top : 10px;
}

figcaption {
	font-weight: 600;
	color      : #202020;
}

.comment-form-comment label {
	display: none;
}

.form-submit {
	margin-bottom: 0;
}

.comment__wrap {
	border-bottom: 2px solid #eeeeee17;
}

.comment__wrap-title {
	margin-bottom: 35px;
}

.comment__wrap-title h5 {
	font-size    : 26px;
	margin-bottom: 0;
	font-weight  : bold;
}

.single__comment .comments-avatar {
	float       : left;
	width       : 100px;
	margin-right: 30px;
}

.single__comment.children {
	margin-left: 130px;
}

.single__comment.children .avatar-name h6 i {
	font-size  : 12px;
	color      : #cacfef;
	margin-left: 20px;
}

.comment-text {
	overflow: hidden;
}

.comment-text .avatar-name {
	overflow: hidden;
}

.comment-text .avatar-name h6 {
	font-size    : 16px;
	margin-bottom: 7px;
	font-weight  : bold;
}

.comment-text .avatar-name h6 i {
	display: none;
}

.comment-author-admin .avatar-name h6 i {
	font-size  : 12px;
	display    : inline-block;
	color      : #cacfef;
	margin-left: 20px;
}

.comment-text .avatar-name span {
	font-size     : 12px;
	font-weight   : bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	color         : #ffc010;
}

.comment-text .avatar-name .comment-reply {
	float        : right;
	display      : inline-block;
	border       : 2px solid #eeeeee17;
	padding      : 8px 18px;
	border-radius: 50px;
	font-size    : 14px;
	margin-top   : -23px;
	color        : #fff;
}

.comment-text .avatar-name .comment-reply a {
	color: #777777;
}

.comment-text .avatar-name .comment-reply:hover a {
	color: #fff;
}

.comment-list {
	padding       : 0;
	border-bottom : 2px solid #eeeeee17;
	margin-top    : 45px;
	margin-bottom : 45px;
	padding-bottom: 45px;
}

.comments-title {
	font-size    : 22px;
	margin-bottom: 0;
	font-weight  : bold;
}

.comment-reply-title {
	font-size    : 20px;
	margin-bottom: 20px;
}

.comment-author-admin {
	margin-left: 130px;
}

.comment-text .avatar-name .comment-reply:hover {
	border-color: #ffc010;
	background  : #ffc010;
	color       : #ffffff;
}

.comment-text .avatar-name .comment-reply i {
	margin-right: 5px;
}

.comment-text p {
	font-size    : 16px;
	margin-bottom: 0;
}

.screen-reader-text {
	display: none;
}

.comment-form {
	padding      : 50px;
	background   : #262228;
	border-radius: 30px;
}

.comment-form .comment-field {
	position: relative;
}

.comment-form .comment-field.text-area i {
	top              : 25px;
	-webkit-transform: unset;
	transform        : unset;
}

.comment-form i {
	position         : absolute;
	right            : 30px;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	transform        : translateY(-50%);
	color            : #ffc010;
	font-size        : 14px;
}

.comment-form textarea {
	height       : 150px;
	width        : 100%;
	padding      : 20px;
	padding-right: 50px;
	background   : none;
	border       : 2px solid #eeeeee17;
	color        : #fff;
}

.comment-form textarea::-webkit-input-placeholder {
	font-size: 14px;
	color    : #a7a7c1;
}

.comment-form textarea::-moz-placeholder {
	font-size: 14px;
	color    : #a7a7c1;
}

.comment-form textarea:-ms-input-placeholder {
	font-size: 14px;
	color    : #a7a7c1;
}

.comment-form textarea::placeholder {
	font-size: 14px;
	color    : #a7a7c1;
}

.comment-form textarea:focus {
	outline: 1px solid #ffc010;
}

.comment-form input {
	width        : 100%;
	padding      : 20px;
	padding-right: 50px;
	background   : none;
	border       : 2px solid #eeeeee17;
	color        : #fff;
}

.comment-form input::-webkit-input-placeholder {
	font-size: 14px;
	color    : #b0b0b0;
}

.comment-form input::-moz-placeholder {
	font-size: 14px;
	color    : #b0b0b0;
}

.comment-form input:-ms-input-placeholder {
	font-size: 14px;
	color    : #b0b0b0;
}

.comment-form input::placeholder {
	font-size: 14px;
	color    : #b0b0b0;
}

.comment-form input:focus {
	outline: 1px solid #ffc010;
}

.comment-form .submit {
	background    : #ffc010;
	border        : none;
	font-size     : 14px;
	text-transform: uppercase;
	color         : #fff;
	border-radius : 50px;
	padding       : 12px 45px;
	margin-top    : 20px;
	font-weight   : bold;
	width         : auto;
}

.comment-form .submit:before {
	content: none;
}

.comment-form .submit:hover {
	color     : #fff;
	background: #ffc010;
}

.page .comments-area ol .comment-respond {
	margin-bottom: 30px;
	margin-top   : -26px;
}

.comments-area ol>.comment-respond {
	margin-bottom: 30px;
	margin-top   : -30px;
}

.comments-area ol>li>ol .comment-respond {
	margin-left: -32px;
	margin-top : -35px;
}

.comments-area ol>li>ol>li>ol>.comment-respond {
	margin-left: -42px;
	margin-top : -30px;
}

.comments-area ol>li>ol>li>ol>li>ol>.comment-respond {
	margin-left: -62px;
	margin-top : -35px;
}

.comment-reply-title small {
	margin-left: 15px;
}

.blist li {
	list-style: none !important;
}

.wp-block-gallery.columns-3.is-cropped {
	margin-top: 30px;
}

.single-post .footer-widget {
	padding-top: 50px;
}

figure.aligncenter img {
	margin-bottom: 15px;
}

.wp-image-907 {
	width        : 100% !important;
	height       : auto;
	margin-bottom: 15px;
}

figure img {
	display: inline-block;
}


.footer-widget .tag-cloud-link {
	color: #020202;
}

.footer-widget .textwidget,
.footer-widget .recentcomments a,
.footer-widget a,
.footer-widget .widget_categories a {
	color: #99A3AC;
}

.footer-widget .recentcomments a:hover,
.footer-widget a:hover,
.footer-widget .widget_categories a:hover {
	color: #ffc010;
}

.footer-widget .tag-cloud-link:hover {
	color: #fff;
}

.single-post .bsingle__content .admin {
	display: none;
}

.single-post .blog-deatails-box.single {
	padding     : 40px;
	border-style: solid;
	border-width: 2px;
	border-color: rgb(243, 243, 243);
	float       : left;
	width       : 100%;
}

.single-post .bsingle__post-thumb img {
	margin-bottom: 50px;
}

.bsingle__content.quote-post .meta-info {
	margin-top: 30px;
}

blockquote h3 {
	color: #fff;
}

blockquote footer {
	color        : #ffc010;
	margin-bottom: 30px;
}

.blist li i {
	color        : #F15B26;
	padding-right: 5px;
}

.blist li {
	line-height: 30px;
	list-style : none;
}

.comment-form-cookies-consent input {
	width       : auto;
	margin-top  : 7px;
	margin-right: 10px;
}

/* 16. pagination */

.pagination {
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : block;
	padding-left : 0;
	list-style   : none;
	border-radius: 0;
}

.pagination .page-item {
	display: inline-block;
	margin : 0 5px;
}

.pagination .page-item a.page-link {
	border-radius: 50%;
	padding      : 0;
	height       : 70px;
	width        : 70px;
	line-height  : 70px;
	background   : #f8f8f8;
	color        : #9fa7c5;
	font-size    : 14px;
	border       : none;
	font-weight  : 500;
}

.pagination .page-item:hover a.page-link {
	box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
	background: #ff4a57;
	color     : #fff;
}

.pagination .page-item.active a.page-link {
	box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
	background: #ff4a57;
	color     : #fff;
}

.pagination-wrap .pagination {
	display      : block;
	border-radius: unset;
}

.pagination-wrap .pagination li {
	display      : inline-block;
	margin-right : 6px;
	margin-left  : 0;
	margin-bottom: 15px;
}

.pagination-wrap .pagination li.active a {
	background: #252525;
	color     : #ffffff;
	box-shadow: 0px 8px 16px 0px rgba(26, 35, 126, 0.32);
}

.pagination-wrap .pagination li a {
	border       : none;
	height       : 50px;
	width        : 50px;
	display      : block;
	line-height  : 50px;
	background   : #ffc010;
	border-radius: 50%;
	color        : #fff;
	font-size    : 14px;
	text-align   : center;
}

.pagination-wrap .pagination li a:hover {
	color     : #ffffff;
	background: #ffc010;
}

/* 17. f-cta */

.f-cta-area {
	background   : #fff;
	margin-left  : 260px;
	margin-right : 260px;
	position     : relative;
	top          : -70px;
	margin-bottom: 30px;
	box-shadow   : 0 -3px 65px 0 rgba(0, 0, 0, .09);
}

.f-cta-icon i {
	display      : inline-block;
	height       : 80px;
	width        : 80px;
	text-align   : center;
	line-height  : 78px;
	background   : #ffc010;
	border-radius: 50%;
	color        : #fff;
	font-size    : 28px;
	margin-bottom: 30px;
}

.contact-info p,
.contact-bg02 p {
	color: #fff;
	width: auto;
}

.contact-info .line5,
.contact-bg02 .line5 {
	margin-bottom: 30px;
}

.contact-info h5 {
	color: #fff;
}

.contact_info h4 {
	margin-bottom: 15px;
	font-size    : 28px;
}

.contact_info p {
	margin-top: 0;
	font-size : 16px;
}

.contact_info p:not(:last-child) {
	margin-bottom: 5px;
}

.single-cta {
	border-bottom: 1px solid;
	float        : left;
	width        : 100%;
}

div.single-cta:last-child {
	border: 0;
}

.single-cta h5 {
	font-size    : 20px;
	margin-bottom: 15px !important;
}

.single-cta p {
	margin-bottom: 0;
}

.single-cta p a {
	color: #ffc010;
}

.s-cta-btn .btn {
	background: #383838;
	color     : #fff;
	margin-top: 10px;
}

.s-cta-btn .btn:before {
	content: none;
}

.widget.widget_media_image {
	margin: 25px 0 0;
}

.award-box .date {
	font-weight: 600;
	font-size  : 24px;
	color      : #010f2e;
	font-family: 'Teko', sans-serif;
}

.aw-line {
	position: relative;
}

.aw-line:before {
	content      : "";
	position     : absolute;
	height       : 1px;
	left         : 9%;
	border-bottom: 1px dashed;
	top          : 32%;
	width        : 81%;
	margin       : auto;
}

.team {
	position: relative;
}

.team:after {
	position         : absolute;
	content          : '';
	width            : 85%;
	height           : 170px;
	right            : 0;
	bottom           : 0;
	z-index          : -1;
	background       : rgba(42, 30, 84, 41%);
	-webkit-clip-path: polygon(0 30px, 35px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 55px) 100%, 0 100%, 0 0);
	clip-path        : polygon(0 30px, 35px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 55px) 100%, 0 100%, 0 0);
}

.team2:after {
	position         : absolute;
	content          : '';
	width            : 85%;
	height           : 170px;
	left             : 0;
	bottom           : 0;
	z-index          : -1;
	background       : rgba(42, 30, 84, 41%);
	-webkit-clip-path: polygon(0 30px, 35px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 55px) 100%, 0 100%, 0 0);
	clip-path        : polygon(0 30px, 35px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 55px) 100%, 0 100%, 0 0);
}

.team2 img {
	float: right;
}

.team .text {
	position: absolute;
	top     : 28%;
	right   : 0;
	width   : 45%;
}

.team2 .text {
	position: absolute;
	top     : 28%;
	left    : 80px;
	width   : 45%;
}

.team .text h3,
.team2 .text h3 {
	font-size    : 36px;
	margin-bottom: 0 !important;
	line-height  : unset;
}

.team .text span,
.team2 .text span,
.match-time span {
	color         : #ffc010;
	text-transform: uppercase;
}

.match-time h4 {
	font-size    : 60px;
	margin-bottom: 0;
}

.video-grid .video-full-img .play-btn {
	position         : absolute;
	top              : 50%;
	left             : 50%;
	text-align       : center;
	-webkit-transform: translate(-50%, -50%);
	transform        : translate(-50%, -50%);
}

.video-grid .video-full-img .play-btn a {
	color        : #fff;
	width        : 110px;
	height       : 110px;
	font-size    : 50px;
	border-radius: 50%;
	line-height  : 120px;
	text-align   : center;
	background   : #ffc010;
	margin-bottom: 30px;
	display      : inline-block;
}

.video-grid .video-full-img .play-btn a i {
	margin-left: 5px;
}

.video-grid ul {
	display         : -webkit-box;
	display         : -ms-flexbox;
	display         : flex;
	-ms-flex-wrap   : wrap;
	flex-wrap       : wrap;
	-webkit-box-pack: center;
	-ms-flex-pack   : center;
	justify-content : center;
	margin          : 0 -5px;
}

.video-grid ul li {
	width     : 25%;
	margin-top: 10px;
	padding   : 0 5px;
}

.video-grid ul li:last-child {
	margin-right: 0;
}

.video-tumb .img {
	margin-bottom: 30px;
}

.video-tumb .img img {
	border-radius: 10px;
}

.video-tumb .play-btn {
	position  : absolute;
	top       : 35%;
	width     : 100%;
	text-align: center;
}

.video-tumb .play-btn a {
	color    : #fff;
	font-size: 22px;
}

.insg-grid ul {
	display         : -webkit-box;
	display         : -ms-flexbox;
	display         : flex;
	-ms-flex-wrap   : wrap;
	flex-wrap       : wrap;
	-webkit-box-pack: center;
	-ms-flex-pack   : center;
	justify-content : center;
	margin          : 0 -5px;
}

.insg-grid ul li {
	width  : 16.66%;
	padding: 0 5px;
}

.insg-tumb img {
	width: 100%;
}

.after-bg {
	position: absolute;
	bottom  : 0;
}

.live-team-box {
	width     : 50%;
	margin    : auto;
	margin-top: auto;
	background: #211537;
	padding   : 25px;
	margin-top: -106px;
	z-index   : 1;
	position  : relative;
}

.live-team-box img {
	height       : 90px;
	margin-bottom: 10px;
}

.testimonial-area .cta-title span {
	margin-left: -10px;
}

.mobile-toggler {
	color            : #fff;
	border           : 1px solid #fff;
	font-size        : 24px;
	width            : 45px;
	height           : 45px;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	margin-left      : auto;
}

.mean-nav {
	background: #fff;
}

.mean-nav li {
	padding : 10px 25px;
	position: relative;
}

.mean-nav li i {
	position         : absolute;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	transform        : translateY(-50%);
	right            : 25px;
	font-size        : 18px;
}

.mean-nav>li:not(:last-child) {
	border-bottom: 1px solid #d9d9d980;
}

.mean-nav ul li {
	border-bottom: 1px solid #d9d9d980;
}

.mean-nav li,
.mean-nav li a {
	color: #000;
}

.mean-nav li a {
	display: block;
}

.mean-nav ul li {
	padding-left: 50px;
}


.mobile-menu {
	visibility              : hidden;
	opacity                 : 0;
	-ms-filter              : "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transform       : scaleY(0) translateX(-50%);
	transform               : scaleY(0) translateX(-50%);
	-webkit-transform-origin: top;
	transform-origin        : top;
	-webkit-transition      : all 0.3s ease-in-out;
	transition              : all 0.3s ease-in-out;
	position                : absolute;
	top                     : 100%;
	left                    : 50%;
	width                   : calc(100% - 10%);
}

.mobile-menu-active {
	visibility       : visible;
	opacity          : 1;
	-ms-filter       : "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-webkit-transform: scaleY(1) translateX(-50%);
	transform        : scaleY(1) translateX(-50%);
}


/* Customize Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: white; /* Change this to the color you want, e.g., Tomato color */
}

/* Optionally, you can change the hover color */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: white; /* Light Salmon on hover */
}


.custom-icons {
	position: absolute;
	padding-bottom: 80%;

	top: -15%; /* Adjust the positioning */
	/* right: 10px; */
	padding-left: 72%;
	display: flex;
	align-items: center;
  }
  
  .icon-group {
	display: flex;
	gap: 5px; /* Space between icons */
  }
  
  .app-icon {
	width: 30px; /* Adjust the size */
	height: auto;
  }
  